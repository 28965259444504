<template>
  <v-app tabindex="-1">
    <LtImagesSnack />
    <router-view />
    <GlobalModal />
    <GlobalSnack />
  </v-app>
</template>

<script>
import LtImagesSnack from 'components/listing-tasks/lt-images-snack'
import GlobalModal from 'components/common/global-modal'
import GlobalSnack from 'components/common/global-snack'

export default {
  name: 'AppComponent',
  components: { GlobalSnack, GlobalModal, LtImagesSnack },
}
</script>

<style lang="scss">
.gap-1 {
  grid-gap: 4px;
}
.gap-2 {
  grid-gap: 8px;
}
.full {
  flex: 1;
  height: 100%;
  width: 100%;
}
.input-auto {
  & input {
    width: auto;
  }
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-fixed {
  position: fixed;
}

.p-absolute {
  position: absolute !important;
}

.p-relative {
  position: relative;
}

.w-100 {
  width: 100%;
}
.right-0 {
  right: 0;
}
.top-0 {
  top: 0;
}
.left-0 {
  left: 0;
}
.h-100 {
  height: 100%;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-30 {
  opacity: 0.3;
}

.flex-1 {
  flex: 1;
}

.user-select-text {
  user-select: text;
}

.v-main__wrap {
  display: flex;
  flex-direction: column;
}

.v-card__title {
  font-size: 14px !important;
}

.theme--light.v-application {
  height: 100%;
}

.theme--light.v-divider {
  border-color: var(--v-secondary-lighten1) !important;
}

.theme--light.v-divider.black-divider {
  border-color: black !important;
}

.row {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.v-application {
  font-family: 'Montserrat', 'Roboto';

  .text-h1,
  .text-h2,
  .text-h3,
  .text-h4,
  .text-h5,
  .text-h6,
  .text-subtitle-1,
  .text-subtitle-2,
  .text-body-1,
  .text-body-2,
  .text-overline,
  .text-button,
  .text-caption {
    font-family: 'Montserrat' !important;
  }
}

.pointer {
  cursor: pointer !important;
}

.grab {
  cursor: grab !important;
}

.grabbing {
  cursor: grabbing !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
  min-width: auto;
  font-weight: lighter;
  text-align: center;

  &--active {
    font-weight: 500;
    color: #000000;
  }
}

.close-button {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.comp-wrapper {
  background-color: white;
  border-radius: 3px;
  padding: 15px;
  border: 1px solid lightgrey;
}

.comp-wrapper-small-p {
  background-color: white;
  border-radius: 3px;
  padding: 5px;
  border: 1px solid lightgrey;
}

.center-logo {
  width: 200px;
  height: 180px;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
}

.center-overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  opacity: 0.8;
  background-color: white;
  color: #fff;
  text-align: left;
  z-index: 1000;
}

.shadow {
  position: absolute;
  width: 100px;
  height: 10px;
  top: 50%;
  left: 50%;
  margin-top: 50px;
  margin-left: -75px;
  border-radius: 50%;
  background-color: #95a5a6;
  -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
  transform: translateX(0px) translateY(0px) rotate(0deg);
  -webkit-animation: shadowAnimation 2.6s ease 0s infinite forwards,
    float 5s ease-in-out 0s infinite forwards;
  animation: shadowAnimation 2.6s ease 0s infinite forwards,
    float 5s ease-in-out 0s infinite forwards;
  z-index: 1;
}

.custom-tooltip {
  opacity: 1 !important;
}

.ellipsis-1 {
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.ellipsis-2 {
  @extend .ellipsis-1;
  -webkit-line-clamp: 2;
}

.ellipsis-3 {
  @extend .ellipsis-1;
  -webkit-line-clamp: 3;
}

.ellipsis-4 {
  @extend .ellipsis-1;
  -webkit-line-clamp: 4;
}

.ellipsis-5 {
  @extend .ellipsis-1;
  -webkit-line-clamp: 5;
}

.text-title-1 {
  @extend .text-subtitle-1;
  font-weight: 500;
}

.checkbox-no-label {
  padding: 0;
  margin: 0;

  & .v-input__slot,
  & .v-input--selection-controls__input {
    margin: 0;
  }
}

.picker-tooltip {
  opacity: 1 !important;
  background-color: white;
  color: var(--v-secondary-darken1);
  border: 1px solid var(--v-secondary-base);
}

// DASHBOARD.SCSS.ERB

// Place all the styles related to the dashboard controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

.rounded-card {
  border-radius: 50px;
}

.bronze {
  background-color: #cd7f32 !important;
}
.gold {
  background-color: #ffd700 !important;
}
.diamond {
  background-color: #f8f8ff !important;
}
.silver {
  background-color: #c0c0c0 !important;
}

.dvr-comment-box {
  min-height: 100px;
  max-width: 80%;
  border: 1px solid lightgray;
  padding: 10px;
}

.min-w-150 {
  min-width: 150px;
}

.min-w-200 {
  min-width: 200px;
}

.min-w-350 {
  min-width: 350px;
}

.min-w-500 {
  min-width: 500px;
}

body .fc {
  overflow: auto;
}

.tasks-maker .chip.chip--select-multi {
  background-color: #7ebac0;
  width: 100%;
}

.pri-high {
  color: white;
  border-radius: 40px;
  background: #ca4a48;
}

.pri-critical {
  color: white;
  border-radius: 40px;
  background: red;
}

.pri-med {
  color: white;
  border-radius: 40px;
  background: #dfc12a;
}

.pri-low {
  color: white;
  border-radius: 40px;
  background: darkgrey;
}

.cal-right-cut {
  margin-right: 60px !important;
}

.rounded-card:hover {
  background-color: #72b0b6;
}

.cal-image {
  width: 27px;
  height: 30px;
  border-radius: 50%;
  z-index: 1000;
}

.cal-ini {
  border-radius: 50%;
  text-align: center;
  bottom: 2px;
  left: 2px;
  position: absolute;
  z-index: 1000;
}

.list-cal-ini {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
}

.right-text {
  text-align: right;
}

.midday-btn {
  bottom: 2px;
  right: 10px;
  position: absolute;
  background-color: white;
  color: #9b8ea4;
  border-radius: 20%;
  padding: 1px;
  z-index: 100000;
}

.cal-clock-warn {
  right: 3px;
  left: auto;
  bottom: 2px;
  position: absolute;
  background-color: #eeec;
  border-radius: 50px;
  font-size: 0.85em;
  padding: 1px;
  z-index: 100000;
  border-radius: 50%;
}

.sm-overline-text {
  font-size: 12px !important;
  font-size: 0.55rem !important;
  font-weight: 500;
  line-height: 32px;
  line-height: 2rem;
  text-transform: uppercase;
}

.round-tag.Free.today {
  background-color: #75c547 !important;
}

.round-tag.Checkout.today {
  background-color: #dfc12a !important;
}

.round-tag.Check-in.today {
  background-color: #c7b454 !important;
}

.round-tag.Occupied {
  background-color: red !important;
}

.round-tag.Check-in.and {
  background-color: rgb(255, 158, 80) !important;
}

@keyframes yellowfade {
  from {
    background: #fffbc3;
  }
  to {
    background: transparent;
  }
}

/* The element to apply the animation to */
.just-selected {
  animation-name: yellowfade;
  animation-duration: 3.5s;
}

.remove-file {
  border: #9b8ea4 solid 1px;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  color: white;
}

#sources {
  max-width: 350px !important;
  margin: 0 auto;
  display: block;
}

.carousel__left {
  background-color: #90d2c0 !important;
}

.carousel__right {
  background-color: #90d2c0 !important;
}

.numberCircle {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 2px;

  border: 2px solid #9b8ea4;
  color: #9b8ea4;
  text-align: center;

  font: 15px Arial, sans-serif;
}

.purple-grey-br {
  background-color: #9b8ea41f !important;
}

.listing-picker-new .icon.material-icons.input-group__prepend-icon {
  color: #9b8ea47d !important;
}

.listing-picker-new .input-group__icon-cb {
  color: #9b8ea4 !important;
}

.listing-picker-new li {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 0px;
  color: white !important;
}

.listing-picker-new
  .listing-select:not(.v-input--is-focused)
  .v-select__selections
  input {
  height: 0 !important;
  padding: 0 !important;
}

.header-strip {
  min-height: 300px;
  max-height: 400px;
  background-color: white;
}

.editable {
  cursor: url('assets/images/edit.png'), auto;
}
.editable-field:hover {
  border: 1px solid var(--v-primary-base);
  padding: 4px 8px;
  cursor: url('assets/images/edit.png'), auto;
  transition: all 0.15s ease-in;
}

.fixedbutton {
  position: fixed !important;
  bottom: 10px;
  right: 10px;
  z-index: 100000;
}

.fixedbuttonleft {
  position: fixed !important;
  bottom: 10px;
  left: 10px;
}

.purple-border {
  border: 1px solid #9b8ea4 !important;
}

.fixedbuttonCenter {
  position: fixed !important;
  bottom: 10px;
  left: 48%;
  z-index: 10000;
  text-align: center;
}

.fileUploadWarning {
  position: fixed !important;
  top: 61px;
  left: 2px;
  z-index: 10000;
  text-align: center;
}

.list__tile--active .list__tile__content {
  color: #7ebac0 !important;
}

.header-strip-tour {
  min-height: 200px;
  max-height: 300px;
  background-color: white;
}

.tour-headline {
  text-align: center;
  color: white;
  font-size: 30px;
  font-family: Lato;
  font-weight: bold;
}

.invest-headline {
  text-align: center;
  color: white;
  font-size: 50px;
  font-family: Lato;
  font-weight: bold;
}

.ba-1 {
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  border-top: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
}

.ba-black-1 {
  border-right: 1px solid black !important;
  border-bottom: 1px solid black !important;
  border-top: 1px solid black !important;
  border-left: 1px solid black !important;
}

.ba-1-thin {
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  border-top: 1px solid lightgrey;
  border-width: thin;
  border-left: 1px dotted lightgrey;
}

.br-1 {
  border-right: 1px dotted lightgrey;
}

.br-1w {
  border-right: 1px solid white;
}

.bb-1 {
  border-bottom: 1px solid lightgrey;
}

.bt-1 {
  border-top: 1px solid lightgrey;
}

.bl-1 {
  border-left: 1px dotted lightgrey;
  padding-left: 20px;
}

.center-text {
  text-align: center;
}

.left-text {
  text-align: left;
}

.light-overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 3px 10px;
  width: 100%;
  color: #fff;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.3);
}

.blur-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  z-index: 200000;
  background-color: white;
  color: #fff;
  text-align: left;
  z-index: 190000;
}

.header-overlay {
  position: absolute;
  width: 100%;
  height: 100%;

  color: #fff;
  text-align: left;
  background-color: rgba(78, 78, 78, 0.06);
  z-index: 200000;
}

.carousel__controls {
  background-color: #9b8ea459 !important;
}

.big-icon {
  min-height: 45px;
  min-width: 60px;
  color: #7ebac0 !important;
}

.gallery-mid {
  max-height: 350px;
}

.vue-map-container,
.vue-map-container .vue-map {
  width: 100%;
  height: 100%;
}

.light-purple {
  color: #9b8ea4 !important;
}

.report-table td {
  border: 1px solid lightgrey;
  color: lightgray;
}

.price-very-high {
  background-color: darken(#7ebac0, 30%);
}

.price-high {
  background-color: darken(#7ebac0, 15%);
}

.price-mid {
  background-color: #7ebac0;
}

.price-low {
  background-color: lighten(#7ebac0, 5%);
}

.report-table tr {
  border-top: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;

  color: lightgray;
}

.report-table th {
  text-align: center !important;
}

.report-table .datatable__actions {
  display: none;
}

.special-border {
  border-radius: 4px !important;
  border-top-color: #7ebac0 !important;
  border-top-width: 5px !important;
}

.cyan-icon {
  color: #7ebac0 !important;
}

.mantis-icon {
  color: #7dd181 !important;
}

.mantisish {
  background-color: #7dd181 !important;
}

.v-application td.light-green {
  background-color: #bde4a71a !important;
}

.v-application .green {
  background-color: green !important;
  border-color: green !important;
}

.red-icon {
  color: #d2778c !important;
}

.min80vh {
  min-height: 80vh;
}

.icon.material-icons.input-group__prepend-icon {
  color: #7ebac0 !important;
}

.mt--1 {
  margin-top: -20px;
}

.font-light {
  font-weight: lighter;
  font-size: 13px;
}

.input-group--selection-controls__container {
  color: #7ebac0 !important;
}

.dark-cyan {
  background-color: #77bca9 !important;
}

.dark-purple {
  background-color: #9b8ea4 !important;
}

.done-logo {
  min-width: 100px;
  max-width: 200px;
  border-radius: 50%;
}

.purple-icon {
  color: #9b8ea4 !important;
}

.purple-bg {
  background-color: #cebadd !important;
}

.icon--checkbox {
  color: #9b8ea4 !important;
}

.headline-font {
  font: 600 14px/22px Muli, sans-serif;
  font-weight: lighter;
  font-size: 16px !important;
}

.small-tab {
  min-width: 80px !important;
  width: 80px !important;
  font-size: 12px;
}

.bolder {
  font-weight: 600 !important;
}

.bolder2 {
  font-weight: 700 !important;
}

.submit-btn-pre {
  min-width: 150px;
}

.check-in-header {
  height: 100%;
  background-color: #90d2c0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  max-height: 190px;
  width: 100%;
}

.listing-thumb {
  max-width: 54px;
  margin-top: 5px;
  height: 45px;
  border-radius: 51%;
}

.listing-thumb-2 {
  width: 75px;
  margin-top: 5px;
  height: 75px;
  border-radius: 51%;
}

.guest-profile {
  width: 120px;
  border-radius: 50%;
}

.super-light-yellow {
  background-color: #fffd982e;
}

.reddish {
  color: #f4433633 !important;
}

.x-small-btn {
  height: 25px;
  width: 25px;
}

.maxw150 {
  max-width: 150px;
}

.channel-img {
  max-height: 4em;
  max-width: 8em;
  display: block;
  float: right;
}

.footer-bg {
  background-image: linear-gradient(#7ebac0, #72b0b6);
}

#expenses-graph {
  max-width: 350px !important;
  margin: 0 auto;
  display: block;
}

.sourcesMonthly {
  min-width: 330px !important;
}

.dashboard-component {
  background-color: #8080800f;
  border-radius: 25px;
}

.overlay-custom {
  background-color: SlateGray;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  -moz-opacity: 50%;
  -webkit-opacity: 50%;
  z-index: 1;
}

.overlay-strong {
  background-color: SlateGray;
  position: relative;
  opacity: 0.2;
  -moz-opacity: 20%;
  -webkit-opacity: 20%;
  z-index: 1;
}

#montly-graph {
  display: block;
}

#montly-graph-general-stas {
  display: block;
  height: 100% !important;
  width: 100% !important;
}

.full-screen {
  height: 82vh;
}

.none-white .listing-select i {
  color: #7ebac0 !important;
}

.listing-select i {
  color: #7ebac0 !important;
}

.max-w-150 {
  max-width: 150px !important;
}

.max-w-200 {
  max-width: 200px !important;
}

.guest-msg {
  border-top-right-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  width: 90% !important;
  background-color: #7ebac033 !important;
  border: #9edae073 1px solid;
  @media screen and (max-width: 700px) {
    margin-left: 8px !important;
  }
}

.note-msg {
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  align-self: center;
  width: 90% !important;
  background-color: #fffd9873 !important;
  border: #e0e09e 1px solid;
  @media screen and (max-width: 700px) {
    margin-right: 8px !important;
  }
}

.staff-msg {
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  align-self: center;
  width: 90% !important;
  background-color: #91759833 !important;
  border: #b8aaca9c 1px solid;
  @media screen and (max-width: 700px) {
    margin-right: 8px !important;
  }
}

.auto-msg {
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  align-self: center;
  width: 90% !important;
  background-color: #443b4633 !important;
  border: rgba(159, 187, 202, 0.61) 1px solid;
}

a.Expedia.fc-day-grid-event::after {
  content: 'Expedia';
  color: #004c88;
}

a.TripAdvisor.fc-day-grid-event::after {
  content: 'TripAdvisor';
  color: #1ea859;
}

a.flipkey.fc-day-grid-event::after {
  content: 'TripAdvisor';
  color: #1ea859;
}

a.Google.fc-day-grid-event::after {
  content: 'Google';
  color: #387cff;
}

a.Airbnb.fc-day-grid-event::after {
  content: 'Airbnb';
  color: #ffa5a9;
}

a.VRBO.fc-day-grid-event::after {
  content: 'VRBO';
  color: #43ff90;
}

a.Booking.fc-day-grid-event::after {
  content: 'Booking';
  color: #accc9a;
}

a.Designedvr.fc-day-grid-event::after {
  content: 'DesignedVR';
  color: #c1b5c9;
}

.airbnb-color {
  color: #ffa5a9;
}

.vrbo-color {
  color: #43ff90;
}

.expedia-color {
  color: #004c88;
}

svg.active-icon-dvr {
  color: #bde4a7 !important;
}

.designedvr-color {
  color: #c1b5c9;
}

.booking-color {
  color: #accc9a;
}

.homeaway-color {
  color: #9fd0c3;
}

.pa-4.card .single-img .carousel .jumbotron {
  display: block !important;
}

a.HomeAway.fc-day-grid-event::after {
  content: 'HomeAway';
  color: #9fd0c3;
}

.reqBtn {
  font-size: 10px !important;
}

.small-select {
  .input-group__selections__comma {
    font-size: 12px !important;
    color: #9b8ea4 !important;
  }

  .input-group__input {
    color: #bdede0 !important;
  }

  .input-group__append-icon {
    color: #7ebac0 !important;
  }
}

.small-text-input {
  .input-group__input input {
    font-size: 12px !important;
    color: #9b8ea4 !important;
  }
}

.nice-blue {
  color: #7ebac0 !important;
}

.stats-icon {
  color: #7ebac0 !important;
}

.grey-text {
  color: #a0a1a1;
  font-weight: 400;
}

.dark-grey-text {
  color: #797878 !important;
}

.talk-to-us-text {
  font-size: 18px;
  line-height: 1.8;
  -webkit-font-smoothing: subpixel-antialiased;
}

.message-us-box {
  margin-left: 8em;
}

.blurry {
  box-shadow: 0 0 0 7px #7ebac05c, 0 0 0 15px #e6e6e6;
}

.hide-filter-list thead {
  display: none;
}

.talk-to-us-headline {
  text-align: center;
  color: #9b8ea4;
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 30px;
  font-family: Lato;
  font-weight: bold;
}

.card-icon-wrapper {
  position: absolute;
  background-color: #ffffff9e;
}

.card-switch-wrapper {
  position: absolute;
  right: 0;
  background-color: #ffffffd1;
}

.jumbotron__wrapper {
  max-height: 95vh !important;
}

.jumbotron__wrapper img {
  max-height: 100% !important;
}

.service-panel expansion-panel__container {
  background-color: lighten(#9b8ea4, 20%);
}

.toolbar__content {
  background-color: white;
}

.list-cyan {
  background-color: #90d2c0a1;
}

.list-pink {
  background-color: #d290c7a1;
}

.image-upload > input {
  display: none;
}

.image-upload img {
  width: 40px;
  cursor: pointer;
}

.list-green {
  background-color: #90d298a1;
}

.list-yellow {
  background-color: #d2cd90a1;
}

.list-orange {
  background-color: #d2a590a1;
}

.toolbar__side-icon {
  color: grey;
}

.list__tile.list__tile--link {
  font-size: 12px !important;
}

.comp-wrapper .list__tile.list__tile--link {
  @media screen and (max-width: 700px) {
    min-height: 90px !important;
  }
}

.light-wrapper {
  border: 1px solid #d3d3d340;
  padding: 10px;
}

.listing-picker .list__tile__title {
  font-size: 12px !important;
  color: #9b8ea4 !important;
}

.listing-picker .list__tile__title.multi-picker {
  font-size: 12px !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.multi-picker.list__tile__title {
  font-size: 12px !important;
}

.theme--light.v-tabs > .v-tabs-bar {
  background-color: #9898981f;
}

.tabs__bar {
  background-color: #9898981f !important;
}

.main-background {
  background-color: #efefef1c !important;
}

.comp-wrapper {
  background-color: white;
  border-radius: 3px;
  padding: 15px;
  border: 1px solid lightgrey;
}

.comp-wrapper-small-p {
  background-color: white;
  border-radius: 3px;
  padding: 5px;
  border: 1px solid lightgrey;
}

.round-tag {
  border-radius: 15px;
  padding: 15px;
}

.undo-btn {
  position: absolute !important;
  right: 8px;
}

.cyan-wrapper {
  background-color: white;
  border-radius: 3px;
  padding: 15px;
  border: 1px dotted #7ebac0;
}

.left-grey-border {
  border-left: 1px solid lightgrey;
}

.green-health {
  color: #75c547 !important;
}

.yellow-health {
  color: #dfc12a !important;
}

.dark-yellow {
  color: #c7b454;
}

.red-health {
  color: red !important;
}

.red-warn {
  color: #f443368a !important;
}

.health-listing {
  background-color: #eeedf12e !important;
}

.small-text {
  font-weight: lighter;
  font-size: 11px;
}

.very-small-text {
  font-weight: lighter;
  font-size: 8px;
}

.health-text {
  font-weight: 300;
  font-size: 14px;
}

.med-font {
  font-size: 13px;
}

.marked-status {
  background-color: #bdede0 !important;
}

.address-text {
  font-weight: 230;
  font-size: 12px;
}

.big-text {
  font-weight: 530;
  font-size: 15px;
}

.grey-text {
  color: #bbb3b3;
}

.blw {
  border-left: 1px solid white;
}

.full-width {
  width: 100%;
}

.text-border {
  text-shadow: -1px 0 #90d2c0, 0 1px #90d2c0, 1px 0 #90d2c0, 0 -1px #90d2c0;
}

.help-icon {
  max-width: 15px;
  color: #bbb3b3 !important;
}

.cyanish {
  background-color: #90d2c0 !important;
}

.light-cyanish {
  background-color: #90d2c0 !important;
}

.login-bar .toolbar__content {
  background-color: #bdede0 !important;
}

.pointer-c {
  cursor: pointer;
}

.yellowish {
  background-color: #fffd9873 !important;
}

.big-snack .snack__content {
  min-height: 450px;
  background-color: #fff;
}

.vbig-snack .snack__content {
  min-height: 370px;
  background-color: #fff;
}

.input-group--select.white--text label {
  color: white !important;
}

.sm-snack .snack__content {
  min-height: 220px;
  background-color: #fff;
}

.snack__content {
  min-height: 120px;
}

.light-grey {
  background-color: #8080801f !important;
}

.tooltip-span {
  color: grey;
  font-size: 16px;
  line-height: 40px;
}

.light-red {
  background-color: #ff000029;
}

.super-light-red {
  background-color: rgba(255, 0, 0, 0.03) !important;
}

.sidebar-link {
  text-decoration: none;
  color: #000000b0;
}

.pointer {
  cursor: pointer;
}

.investment-card {
  cursor: pointer;
  opacity: 1;
}

.investment-card:hover {
  opacity: 0.8;
}

.greenish {
  background-color: #bde4a7 !important;
}

.super-light-gray {
  background-color: #d3d3d30d;
}

.very-light-grey {
  background-color: #d3d3d340;
}

.checkbox-txt label {
  font-size: 14px;
}

.bluish .btn__content {
  background-color: #bdede0;
}

.purpleish {
  background-color: #9b8ea4 !important;
}

.light-purpleish {
  background-color: #91759833 !important;
}

.Vacation {
  background-color: #72b0b6ad !important;
  border-color: #80bcc1 !important;
}

.invetor-tab .tabs__item--active {
  background-color: #feffffd9 !important;
}

#header .toolbar__content {
  background-image: linear-gradient(#7ebac0, #72b0b6);
}

.ui-widget-header {
  background: #f7f7f7;
  color: #a3a6b4;
}

.invetor-tab {
  background-color: white !important;
}

.combobox label {
  color: #7ebac0 !important;
}

.input-group__icon-cb {
  color: #7ebac0 !important;
}

.listing-card-address {
  min-height: 120px !important;
}

#imageCarousel {
  height: 30em;
}

.pre-check-in-container {
  margin-top: 7% !important;
}

@media screen and (max-width: 600px) {
  .pre-check-in-container {
    margin-top: 0px !important;
  }
  #imageCarousel {
    height: 15em;
  }
  .tabs__div a {
    font-size: 9px !important;
  }
  .talk-to-us-headline {
    text-align: center;
    color: #9b8ea4;
    font-size: 24px;
    font-family: Lato;
    font-weight: bold;
  }

  .message-us-box {
    margin-left: 0px;
  }

  .talk-to-us-text {
    font-size: 16px;
    line-height: 1.5;
  }
  .bl-1 {
    border-left: none;
    padding-left: 0px;
  }
  .invest-headline {
    text-align: center;
    color: white;
    font-size: 30px;
    font-family: Lato;
    font-weight: bold;
  }
}

.logo-img {
  height: 33px;
  width: 100px;
  margin-top: 10px;
}

//.container .fill-height {
//  background-color: #fafafa;
//}

.lg-font {
  font-weight: bold;
}

.scrollable-dvr {
  height: 78.9vh;
  overflow: auto;
}

.full-scrollable-dvr {
  height: 90vh;
  overflow: auto;
}

.scrollable-dvr-partial {
  height: calc(100vh - 350px);
  overflow-y: scroll;
  @media screen and (max-width: 700px) {
    height: 51vh;
  }
}

.bolder--1 {
  font-weight: 200;
}

@-webkit-keyframes mainAnimation {
  0% {
    width: 50px;
    -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
    transform: translateX(0px) translateY(0px) rotate(0deg);
  }
  20% {
    width: 50px;
    -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
    transform: translateX(0px) translateY(0px) rotate(0deg);
  }
  40% {
    width: 150px;
    -webkit-transform: translateX(-50px) translateY(0px) rotate(0deg);
    transform: translateX(-50px) translateY(0px) rotate(0deg);
  }
  60% {
    width: 150px;
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
    -webkit-transform: translateX(-150px) translateY(0px) rotate(90deg);
    transform: translateX(-150px) translateY(0px) rotate(90deg);
  }
  80% {
    width: 50px;
    -webkit-transform: translateX(-25px) translateY(0px) rotate(90deg);
    transform: translateX(-25px) translateY(0px) rotate(90deg);
  }
  100% {
    width: 50px;
    -webkit-transform: translateX(-50px) translateY(0px) rotate(90deg);
    transform: translateX(-50px) translateY(0px) rotate(90deg);
  }
}

@keyframes mainAnimation {
  0% {
    width: 50px;
    -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
    transform: translateX(0px) translateY(0px) rotate(0deg);
  }
  20% {
    width: 50px;
    -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
    transform: translateX(0px) translateY(0px) rotate(0deg);
  }
  40% {
    width: 150px;
    -webkit-transform: translateX(-50px) translateY(0px) rotate(0deg);
    transform: translateX(-50px) translateY(0px) rotate(0deg);
  }
  60% {
    width: 150px;
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
    -webkit-transform: translateX(-150px) translateY(0px) rotate(90deg);
    transform: translateX(-150px) translateY(0px) rotate(90deg);
  }
  80% {
    width: 50px;
    -webkit-transform: translateX(-25px) translateY(0px) rotate(90deg);
    transform: translateX(-25px) translateY(0px) rotate(90deg);
  }
  100% {
    width: 50px;
    -webkit-transform: translateX(-50px) translateY(0px) rotate(90deg);
    transform: translateX(-50px) translateY(0px) rotate(90deg);
  }
}

@-webkit-keyframes secundaryAnimation {
  0% {
    -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
    transform: translateX(0px) translateY(0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translateX(0px) translateY(0px) rotate(180deg);
    transform: translateX(0px) translateY(0px) rotate(180deg);
  }
  40% {
    -webkit-transform: translateX(0px) translateY(0px) rotate(180deg);
    transform: translateX(0px) translateY(0px) rotate(180deg);
  }
  60% {
    -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
    transform: translateX(0px) translateY(0px) rotate(0deg);
  }
  80% {
    -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
    transform: translateX(0px) translateY(0px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateX(0px) translateY(0px) rotate(180deg);
    transform: translateX(0px) translateY(0px) rotate(180deg);
  }
}

@keyframes secundaryAnimation {
  0% {
    -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
    transform: translateX(0px) translateY(0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translateX(0px) translateY(0px) rotate(180deg);
    transform: translateX(0px) translateY(0px) rotate(180deg);
  }
  40% {
    -webkit-transform: translateX(0px) translateY(0px) rotate(180deg);
    transform: translateX(0px) translateY(0px) rotate(180deg);
  }
  60% {
    -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
    transform: translateX(0px) translateY(0px) rotate(0deg);
  }
  80% {
    -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
    transform: translateX(0px) translateY(0px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateX(0px) translateY(0px) rotate(180deg);
    transform: translateX(0px) translateY(0px) rotate(180deg);
  }
}

@-webkit-keyframes shadowAnimation {
  0% {
    width: 150px;
    -webkit-transform: translateX(-25px) translateY(0px) rotate(0deg);
    transform: translateX(-25px) translateY(0px) rotate(0deg);
  }
  20% {
    width: 75px;
    -webkit-transform: translateX(37.5px) translateY(0px) rotate(0deg);
    transform: translateX(37.5px) translateY(0px) rotate(0deg);
  }
  40% {
    width: 200px;
    -webkit-transform: translateX(-25px) translateY(0px) rotate(0deg);
    transform: translateX(-25px) translateY(0px) rotate(0deg);
  }
  60% {
    width: 75px;
    -webkit-transform: translateX(37.5px) translateY(0px) rotate(0deg);
    transform: translateX(37.5px) translateY(0px) rotate(0deg);
  }
  80% {
    width: 75px;
    -webkit-transform: translateX(37.5px) translateY(0px) rotate(0deg);
    transform: translateX(37.5px) translateY(0px) rotate(0deg);
  }
  100% {
    width: 150px;
    -webkit-transform: translateX(-25px) translateY(0px) rotate(0deg);
    transform: translateX(-25px) translateY(0px) rotate(0deg);
  }
}

@keyframes shadowAnimation {
  0% {
    width: 150px;
    -webkit-transform: translateX(-25px) translateY(0px) rotate(0deg);
    transform: translateX(-25px) translateY(0px) rotate(0deg);
  }
  20% {
    width: 75px;
    -webkit-transform: translateX(37.5px) translateY(0px) rotate(0deg);
    transform: translateX(37.5px) translateY(0px) rotate(0deg);
  }
  40% {
    width: 200px;
    -webkit-transform: translateX(-25px) translateY(0px) rotate(0deg);
    transform: translateX(-25px) translateY(0px) rotate(0deg);
  }
  60% {
    width: 75px;
    -webkit-transform: translateX(37.5px) translateY(0px) rotate(0deg);
    transform: translateX(37.5px) translateY(0px) rotate(0deg);
  }
  80% {
    width: 75px;
    -webkit-transform: translateX(37.5px) translateY(0px) rotate(0deg);
    transform: translateX(37.5px) translateY(0px) rotate(0deg);
  }
  100% {
    width: 150px;
    -webkit-transform: translateX(-25px) translateY(0px) rotate(0deg);
    transform: translateX(-25px) translateY(0px) rotate(0deg);
  }
}

@-webkit-keyframes float {
  0% {
    top: 50%;
  }
  50% {
    top: 51%;
  }
  100% {
    top: 50%;
  }
}

@keyframes float {
  0% {
    top: 50%;
  }
  50% {
    top: 51%;
  }
  100% {
    top: 50%;
  }
}

html,
body {
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
  font-size: 100% !important;
}
label {
  font-weight: unset !important;
}
.loader {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  background-color: #bdede0;
  -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
  transform: translateX(0px) translateY(0px) rotate(0deg);
  -webkit-animation: mainAnimation 2.6s ease 0s infinite forwards;
  animation: mainAnimation 2.6s ease 0s infinite forwards;
  z-index: 2;
}

.loader:after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: -50px;
  background-color: #7ebac0;
  -webkit-transform-origin: top right;
  transform-origin: top right;
  -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
  transform: translateX(0px) translateY(0px) rotate(0deg);
  -webkit-animation: secundaryAnimation 2.6s ease 0s infinite forwards;
  animation: secundaryAnimation 2.6s ease 0s infinite forwards;
}

@-webkit-keyframes el_DpcM1QMkETe_jeD_j1N2o_Animation {
  0% {
    -webkit-transform: translate(187.1484375px, 48.1484375px) scale(1.3, 1.3)
      translate(-187.1484375px, -48.1484375px);
    transform: translate(187.1484375px, 48.1484375px) scale(1.3, 1.3)
      translate(-187.1484375px, -48.1484375px);
  }
  16.67% {
    -webkit-transform: translate(187.1484375px, 48.1484375px) scale(1, 1)
      translate(-187.1484375px, -48.1484375px);
    transform: translate(187.1484375px, 48.1484375px) scale(1, 1)
      translate(-187.1484375px, -48.1484375px);
  }
  33.33% {
    -webkit-transform: translate(187.1484375px, 48.1484375px) scale(1.3, 1.3)
      translate(-187.1484375px, -48.1484375px);
    transform: translate(187.1484375px, 48.1484375px) scale(1.3, 1.3)
      translate(-187.1484375px, -48.1484375px);
  }
  50% {
    -webkit-transform: translate(187.1484375px, 48.1484375px) scale(1, 1)
      translate(-187.1484375px, -48.1484375px);
    transform: translate(187.1484375px, 48.1484375px) scale(1, 1)
      translate(-187.1484375px, -48.1484375px);
  }
  66.67% {
    -webkit-transform: translate(187.1484375px, 48.1484375px) scale(1.3, 1.3)
      translate(-187.1484375px, -48.1484375px);
    transform: translate(187.1484375px, 48.1484375px) scale(1.3, 1.3)
      translate(-187.1484375px, -48.1484375px);
  }
  83.33% {
    -webkit-transform: translate(187.1484375px, 48.1484375px) scale(1, 1)
      translate(-187.1484375px, -48.1484375px);
    transform: translate(187.1484375px, 48.1484375px) scale(1, 1)
      translate(-187.1484375px, -48.1484375px);
  }
  100% {
    -webkit-transform: translate(187.1484375px, 48.1484375px) scale(1, 1)
      translate(-187.1484375px, -48.1484375px);
    transform: translate(187.1484375px, 48.1484375px) scale(1, 1)
      translate(-187.1484375px, -48.1484375px);
  }
}

@keyframes el_DpcM1QMkETe_jeD_j1N2o_Animation {
  0% {
    -webkit-transform: translate(187.1484375px, 48.1484375px) scale(1.3, 1.3)
      translate(-187.1484375px, -48.1484375px);
    transform: translate(187.1484375px, 48.1484375px) scale(1.3, 1.3)
      translate(-187.1484375px, -48.1484375px);
  }
  16.67% {
    -webkit-transform: translate(187.1484375px, 48.1484375px) scale(1, 1)
      translate(-187.1484375px, -48.1484375px);
    transform: translate(187.1484375px, 48.1484375px) scale(1, 1)
      translate(-187.1484375px, -48.1484375px);
  }
  33.33% {
    -webkit-transform: translate(187.1484375px, 48.1484375px) scale(1.3, 1.3)
      translate(-187.1484375px, -48.1484375px);
    transform: translate(187.1484375px, 48.1484375px) scale(1.3, 1.3)
      translate(-187.1484375px, -48.1484375px);
  }
  50% {
    -webkit-transform: translate(187.1484375px, 48.1484375px) scale(1, 1)
      translate(-187.1484375px, -48.1484375px);
    transform: translate(187.1484375px, 48.1484375px) scale(1, 1)
      translate(-187.1484375px, -48.1484375px);
  }
  66.67% {
    -webkit-transform: translate(187.1484375px, 48.1484375px) scale(1.3, 1.3)
      translate(-187.1484375px, -48.1484375px);
    transform: translate(187.1484375px, 48.1484375px) scale(1.3, 1.3)
      translate(-187.1484375px, -48.1484375px);
  }
  83.33% {
    -webkit-transform: translate(187.1484375px, 48.1484375px) scale(1, 1)
      translate(-187.1484375px, -48.1484375px);
    transform: translate(187.1484375px, 48.1484375px) scale(1, 1)
      translate(-187.1484375px, -48.1484375px);
  }
  100% {
    -webkit-transform: translate(187.1484375px, 48.1484375px) scale(1, 1)
      translate(-187.1484375px, -48.1484375px);
    transform: translate(187.1484375px, 48.1484375px) scale(1, 1)
      translate(-187.1484375px, -48.1484375px);
  }
}

#el_Hm0yVsyHK3p * {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
  animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_DpcM1QMkETe {
  -webkit-transform: matrix(
    0.7552876749256711,
    0,
    0,
    0.7552876749256711,
    -58.7452324583533,
    61.30060757789375
  );
  transform: matrix(
    0.7552876749256711,
    0,
    0,
    0.7552876749256711,
    -58.7452324583533,
    61.30060757789375
  );
}

#el_lqOGw-WIPx7 {
  stroke: none;
  fill-rule: evenodd;
  fill: rgb(68.627451%, 87.058824%, 83.921569%);
  fill-opacity: 1;
}

#el_mX31vV6oTp1 {
  stroke: none;
  fill-rule: evenodd;
  fill: rgb(68.627451%, 87.058824%, 83.921569%);
  fill-opacity: 1;
}

#el_dmufHVfWMmz {
  fill: rgb(175, 222, 214);
  -webkit-transform: matrix(
    0.95238095238095,
    0,
    0,
    0.95238095238095,
    167.9523809523802,
    139.99999999999983
  );
  transform: matrix(
    0.95238095238095,
    0,
    0,
    0.95238095238095,
    167.9523809523802,
    139.99999999999983
  );
}

#el_q2hSAK-Mq-L {
  fill: rgb(12, 12, 12);
  -webkit-transform: matrix(
    0.95238095238095,
    0,
    0,
    0.95238095238095,
    167.95238095238096,
    169.00000000000009
  );
  transform: matrix(
    0.95238095238095,
    0,
    0,
    0.95238095238095,
    167.95238095238096,
    169.00000000000009
  );
}

#el_DpcM1QMkETe_jEIjLlASs {
  -webkit-transform: translate(187.1484375px, 48.1484375px) rotate(43320deg)
    translate(-187.1484375px, -48.1484375px);
  transform: translate(187.1484375px, 48.1484375px) rotate(43320deg)
    translate(-187.1484375px, -48.1484375px);
}

#el_DpcM1QMkETe_zT_IrmJrU {
  -webkit-transform: translate(187.1484375px, 48.1484375px)
    translate(-187.1484375px, -48.1484375px) translate(1px, 0px);
  transform: translate(187.1484375px, 48.1484375px)
    translate(-187.1484375px, -48.1484375px) translate(1px, 0px);
}

#el_DpcM1QMkETe_jeD_j1N2o {
  -webkit-animation-name: el_DpcM1QMkETe_jeD_j1N2o_Animation;
  animation-name: el_DpcM1QMkETe_jeD_j1N2o_Animation;
  -webkit-transform: translate(187.1484375px, 48.1484375px) scale(1.3, 1.3)
    translate(-187.1484375px, -48.1484375px);
  transform: translate(187.1484375px, 48.1484375px) scale(1.3, 1.3)
    translate(-187.1484375px, -48.1484375px);
}

.center-logo {
  width: 200px;
  height: 180px;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
}

.listing-header-name {
  background-color: #679fcd24 !important;
}

.shadow {
  position: absolute;
  width: 100px;
  height: 10px;
  top: 50%;
  left: 50%;
  margin-top: 50px;
  margin-left: -75px;
  border-radius: 50%;
  background-color: #95a5a6;
  -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
  transform: translateX(0px) translateY(0px) rotate(0deg);
  -webkit-animation: shadowAnimation 2.6s ease 0s infinite forwards,
    float 5s ease-in-out 0s infinite forwards;
  animation: shadowAnimation 2.6s ease 0s infinite forwards,
    float 5s ease-in-out 0s infinite forwards;
  z-index: 1;
}

.logo {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 42px;
  height: 42px;
  padding: 12px 5px;
  margin-left: -21px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 50%;
  transition: -webkit-transform 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55),
    -webkit-transform 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.logo:hover {
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
}

.logo img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.tourist-page {
  html,
  body {
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    font-family: monospace;
    margin: 0;
    padding: 0;
  }

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .toolbar__content {
    background-color: #bdede0 !important;
  }

  .phone {
    display: flex;
    width: 35em;
    height: 40em;
    border: 1px solid lightgrey;
    background-color: #f3f3f3;
    border-radius: 32px;
    overflow: hidden;
  }

  .screen-selected {
    height: 35em;
    background-color: white;
  }

  .phone-footer {
    width: 100%;
    height: 6em;
    padding-left: 2em;
    padding-right: 2em;
  }
}

.info-box:after {
  content: ' ';
  position: absolute;
  top: -15px;
  right: 100px;
  color: grey;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #9b8ea4;
}

.success-cal {
  background-color: #ddd5ea;
}

.blocked-cal {
  background-color: #8080801f !important;
}

.red0-10 {
  background-color: #ff9484c7;
}

.red10-20 {
  background-color: lighten(#ff6851, 20%);
}

.yellow20-30 {
  background-color: lighten(#dfc12a, 10%);
}

.green30-40 {
  background-color: #bde4a7;
}

.green40-55 {
  background-color: darken(#bde4a7, 5%);
}

.green55-70 {
  background-color: darken(#bde4a7, 10%);
}

.green70-90 {
  background-color: darken(#bde4a7, 15%);
}

.green90-100 {
  background-color: darken(#bde4a7, 20%);
}

// logo
@-webkit-keyframes kf_el_Z8ZIr_0lHR_an_aD3CGWJVe {
  0% {
    -webkit-transform: translate(369.8165283203125px, 110.72949981689453px)
      scale(1, 1) translate(-369.8165283203125px, -110.72949981689453px);
    transform: translate(369.8165283203125px, 110.72949981689453px) scale(1, 1)
      translate(-369.8165283203125px, -110.72949981689453px);
  }
  60% {
    -webkit-transform: translate(369.8165283203125px, 110.72949981689453px)
      scale(0.6, 0.6) translate(-369.8165283203125px, -110.72949981689453px);
    transform: translate(369.8165283203125px, 110.72949981689453px)
      scale(0.6, 0.6) translate(-369.8165283203125px, -110.72949981689453px);
  }
  100% {
    -webkit-transform: translate(369.8165283203125px, 110.72949981689453px)
      scale(1, 1) translate(-369.8165283203125px, -110.72949981689453px);
    transform: translate(369.8165283203125px, 110.72949981689453px) scale(1, 1)
      translate(-369.8165283203125px, -110.72949981689453px);
  }
}

@keyframes kf_el_Z8ZIr_0lHR_an_aD3CGWJVe {
  0% {
    -webkit-transform: translate(369.8165283203125px, 110.72949981689453px)
      scale(1, 1) translate(-369.8165283203125px, -110.72949981689453px);
    transform: translate(369.8165283203125px, 110.72949981689453px) scale(1, 1)
      translate(-369.8165283203125px, -110.72949981689453px);
  }
  60% {
    -webkit-transform: translate(369.8165283203125px, 110.72949981689453px)
      scale(0.6, 0.6) translate(-369.8165283203125px, -110.72949981689453px);
    transform: translate(369.8165283203125px, 110.72949981689453px)
      scale(0.6, 0.6) translate(-369.8165283203125px, -110.72949981689453px);
  }
  100% {
    -webkit-transform: translate(369.8165283203125px, 110.72949981689453px)
      scale(1, 1) translate(-369.8165283203125px, -110.72949981689453px);
    transform: translate(369.8165283203125px, 110.72949981689453px) scale(1, 1)
      translate(-369.8165283203125px, -110.72949981689453px);
  }
}

@-webkit-keyframes kf_el_Z8ZIr_0lHR_an_YO1SphsSE {
  0% {
    -webkit-transform: translate(369.8165283203125px, 110.72949981689453px)
      rotate(0deg) translate(-369.8165283203125px, -110.72949981689453px);
    transform: translate(369.8165283203125px, 110.72949981689453px) rotate(0deg)
      translate(-369.8165283203125px, -110.72949981689453px);
  }
  60% {
    -webkit-transform: translate(369.8165283203125px, 110.72949981689453px)
      rotate(720deg) translate(-369.8165283203125px, -110.72949981689453px);
    transform: translate(369.8165283203125px, 110.72949981689453px)
      rotate(720deg) translate(-369.8165283203125px, -110.72949981689453px);
  }
  100% {
    -webkit-transform: translate(369.8165283203125px, 110.72949981689453px)
      rotate(720deg) translate(-369.8165283203125px, -110.72949981689453px);
    transform: translate(369.8165283203125px, 110.72949981689453px)
      rotate(720deg) translate(-369.8165283203125px, -110.72949981689453px);
  }
}

@keyframes kf_el_Z8ZIr_0lHR_an_YO1SphsSE {
  0% {
    -webkit-transform: translate(369.8165283203125px, 110.72949981689453px)
      rotate(0deg) translate(-369.8165283203125px, -110.72949981689453px);
    transform: translate(369.8165283203125px, 110.72949981689453px) rotate(0deg)
      translate(-369.8165283203125px, -110.72949981689453px);
  }
  60% {
    -webkit-transform: translate(369.8165283203125px, 110.72949981689453px)
      rotate(720deg) translate(-369.8165283203125px, -110.72949981689453px);
    transform: translate(369.8165283203125px, 110.72949981689453px)
      rotate(720deg) translate(-369.8165283203125px, -110.72949981689453px);
  }
  100% {
    -webkit-transform: translate(369.8165283203125px, 110.72949981689453px)
      rotate(720deg) translate(-369.8165283203125px, -110.72949981689453px);
    transform: translate(369.8165283203125px, 110.72949981689453px)
      rotate(720deg) translate(-369.8165283203125px, -110.72949981689453px);
  }
}

.full-screen {
  height: 82vh;
}

.none-white .listing-select i {
  color: #7ebac0 !important;
}

.listing-select i {
  color: #7ebac0 !important;
}

.max-w-150 {
  max-width: 150px !important;
}

.max-w-200 {
  max-width: 200px !important;
}

.max-w-1600 {
  max-width: 1600px !important;
}

.pre-line {
  white-space: pre-line;
}

.guest-msg {
  border-top-right-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  width: 90% !important;
  background-color: #7ebac033 !important;
  border: #9edae073 1px solid;
  @media screen and (max-width: 700px) {
    margin-left: 8px !important;
  }
}

.note-msg {
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  align-self: center;
  width: 90% !important;
  background-color: #fffd9873 !important;
  border: #e0e09e 1px solid;
  @media screen and (max-width: 700px) {
    margin-right: 8px !important;
  }
}

.staff-msg {
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  align-self: center;
  width: 90% !important;
  background-color: #91759833 !important;
  border: #b8aaca9c 1px solid;
  @media screen and (max-width: 700px) {
    margin-right: 8px !important;
  }
}

.auto-msg {
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  align-self: center;
  width: 90% !important;
  background-color: #443b4633 !important;
  border: rgba(159, 187, 202, 0.61) 1px solid;
}

table {
  border-collapse: collapse;
}

.login-image {
  background-image: url('assets/images/login_image.jpg');
  background-size: cover;
}

.gallery-container {
  cursor: url('assets/images/gallery-icon.png'), auto;
}

.ui-widget-header {
  border-color: #f1f1f1;
}

.ui-widget-content {
  background: transparent;
  border-color: #f1f1f1;
}
.theme--light.v-icon {
  color: currentColor !important;
}
.font-weight-semibold {
  font-weight: 600 !important;
}

.intercom-lightweight-app {
  z-index: 3 !important;
}
.intercom-lightweight-app-launcher {
  left: 20px !important;
}
</style>
