<template>
  <v-row>
    <v-col cols="12" lg="3">
      <service-codes v-if="listing" :listing="listing" @save="save" />
    </v-col>
    <v-col cols="12" lg="9">
      <info-items-panel v-if="!loading" :listing-id="listing.id" />
      <v-card class="mt-4 pa-6">
        <v-card-title
          class="text-body-1 font-weight-medium pa-0 mb-2 d-flex justify-space-between"
          ><div>Arrival Instructions</div>
          <div>
            <v-btn
              color="success"
              depressed
              small
              :disabled="disableCheckinInstructionsSave"
              @click="saveArrivalInstructions"
              >Save</v-btn
            >
          </div></v-card-title
        >
        <v-textarea
          v-model="checkinInstuctions"
          outlined
          rows="3"
          dense
          label="Instruction on how to get to the property..."
        ></v-textarea>
        <gallery
          :thumbnails="true"
          :uploadable="true"
          :images="checkinInstuctionsMedia"
          :on-change="e => addPics('checkin_instructions_media', e)"
          :remove-img="e => removePic('checkin_instructions_media', e)"
          :cols="2"
        />
      </v-card>
      <v-card class="mt-4 pa-6">
        <v-card-title
          class="text-body-1 font-weight-medium pa-0 mb-2 d-flex justify-space-between"
          ><div>Departure Instructions</div>
          <div>
            <v-btn
              color="success"
              depressed
              small
              :disabled="disableCheckOutInstructionsSave"
              @click="saveDepartureInstructions"
              >Save</v-btn
            >
          </div></v-card-title
        >
        <v-textarea
          v-model="checkOutInstuctions"
          outlined
          rows="3"
          dense
          label="Instruction on how to leave the property..."
        ></v-textarea>
        <gallery
          :thumbnails="true"
          :uploadable="true"
          :images="checkout_instructions_media"
          :on-change="e => addPics('checkout_instructions_media', e)"
          :remove-img="e => removePic('checkout_instructions_media', e)"
          :cols="2"
        />
      </v-card>
      <v-card class="mt-4 pa-6">
        <div>
          <div class="text-body-1 font-weight-medium mb-1">
            Custom Address Link Url
          </div>
          <v-text-field v-model="customAddressUrl" outlined rows="3" dense>
            <template #append>
              <v-btn
                color="primary"
                depressed
                small
                :disabled="disableCustomAddressUrlSave"
                @click="saveCustomAddressUrl"
                >Save
              </v-btn>
            </template>
          </v-text-field>
        </div>
        <div>
          <div class="text-body-1 font-weight-medium mb-1">
            <div>Travel Guide Url</div>
          </div>

          <v-text-field
            v-model="travelGuideUrl"
            outlined
            rows="3"
            dense
            messages="Accepts embeddable links only (e.g. Notion, Google embedded links)"
          >
            <template #append>
              <v-btn
                color="primary"
                depressed
                small
                :disabled="disableTravelGuideUrlSave"
                @click="saveTravelGuideUrl"
                >Save
              </v-btn>
            </template>
          </v-text-field>
        </div>
        <div>
          <div
            class="text-body-1 font-weight-medium mb-1 d-flex justify-space-between"
          >
            <div>
              Terms of Use
              <span class="text--secondary text-caption"
                >(Overrides Default Terms of Use)</span
              >
            </div>
            <div>
              <v-btn
                color="primary"
                depressed
                small
                :disabled="disableTermsOfUseSave"
                @click="saveTermsOfUse"
                >Save</v-btn
              >
            </div>
          </div>
          <text-editor
            :key="initialTermsOfUse"
            v-model="termsOfUse"
            autofocus
            :show-save-button="false"
          />
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import InfoItemsPanel from './info-items-panel.vue'
import ServiceCodes from 'components/service-codes/service-codes.vue'
import Gallery from 'components/common/gallery'
import TextEditor from 'components/common/text-editor'
import { mapState } from 'vuex'

export default {
  components: {
    InfoItemsPanel,
    ServiceCodes,
    Gallery,
    TextEditor,
  },
  mixins: [PermissionsMixin, DeviceMixin],
  props: ['objName'],
  data: () => ({
    enableSave: false,
    newFact: null,
    checkinInstuctions: null,
    checkOutInstuctions: null,
    checkin_instructions_media: [],
    checkout_instructions_media: [],
    customAddressUrl: null,
    travelGuideUrl: null,
    initialTermsOfUse: null,
    termsOfUse: null,
    servicesNames: [
      'internet',
      'water',
      'electricity',
      'association',
      'insurance',
    ],
  }),
  computed: {
    ...mapState(['loading']),
    listing() {
      return this.$store.state[this.objName]
    },
    disableCheckinInstructionsSave() {
      return this.checkinInstuctions === this.initialCheckinInstuctions
    },
    disableCheckOutInstructionsSave() {
      return this.checkOutInstuctions === this.initialCheckOutInstuctions
    },
    disableCustomAddressUrlSave() {
      return this.customAddressUrl === this.initialCustomAddressUrl
    },
    disableTravelGuideUrlSave() {
      return this.travelGuideUrl === this.initialTravelGuideUrl
    },
    disableTermsOfUseSave() {
      console.log(this.termsOfUse, this.initialTermsOfUse)
      return this.termsOfUse === this.initialTermsOfUse
    },
    checkinInstuctionsMedia() {
      return this.checkin_instructions_media.map(media => media.src || media)
    },
  },
  mounted() {
    const {
      boom_checkout_instructions,
      custom_address_url,
      travel_guide_url,
      checkin_instructions_media,
      checkout_instructions_media,
      terms_of_use,
    } = this.listing.extra_info
    this.checkinInstuctions = this.listing.checkin_instructions
    this.checkOutInstuctions = boom_checkout_instructions
    this.initialCheckinInstuctions = this.checkinInstuctions
    this.initialCheckOutInstuctions = this.checkOutInstuctions
    this.checkin_instructions_media = checkin_instructions_media || []
    this.checkout_instructions_media = checkout_instructions_media || []
    this.customAddressUrl = custom_address_url
    this.travelGuideUrl = travel_guide_url
    this.initialTravelGuideUrl = this.travelGuideUrl
    this.initialCustomAddressUrl = this.customAddressUrl
    this.termsOfUse = terms_of_use || ''
    this.initialTermsOfUse = terms_of_use
  },
  methods: {
    save(services) {
      this.$store.dispatch('updateListingServices', {
        id: this.listing.id,
        services,
      })
      this.enableSave = false
    },
    updateField(payload) {
      this.$store.dispatch('listings/updateListing', {
        id: this.listing.id,
        payload,
      })
    },
    saveArrivalInstructions() {
      this.$store.dispatch('listings/updateListing', {
        id: this.listing.id,
        payload: { boom_checkin_instructions: this.checkinInstuctions },
      })
      this.initialCheckinInstuctions = this.checkinInstuctions
    },
    saveDepartureInstructions() {
      this.$store.dispatch('listings/updateListing', {
        id: this.listing.id,
        payload: { boom_checkout_instructions: this.checkOutInstuctions },
      })
      this.initialCheckOutInstuctions = this.checkOutInstuctions
    },
    addPics(field, pics) {
      this[field] = [...this[field], ...pics]
      this.updateField({ [field]: this[field] })
    },
    removePic(field, pic) {
      this[field] = this[field].filter(p => pic !== (p.src || p))
      this.updateField({ [field]: this[field] })
    },
    saveCustomAddressUrl() {
      this.$store.dispatch('listings/updateListing', {
        id: this.listing.id,
        payload: { custom_address_url: this.customAddressUrl },
      })
      this.initialCustomAddressUrl = this.customAddressUrl
    },
    saveTravelGuideUrl() {
      this.updateField({ travel_guide_url: this.travelGuideUrl })
      this.initialTravelGuideUrl = this.travelGuideUrl
    },
    saveTermsOfUse() {
      this.updateField({ terms_of_use: this.termsOfUse })
      this.initialTermsOfUse = this.termsOfUse
    },
  },
}
</script>
<style scoped>
:deep() .v-input__append-inner {
  margin: 8px 0;
}
</style>
