<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="12" sm="auto" align="center">
        <v-btn-toggle v-model="dateType" color="primary" mandatory>
          <v-btn value="monthly">Monthly</v-btn>
          <v-btn value="yearly">Yearly</v-btn>
        </v-btn-toggle>
      </v-col>

      <v-col cols="12" sm="auto">
        <v-menu v-model="menu" transition="scale-transition" offset-y>
          <template #activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              :value="date"
              :label="dateType === 'monthly' ? $t('Month') : $t('Year')"
              prepend-inner-icon="mdi-calendar"
              readonly
              outlined
              hide-details
              v-on="on"
            />
          </template>
          <v-date-picker
            ref="datePicker"
            v-model="date"
            :max="
              $moment.utc().subtract(1, 'month').endOf('month').toISOString()
            "
            type="month"
            no-title
            reactive
            @change="dateChanged"
            @click:year="dateChanged"
          />
        </v-menu>
      </v-col>

      <v-col cols="12" sm="5">
        <v-text-field
          v-model="search"
          label="Search..."
          prepend-inner-icon="search"
          outlined
          clearable
          hide-details
          @input="fetchDataDebounce(1)"
        />
      </v-col>

      <v-col cols="12" sm="auto" align="center">
        <v-btn-toggle
          v-model="listingsState"
          color="primary"
          mandatory
          @change="fetchData(1)"
        >
          <v-btn value="active">Active</v-btn>
          <v-btn value="disabled">Disabled</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-tooltip bottom open-delay="100">
        <template #activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <v-btn
              class="mx-5"
              color="primary"
              icon
              @click="openModalOfListingsWithMissingBankInfo"
            >
              <v-icon>mdi-bank-minus</v-icon>
            </v-btn>
          </span>
        </template>
        <span>Listings With Missing Bank Info</span>
      </v-tooltip>
    </v-row>
    <v-row>
      <v-col cols="12" sm="auto">
        <v-checkbox
          v-model="includeListingsInSetup"
          label="Include listings in setup"
          hide-details
          class="d-inline-block"
          @change="fetchData(1)"
        />
      </v-col>
      <v-col cols="12" sm="auto">
        <v-checkbox
          v-model="inProcess"
          label="In Process"
          hide-details
          class="d-inline-block"
          @change="fetchData(1)"
        />
      </v-col>
      <v-col cols="12" sm="auto">
        <v-checkbox
          v-model="zeroBalance"
          label="Show zero balances"
          hide-details
          class="d-inline-block"
          @change="fetchData(1)"
        />
      </v-col>
    </v-row>
    <v-row class="mt-5 pa-3">
      <v-spacer />
      <v-btn
        v-if="managementRoles && hasAbility('management-payments')"
        class="text-capitalize"
        color="secondary"
        elevation="0"
        :disabled="!selectedIds.size"
        @click="paymentActions"
      >
        Payments Actions
      </v-btn>
      <v-btn
        v-if="managementRoles && hasAbility('management-payments')"
        class="text-capitalize ml-3"
        color="secondary"
        elevation="0"
        @click="exportToExcel"
      >
        Export To Excel
      </v-btn>
    </v-row>
    <v-row class="mt-5">
      <v-data-table
        :headers="tableHeaders"
        :items="items"
        :options="pagination"
        :server-items-length="pagination.itemsLength"
        :loading="fetchingData"
        class="elevation-1 w-100 mx-3"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100],
        }"
        @pagination="paginationChanged($event)"
      >
        <template #header.checkbox>
          <v-checkbox
            :input-value="
              availableItemsToSelect.length &&
              selectedIds.size === availableItemsToSelect.length
            "
            :disabled="!availableItemsToSelect.length"
            hide-details
            class="ma-0 pa-0 d-inline-block pl-2"
            @change="selectAllClicked(!!$event)"
          />
        </template>

        <template #item.checkbox="{ item }">
          <v-tooltip v-if="item.checkboxErrors.length" top>
            <template #activator="{ on }">
              <v-icon small v-on="on">info</v-icon>
            </template>
            <div v-for="(error, index) in item.checkboxErrors" :key="index">
              {{ error }}
            </div>
          </v-tooltip>
          <div v-else class="flex-center">
            <v-checkbox
              :input-value="selectedIds.has(item.id)"
              hide-details
              class="ma-0 pa-0 d-inline-block pl-2"
              @change="itemSelect(!!$event, item.id)"
            />
          </div>
        </template>
        <template #item.cleaningFee="{ item }">
          {{ dollarFormatter(item.cleaningFee) }}
        </template>
        <template #item.managementFee="{ item }">
          {{ dollarFormatter(item.managementFee) }}
        </template>
        <template #item.pmcFee="{ item }">
          {{ dollarFormatter(item.pmcFee) }}
        </template>
        <template #item.totalFee="{ item }">
          {{ dollarFormatter(item.totalFee) }}
        </template>
        <template #item.inProcess="{ item }">
          {{ dollarFormatter(item.inProcess) }}
        </template>
        <template #item.transferred="{ item }">
          {{ dollarFormatter(item.transferred) }}
        </template>
        <template #item.balance="{ item }">
          {{ dollarFormatter(item.balance) }}
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { debounce, map, round, sum } from 'lodash'
import { mapActions } from 'vuex'
import FormattersMixin from 'components/mixins/formatters-mixin'
import Toaster from '@/utils/toaster'
import { autoCancelToken } from '@/axios/config'

export default {
  name: 'ManagementPayments',
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: ['natchaSupport'],
  data: function () {
    return {
      menu: false,
      dateType: 'monthly',
      listingsState: 'active',
      date: this.$moment.utc().subtract(1, 'month').format('YYYY-MM'),
      search: '',
      includeListingsInSetup: false,
      inProcess: false,
      zeroBalance: false,
      tableHeaders: [
        {
          text: '',
          value: 'checkbox',
          sortable: false,
          align: 'center',
          width: 100,
        },
        { text: 'Id', value: 'id', sortable: false },
        { text: 'Property Nickname', value: 'nickname', sortable: false },
        { text: 'Cleaning Fee', value: 'cleaningFee', sortable: false },
        { text: 'Management Fee', value: 'managementFee', sortable: false },
        { text: 'PMC Fees', value: 'pmcFee', sortable: false },
        { text: 'Total Fee', value: 'totalFee', sortable: false },
        { text: 'In Process', value: 'inProcess', sortable: false },
        { text: 'Transferred', value: 'transferred', sortable: false },
        { text: 'Balance', value: 'balance', sortable: false },
      ],
      fetchingData: true,
      pagination: {
        page: 1,
        itemsPerPage: 10,
        itemsLength: null,
      },
      items: [],
      fetchDataDebounce: debounce(this.fetchData, 300),
      selectedIds: new Set(),
    }
  },
  computed: {
    availableItemsToSelect() {
      return this.items.filter(item => !item.checkboxErrors.length)
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions('listings', ['getAllListingsWithMissingBankInfo']),
    preparePayload() {
      const isYearly = this.dateType === 'yearly'
      this.from = this.$moment(this.date)
        .startOf(isYearly ? 'year' : 'month')
        .format('YYYY-MM-DD')
      this.to = this.$moment(this.date)
        .endOf(isYearly ? 'year' : 'month')
        .format('YYYY-MM-DD')
      return {
        page: this.pagination.page,
        per_page: this.pagination.itemsPerPage,
        search: this.search || '',
        include_listings_in_setup: this.includeListingsInSetup,
        positive_balance: true,
        negative_balance: true,
        balanced: this.zeroBalance,
        in_process: this.inProcess,
        listings_state: this.listingsState,
        from: this.from,
        to: this.to,
      }
    },
    fetchData(page) {
      this.fetchingData = true
      if (page) this.pagination.page = page
      const params = this.preparePayload()
      axios
        .get('/api/listings/accounting_data/management', {
          params,
          cancelToken: autoCancelToken('managementPayments'),
        })
        .then(res => {
          this.selectedIds = new Set()
          this.items = this.mapItems(res.data.items)
          this.pagination = {
            page: parseInt(res.data.pagi_info.page),
            itemsPerPage: parseInt(res.data.pagi_info.per_page),
            itemsLength: parseInt(res.data.pagi_info.count),
          }
        })
        .finally(() => {
          this.fetchingData = false
        })
    },
    exportToExcel() {
      this.fetchingData = true
      const params = this.preparePayload()
      axios
        .get('/api/listings/accounting_data/management/excel', {
          params,
        })
        .then(res => {
          Toaster.show([{ type: 'success', text: res.data.message }])
        })
        .catch(alert)
        .finally(() => {
          this.fetchingData = false
        })
    },
    paginationChanged(pagination) {
      const page = this.pagination.page
      const itemsPerPage = this.pagination.itemsPerPage
      this.pagination = pagination
      if (
        page !== pagination.page ||
        itemsPerPage !== pagination.itemsPerPage
      ) {
        this.fetchData()
      }
    },
    dateChanged(val) {
      this.date = val.toString()
      this.fetchData(1)
    },
    mapItems(resItems = []) {
      return resItems.map(resItem => {
        return {
          id: resItem.listing.id,
          nickname: resItem.listing.nickname,
          address: resItem.listing.address,
          managementFee: resItem.accounting_stats.summary.management_fees,
          pmcFee: resItem.accounting_stats.summary.pmc_fees,
          cleaningFee:
            resItem.accounting_stats.summary.total_reservations_cleaning,
          totalFee: resItem.accounting_stats.summary.total_management_fees,
          inProcess:
            resItem.accounting_stats.summary.management_fees_in_process,
          transferred:
            resItem.accounting_stats.summary.management_fees_confirmed,
          balance: resItem.accounting_stats.summary.managemement_report_balance,
          checkboxErrors: this.errorsToCreatePayment(
            resItem.listing,
            resItem.accounting_stats
          ),
        }
      })
    },
    rsm(val, field) {
      return round(sum(map(val, field)), 2) || 0
    },
    selectAllClicked(value) {
      if (value) {
        this.selectedIds = new Set(
          this.availableItemsToSelect.map(item => item.id)
        )
      } else {
        this.selectedIds.clear()
      }
      this.$forceUpdate()
    },
    itemSelect(val, itemId) {
      if (val) {
        this.selectedIds.add(itemId)
      } else {
        this.selectedIds.delete(itemId)
      }
      this.$forceUpdate()
    },
    errorsToCreatePayment(listing, accountingData) {
      const errors = []
      if (!listing.bank_account) {
        errors.push('Missing listing bank account info')
      } else if (!listing.bank_account.account_number) {
        errors.push('Missing listing bank account number')
      }

      if (accountingData.summary.management_fees_balance === 0) {
        errors.push('Empty balance')
      }

      if (accountingData.summary.management_fees_in_process > 0) {
        errors.push('Transaction in process')
      }

      if (this.dateType === 'yearly') {
        errors.push('Paying on an annual basis is not possible')
      }

      return errors
    },
    selectedItems() {
      return this.items.filter(i => this.selectedIds.has(i.id))
    },
    totalSelectedItemsBalance() {
      return this.rsm(this.selectedItems(), 'balance')
    },
    isAllSelectedItemsHavePositiveBalance() {
      return this.selectedItems().every(i => i.balance > 0 && i.inProcess <= 0)
    },
    paymentActions() {
      this.$store.commit('showModal', {
        name: 'ListingsPaymentsModal',
        props: {
          date: this.date,
          selectedIds: this.selectedIds,
          natchaSupport: this.natchaSupport,
          totalAmount: this.dollarFormatter(this.totalSelectedItemsBalance()),
          enablePay: this.isAllSelectedItemsHavePositiveBalance(),
          type: 'Management Payments',
          afterAction: () => this.fetchData(),
          afterFileDownload: fileName =>
            this.afterDownloadPaymentsFile(fileName),
        },
      })
    },
    afterDownloadPaymentsFile(fileName) {
      const rows = this.selectedItems().map(item => {
        return [item.id, item.nickname, item.address, item.balance]
      })
      rows.unshift(['Id', 'Nickname', 'Address', 'Balance'])
      this.autoDownloadLocalCsv(rows, fileName.replace('Nacha', 'Info'))
    },
    async openModalOfListingsWithMissingBankInfo() {
      this.$store.commit('updateLoading', true)
      const listings = await this.getAllListingsWithMissingBankInfo()
      this.$store.commit('updateLoading', false)
      this.$store.commit('showModal', {
        name: 'ListingsListModal',
        maxWidth: 950,
        isPersistent: false,
        props: {
          title: 'Listings With Missing Bank Info',
          listings,
        },
      })
    },
  },
  watch: {
    menu(val) {
      if (val && this.dateType === 'yearly') {
        this.$nextTick(() => (this.$refs.datePicker.activePicker = 'YEAR'))
      } else if (val && this.dateType === 'monthly') {
        this.$nextTick(() => (this.$refs.datePicker.activePicker = 'MONTH'))
      }
    },
    dateType(val) {
      if (val === 'monthly') {
        this.date = this.$moment.utc().subtract(1, 'month').format('YYYY-MM')
      } else {
        this.date = this.$moment.utc().subtract(1, 'month').format('YYYY')
      }
      this.fetchData(1)
    },
  },
}
</script>

<style scoped></style>
