<template>
  <div>
    <v-btn v-show="name" x-small depressed color="info" @click="openDialog">
      <v-icon x-small class="me-1">mdi-phone</v-icon>
      Call
    </v-btn>

    <v-dialog v-model="dialog" width="300px" @click:outside="ignoreCall">
      <v-card height="350px" class="fill-height d-flex flex-column">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon @click="closeDialog">mdi-close</v-icon>
        </v-card-title>
        <v-card-text
          class="flex-1 text-center align-center d-flex flex-column justify-center"
        >
          <div v-if="isIncoming" class="text-body-1 font-weight-bold">
            Incoming Call...
          </div>
          <div class="text-h6 font-weight-medium">{{ formattedPhone }}</div>
          <user-avatar
            v-if="name"
            class="my-6"
            :name="name"
            :size="70"
            :color="stringToHslColor(name)"
          />
          <div class="text-h5 font-weight-bold text-capitalize">{{ name }}</div>
          <div v-if="callTime" class="info--text mt-2">
            {{ callTime }}
          </div>
        </v-card-text>
        <v-card-actions class="justify-center gap-2 my-4">
          <v-btn
            v-if="isIncoming"
            color="success"
            class="mx-1"
            rounded
            depressed
            @click="acceptIncomingCall"
            ><v-icon class="mx-1">mdi-phone</v-icon
            ><span class="mx-1 font-weight-semibold">Accept</span></v-btn
          >
          <v-btn
            v-if="!isIncoming"
            color="success"
            class="mx-1"
            rounded
            depressed
            :loading="!device"
            :disabled="isInCall || !device"
            @click="makeOutgoingCall"
            ><v-icon class="mx-1">mdi-phone</v-icon
            ><span class="mx-1 font-weight-semibold">Call</span></v-btn
          >
          <v-btn
            v-if="isIncoming"
            color="error"
            class="mx-1"
            rounded
            depressed
            @click="declineCall"
            ><v-icon class="mx-1">mdi-phone-hangup</v-icon
            ><span class="mx-1 font-weight-semibold">Decline</span></v-btn
          >
          <v-btn
            v-if="!isIncoming"
            color="error"
            class="mx-1"
            rounded
            depressed
            :disabled="!isInCall"
            @click="hangupCall"
            ><v-icon class="mx-1">mdi-phone-hangup</v-icon
            ><span class="mx-1 font-weight-semibold">End</span></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Device } from '@twilio/voice-sdk'
import UserAvatar from 'components/common/user-avatar.vue'
import ColorsMixin from 'components/mixins/colors-mixin'

export default {
  name: 'VoiceModal',
  components: { UserAvatar },
  mixins: [ColorsMixin],
  props: ['phone', 'name', 'conversationId'],
  data() {
    return {
      dialog: false,
      device: null,
      call: null,
      isInCall: false,
      isIncoming: false,
      callTime: null,
      callTimer: null,
      incomingPhone: null,
    }
  },
  mounted() {
    this.startupClient()
  },
  computed: {
    formattedPhone() {
      const number = this.phone || this.incomingPhone
      if (!number) return ''
      const phone = number.replace(/\D/g, '')
      return [[0, 3], [3, 5], [5, 8], [8]]
        .map(r => phone.slice(r[0], r[1]))
        .join(' ')
    },
  },
  methods: {
    openDialog() {
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    startCallTimer() {
      let seconds = 0
      this.callTimer = setInterval(() => {
        seconds++
        const mins = Math.floor(seconds / 60)
        const secs = seconds % 60
        this.callTime = `${mins}:${secs < 10 ? '0' : ''}${secs}`
      }, 1000)
      console.log('callTimer', this.callTimer)
    },
    endCallTimer() {
      clearInterval(this.callTimer)
      this.callTime = null
      this.isInCall = false
    },
    callEvent(event) {
      return () => {
        console.log(`Call ${event}`)
        if (event == 'accept') this.startCallTimer()
        else this.endCallTimer()
      }
    },
    handleCall() {
      this.call.on('accept', this.callEvent('accept'))
      this.call.on('disconnect', this.callEvent('disconnected'))
      this.call.on('cancel', this.callEvent('cancel'))
      this.call.on('reject', this.callEvent('reject'))
    },
    async makeOutgoingCall() {
      if (!this.device) return
      this.isInCall = true
      this.call = await this.device.connect({ params: { To: this.phone } })
      this.handleCall()
    },
    async handleIncomingCall(call) {
      console.log('incoming call', call.parameters)
      this.dialog = true
      this.isIncoming = true
      this.call = call
      this.incomingPhone = call.parameters.From
      this.handleCall()
    },
    acceptIncomingCall() {
      this.isIncoming = false
      this.isInCall = true
      this.call.accept()
    },
    async declineCall() {
      this.call.reject()
      this.call = null
      this.isIncoming = false
    },
    async hangupCall() {
      if (!this.call) return
      this.call.disconnect()
      this.call = null
      this.isInCall = false
      clearInterval(this.callTimer)
      this.callTime = null
    },
    async ignoreCall() {
      if (this.isIncoming) {
        this.call.ignore()
        this.call = null
        this.isIncoming = false
      }
    },
    async startupClient() {
      const event = 'conversations/generateVoiceToken'
      const { data } = await this.$store.dispatch(event)
      const options = {
        logLevel: 1,
        codecPreferences: ['opus', 'pcmu'],
      }
      this.device = new Device(data.token, options)

      this.device.register()
      this.device.on('registered', () =>
        console.log('Twilio.Device Ready to make and receive calls!')
      )
      this.device.on('incoming', this.handleIncomingCall)
      this.device.on('error', function (error) {
        console.log('Twilio.Device Error: ' + error.message)
      })
    },
  },
}
</script>

<style scoped>
/* Add any scoped styles here */
</style>
