import axios from '@/axios/config'

export default {
  namespaced: true,
  actions: {
    async syncTemplates(_, { tenantId, ...body }) {
      return axios.post(`/api/twilio/templates/${tenantId}/sync`, body)
    },
    async generateInfo(_, body) {
      return axios.post(`/api/conversations/generate-train-data`, body)
    },
    async createPolicy(_, body) {
      return axios.post(`/api/policies`, body)
    },
    async createInfoItem(_, body) {
      return axios.post(`/api/listing-info-items/create-by-trained-data`, body)
    },
    async generateVoiceToken(_) {
      return axios.get(`/api/twilio/voice-token`)
    },
  },
}
