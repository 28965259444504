import GlobalMixin from './global-mixin'

export default {
  mixins: [GlobalMixin],

  methods: {
    proxiedIf(condition) {
      try {
        if (this.isDesignedVr === undefined || !this.isDesignedVr) {
          return false
        }
        if (typeof condition === 'function') {
          return condition()
        }
        return condition
      } catch {
        return false
      }
    },
  },
}
