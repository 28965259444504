<template>
  <div>
    <v-navigation-drawer
      v-if="isMobile"
      v-model="drawer"
      absolute
      top
      temporary
    >
      <v-expansion-panels
        v-model="panels"
        multiple
        flat
        class="my-3 ps-2 ps-sm-4"
      >
        <v-expansion-panel v-for="(channels, name, i) in panelData" :key="i">
          <v-expansion-panel-header>
            <v-row align="center">
              <v-icon class="mr-2">
                {{ i == panels ? 'mdi-menu-up' : 'mdi-menu-down' }}
              </v-icon>
              <span class="text-body-1 font-weight-semibold">{{ name }}</span>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="px-1">
            <div>
              <v-btn
                v-for="(channel, i) in channels"
                :key="i"
                full-width
                text
                class="w-100 text-left text-capitalize text-body-1 font-weight-medium justify-start px-0 my-1 py-2 channel-btn"
                :class="{
                  'selected-channel': selectedChannel == channel.id,
                }"
                @click="onSelectChannel(channel)"
              >
                <v-card outlined class="pa-1 mx-2"
                  ><v-icon class="d-flex" small>{{
                    CHANNEL_ICONS[channel.name] || 'mdi-pound'
                  }}</v-icon></v-card
                >
                {{ channel.name }}</v-btn
              >
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-navigation-drawer>
    <v-row class="light300 v-100">
      <v-col v-if="!isMobile" cols="2" class="pa-0 feed">
        <template>
          <v-expansion-panels v-model="panels" multiple flat class="my-3 ps-4">
            <v-expansion-panel
              v-for="(channels, name, i) in panelData"
              :key="i"
            >
              <v-expansion-panel-header>
                <v-row align="center">
                  <v-icon class="mr-2">
                    {{ i == panels ? 'mdi-menu-up' : 'mdi-menu-down' }}
                  </v-icon>
                  <span class="text-body-1 font-weight-semibold">{{
                    name
                  }}</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-1">
                <div>
                  <v-btn
                    v-for="(channel, i) in channels"
                    :key="i"
                    full-width
                    text
                    class="w-100 text-left text-capitalize text-body-1 font-weight-medium justify-start px-0 my-1 py-2 channel-btn"
                    :class="{
                      'selected-channel': selectedChannel == channel.id,
                    }"
                    @click="onSelectChannel(channel)"
                  >
                    <v-card outlined class="pa-1 mx-2"
                      ><v-icon class="d-flex" small>{{
                        CHANNEL_ICONS[channel.name] || 'mdi-pound'
                      }}</v-icon></v-card
                    >
                    {{ channel.name }}</v-btn
                  >
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </v-col>
      <v-col ref="feedRef" cols="12" sm="10" class="white feed pt-0">
        <v-row>
          <v-col cols="12" class="pa-0 sticky-section white">
            <div
              class="pa-3 font-weight-semibold text-h6 d-flex py-4"
              flat
              outlined
            >
              <v-icon
                v-if="isMobile"
                class="ms-2"
                @click.stop="drawer = !drawer"
                >mdi-menu-down</v-icon
              >
              <v-card outlined class="pa-1 ms-2 me-4"
                ><v-icon class="d-flex">{{
                  CHANNEL_ICONS[selectedChannelObject.name] || 'mdi-pound'
                }}</v-icon></v-card
              >
              {{ selectedChannelObject.name }}
            </div>
            <v-divider></v-divider>
          </v-col>
          <div v-if="messages.length" class="w-100">
            <div v-for="msg in messages" :key="msg.id" class="mt-3 mb-3">
              <v-col cols="12" class="pa-0 d-flex">
                <div class="mt-2 ms-5">
                  <v-avatar rounded size="40" :color="roleColor(msg.role)">
                    <span class="white--text">{{
                      initialsFromFullName(msg.created_by || 'Admin')
                    }}</span>
                  </v-avatar>
                </div>
                <div class="mx-3 my-4 pe-8">
                  <div class="d-flex">
                    <div class="dark800 font-weight-semibold item-title-row">
                      {{ msg.user_name || 'Admin' }}
                      <span
                        class="dark500--text text-body-2 font-weight-medium ms-2"
                        >{{ $moment(msg.created_at).fromNow() }}</span
                      >
                    </div>
                  </div>
                  <div v-if="isBodyArray(msg.body)">
                    <div
                      v-for="msg in msg.body"
                      :key="msg.text"
                      class="d-flex py-3 font-weight-medium text-body-2"
                    >
                      <div
                        class="msg-color-bar me-2"
                        :style="`background-color: ${msg.color || 'grey'}`"
                      ></div>
                      <div v-if="msg.fields">
                        <div v-for="field in msg.fields" :key="field.title">
                          <div class="font-weight-semibold mb-2">
                            {{ field.title }}
                          </div>
                          <span>{{ field.value }}</span>
                        </div>
                      </div>
                      <span v-html="msg.text" />
                    </div>
                  </div>
                  <div v-else>
                    {{ msg.body.title }}
                  </div>
                </div>
              </v-col>
              <v-divider></v-divider>
            </div>
          </div>
          <div v-else-if="msgsLoading" class="w-100 text-center ma-10">
            <v-progress-circular
              v-show="msgsLoading"
              color="secondary"
              :indeterminate="true"
            />
          </div>
          <div v-else class="w-100 text-center ma-10">
            <span class="dark500--text font-weight-medium ms-2"
              >Nothing here yet :)</span
            >
          </div>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { groupBy, isArray } from 'lodash'
import CommonFunctions from 'components/mixins/common_functions'
import deviceMixin from 'components/mixins/device-mixin'

const CHANNEL_ICONS = {
  Reservations: 'mdi-calendar-month-outline',
  'Store Purchases': 'mdi-shopping-outline',
  'New Reviews': 'mdi-comment-text-outline',
}

export default {
  name: 'Feed',
  mixins: [CommonFunctions, deviceMixin],
  data() {
    return {
      msgsLoading: true,
      CHANNEL_ICONS,
      panels: 0,
      selectedChannel: null,
      feeds: [],
      messages: [],
      drawer: false,
    }
  },
  mounted() {
    this.getChannels()
  },
  computed: {
    panelData() {
      return groupBy(this.feeds, 'category')
    },
    selectedChannelObject() {
      return (
        this.feeds.find(channel => channel.id === this.selectedChannel) || {}
      )
    },
  },
  methods: {
    expandAllPanels() {
      this.panels = Object.keys(this.panelData).map((_section, i) => i)
    },
    onSelectChannel(channel) {
      this.msgsLoading = true
      this.selectedChannel = channel.id
      this.getChannelFeed(this.selectedChannel)
      this.drawer = false
    },
    async getChannelFeed(id) {
      const { data } = await this.$store.dispatch('feeds/getFeed', { id })
      this.messages = data.messages
      this.$nextTick(() => {
        this.scrollToEnd()
      })
      this.msgsLoading = false
    },
    async getChannels() {
      const { data } = await this.$store.dispatch('feeds/getFeeds')
      this.feeds = data.feeds
      this.onSelectChannel(this.feeds[0])
      this.expandAllPanels()
    },
    isBodyArray(item) {
      return isArray(item)
    },
    scrollToEnd() {
      const container = this.$refs.feedRef
      if (container) container.scrollTop = container.scrollHeight
    },
  },
}
</script>

<style scoped>
.v-100 {
  height: calc(100vh - 64px);
}
.v-expansion-panel {
  background-color: unset !important;
}

::v-deep .v-expansion-panel-header__icon {
  display: none !important;
}
::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}
.selected-channel {
  background-color: var(--v-dark700-base);
  color: var(--v-light300-base);
}
.channel-btn {
  height: 43px !important;
}
.item-title-row {
  height: 40px;
}
.msg-color-bar {
  width: 3px;
}
.feed {
  height: calc(100vh - 64px);
  overflow-y: auto;
}
.sticky-section {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
