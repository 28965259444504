<template>
  <div v-show="!loading">
    <div class="grey lighten-4">
      <v-container class="max-w-1600 pb-0">
        <v-tabs
          v-model="paramsTab"
          slider-color="white"
          background-color="#f6f6f7"
          active-class="white"
          :grow="isMobile"
        >
          <!--          <v-tab-->
          <!--            v-if="isDesignedVr && isAdmin"-->
          <!--            :disabled="!showModule('accounting')"-->
          <!--            href="#dash"-->
          <!--          >-->
          <!--            <v-icon class="mr-md-2">$activity</v-icon>-->
          <!--            <span class="d-none d-md-inline text-capitalize">Dash</span>-->
          <!--          </v-tab>-->
          <v-tab
            :disabled="
              !(
                showModule('accounting') &&
                showModule('ops') &&
                (isPropertyManager ||
                  isAdmin ||
                  isAccountant ||
                  isHotelReception)
              )
            "
            href="#contractors-payments"
          >
            <v-icon class="mr-md-2">$bill</v-icon>
            <span class="d-none d-md-inline text-capitalize">{{
              $t('Contractors Payments')
            }}</span>
          </v-tab>
          <v-tab
            :disabled="
              !(
                hasAbility('listings-owners-payments') &&
                showModule('accounting')
              )
            "
            href="#listings-payments"
          >
            <v-icon class="mr-md-2">mdi-credit-card-marker-outline</v-icon>
            <span class="d-none d-md-inline text-capitalize">{{
              $t('Owners Payments')
            }}</span>
          </v-tab>
          <v-tab
            v-if="showModule('accounting') && hasAbility('management-payments')"
            href="#management-payments"
          >
            <v-icon class="mr-md-2">mdi-credit-card-chip-outline</v-icon>
            <span class="d-none d-md-inline text-capitalize">{{
              $t('Management Payments')
            }}</span>
          </v-tab>
          <v-tab
            v-if="showModule('accounting') && hasAbility('ach-transactions')"
            href="#ach-transactions"
          >
            <v-icon class="mr-md-2">mdi-cash-fast</v-icon>
            <span class="d-none d-md-inline text-capitalize">{{
              $t('ACH Transactions')
            }}</span>
          </v-tab>
          <v-tab
            :disabled="
              !(
                showModule('accounting') &&
                (hasBankAccounts || isAdmin || isAccountant)
              )
            "
            href="#accounting"
          >
            <v-icon class="mr-md-2">$calculator</v-icon>
            <span class="d-none d-md-inline text-capitalize">{{
              $t('Bank Transactions')
            }}</span>
          </v-tab>
          <v-tab
            v-if="
              showModule('accounting') && hasAbility('modify-bank-accounts')
            "
            href="#bank-accounts"
          >
            <v-icon class="mr-md-2">mdi-bank-outline</v-icon>
            <span class="d-none d-md-inline text-capitalize"
              >Bank Accounts</span
            >
          </v-tab>
          <v-tab href="#expenses">
            <v-icon class="mr-md-2">$accounting</v-icon>
            <span class="d-none d-md-inline text-capitalize">{{
              $t('Expenses')
            }}</span>
          </v-tab>
          <v-tab v-if="hasBankAccounts || isAdmin || isAccountant" href="#res">
            <v-icon class="mr-md-2">$money</v-icon>
            <span class="d-none d-md-inline text-capitalize">{{
              $t('Reservations')
            }}</span>
          </v-tab>
          <v-tab
            v-if="hasBankAccounts || isAdmin || isAccountant"
            href="#payments"
          >
            <v-icon class="mr-md-2">$finance</v-icon>
            <span class="d-none d-md-inline text-capitalize">{{
              $t('Payments')
            }}</span>
          </v-tab>
          <v-tab href="#expense-types">
            <v-icon class="mr-md-2">view_timeline</v-icon>
            <span class="d-none d-md-inline text-capitalize">{{
              $t('Expense types')
            }}</span>
          </v-tab>
        </v-tabs>
      </v-container>
    </div>
    <div>
      <div>
        <v-tabs-items v-model="paramsTab" touchless>
          <v-tab-item
            v-if="hasAbility('accounting-dash')"
            value="dash"
            class="pl-4 pr-4 main-background"
          >
            <accounting-dash />
          </v-tab-item>
          <v-tab-item
            v-if="hasAbility('modify-bank-accounts')"
            value="bank-accounts"
          >
            <bank-accounts />
          </v-tab-item>
          <v-tab-item value="expenses" class="pl-4 pr-4 main-background">
            <expenses
              v-if="paramsTab === 'expenses'"
              class="mt-3"
              :show-filters="true"
              :management-view="true"
              :enable-global-create="true"
            />
          </v-tab-item>
          <v-tab-item value="expense-types" class="pl-4 pr-4 main-background">
            <expense-types v-if="paramsTab === 'expense-types'" />
          </v-tab-item>
          <v-tab-item value="payments" class="pl-4 pr-4 main-background">
            <payments
              class="mt-3"
              :show-filters="true"
              :management-view="true"
              :enable-global-create="true"
            />
          </v-tab-item>
          <v-tab-item value="res" class="main-background">
            <reservation-manager />
          </v-tab-item>
          <v-tab-item
            v-if="hasBankAccounts || isAdmin || isAccountant"
            value="accounting"
            class="pl-4 pb-2 pr-4 main-background"
          >
            <bank-transactions class="pt-3" />
          </v-tab-item>
          <v-tab-item
            v-if="
              isPropertyManager || isAdmin || isAccountant || isHotelReception
            "
            value="contractors-payments"
            class="pl-4 pr-4 main-background"
          >
            <listing-tasks-payments />
          </v-tab-item>
          <v-tab-item
            v-if="hasAbility('listings-owners-payments')"
            value="listings-payments"
            class="pl-4 pb-2 pr-4 main-background"
          >
            <listings-payments
              :natcha-support="config.is_natcha_support"
              :payment-period="config.listing_payment_period"
            />
          </v-tab-item>
          <v-tab-item
            v-if="hasAbility('management-payments')"
            value="management-payments"
            class="pl-4 pr-4 main-background"
          >
            <management-payments :natcha-support="config.is_natcha_support" />
          </v-tab-item>
          <v-tab-item
            v-if="hasAbility('ach-transactions')"
            value="ach-transactions"
            class="pl-4 pr-4 main-background"
          >
            <ach-transactions v-if="paramsTab === 'ach-transactions'" />
          </v-tab-item>
          <v-tab-item v-if="hasAbility('bi-accounting')" value="bi-accounting">
            <iframe
              width="100%"
              height="1000"
              src="https://app.powerbi.com/reportEmbed?reportId=8e5ff6a2-1fc5-41af-8eb5-bb9617d6d171&appId=6afcd18f-37df-4c94-8ad0-7fe3dd4f5ad5&autoAuth=true&ctid=cfaa53b4-8414-410c-ad73-470f4cecfeab&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLWVhc3QtYS1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9"
              frameborder="0"
              allowFullScreen="true"
            ></iframe>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

import BankTransactions from 'components/bank-transactions'
import Expenses from 'components/expenses/expenses'
import ListingTasksPayments from 'components/listing-tasks-payments'
import ListingsPayments from 'components/listings-payments'
import get from 'lodash/fp/get'
import isEmpty from 'lodash/fp/isEmpty'
import AchTransactions from 'components/accounting/ach-transactions'
import ReservationManager from 'components/reservation-manager'
import ManagementPayments from 'components/management-payments'
import Payments from 'components/payments'
import AccountingDash from 'components/accounting/accounting-dash.vue'
import DeviceMixin from 'components/mixins/device-mixin'
import BankAccounts from 'components/bank-accounts.vue'
import ExpenseTypes from 'components/expense-types'

export default {
  components: {
    BankAccounts,
    AccountingDash,
    Payments,
    ManagementPayments,
    AchTransactions,
    ListingsPayments,
    ReservationManager,
    ListingTasksPayments,
    BankTransactions,
    Expenses,
    ExpenseTypes,
  },
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  computed: {
    hasBankAccounts() {
      return !isEmpty(get('bank_accounts_numbers', this.$store.state.user))
    },
    config() {
      return this.$store.state.app.configuration
    },
  },
}
</script>
