<template>
  <div>
    <v-container>
      <div v-if="publishableKey" class="mb-5">
        <seam-connect-account-button
          :publishable-key="publishableKey"
        ></seam-connect-account-button>
      </div>

      <v-data-table
        :headers="headers"
        :items="allIotDevices"
        class="elevation-1"
        item-value="device_id"
        @click:row="onRowClick"
      >
        <template v-slot:item.image="{ item }">
          <v-img
            :src="item.properties.image_url"
            :alt="item.properties.image_alt_text"
            max-width="100"
            height="100"
            class="rounded-circle"
          ></v-img>
        </template>

        <template v-slot:item.properties.locked="{ item }">
          <span v-if="typeof item.properties.locked === 'boolean'">
            <v-icon>{{
              item.properties.locked
                ? 'mdi-lock-outline'
                : 'mdi-lock-open-outline'
            }}</v-icon>
          </span>
          <span v-else>No Data</span>
        </template>

        <template v-slot:item.properties.online="{ item }">
          <span v-if="typeof item.properties.online === 'boolean'">
            <v-icon
              small
              :color="item.properties.online ? 'success' : 'warning'"
              >circle</v-icon
            >
          </span>
          <span v-else>No Data</span>
        </template>

        <template v-slot:item.properties.battery_level="{ item }">
          <BatteryLevel
            v-if="item.properties.battery_level"
            :percent="
              parseFloat(item.properties.battery_level).toFixed(2) * 100
            "
          />
          <span v-else>No data</span>
        </template>

        <template v-slot:item.custom_metadata.listing_id="{ item }">
          <listing-picker
            @input="onFieldChange(item, $event)"
            :value="item.custom_metadata.listing_id"
          />
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ListingPicker from 'components/listing/listing-picker'
import BatteryLevel from 'components/iot/battery-level.vue'

export default {
  components: {
    ListingPicker,
    BatteryLevel,
  },
  data() {
    return {
      dialog: false,
      headers: [
        { text: 'Image', value: 'image', sortable: false },
        { text: 'Device Name', value: 'display_name' },
        { text: 'Locked', value: 'properties.locked' },
        { text: 'Online', value: 'properties.online' },
        { text: 'Battery Level', value: 'properties.battery_level' },
        { text: 'Manufacturer', value: 'properties.manufacturer' },
        { text: 'Model', value: 'properties.model.display_name' },
        { text: 'Listing', value: 'custom_metadata.listing_id' },
      ],
    }
  },
  computed: {
    ...mapState('seamIot', ['allIotDevices', 'publishableKey']),
  },
  methods: {
    ...mapActions('seamIot', [
      'getAllDevices',
      'updateDevice',
      'getPublishableKey',
    ]),
    onFieldChange(item, value) {
      if (!value) return
      const payload = {
        device_id: item.device_id,
        listing_id: value,
      }
      const index = this.allIotDevices.findIndex(
        device => device.device_id === item.device_id
      )
      if (index !== -1) {
        this.$set(this.allIotDevices, index, { ...item, listing_id: value })
      }
      this.updateDevice(payload)
    },
    onRowClick(item) {
      console.log('Clicked on row', item)
    },
  },
  mounted() {
    this.getPublishableKey()
    this.getAllDevices()
  },
}
</script>

<style scoped></style>
