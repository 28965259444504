<template>
  <v-card min-width="360" elevation="0">
    <conversation-info-title :guesty-id="reservation.reservation_guesty_id">
      <template #actions>
        <v-btn
          v-if="reservation.reservation_guesty_id"
          :to="`/dashboard/reservation/${reservation.reservation_guesty_id}`"
          :target="isMobile ? '_self' : '_blank'"
          icon
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>

        <v-btn
          v-if="pmsLink"
          :href="pmsLink"
          :target="isMobile ? '_self' : '_blank'"
          small
          class="sm-overline-text"
          icon
        >
          PMS
        </v-btn>

        <v-btn
          v-if="reservation.cleaning_listing_task_id"
          :to="{
            name: 'lt-page',
            params: { id: reservation.cleaning_listing_task_id },
          }"
          target="_blank"
          icon
        >
          <v-icon>mdi-broom</v-icon>
        </v-btn>
        <v-btn
          v-if="$route.name !== 'guests-chat'"
          :target="isMobile ? '_self' : '_blank'"
          icon
          :to="{ name: 'guests-chat', params: { id: conversation.guesty_id } }"
        >
          <v-icon>$chat</v-icon>
        </v-btn>
        <reservation-edit-modal
          :key="reservation.id"
          :reservation="reservation"
          :listing="listing"
          @change="onReservationEdit"
        />
      </template>
    </conversation-info-title>
    <v-card-text>
      <div class="d-flex align-center mb-6">
        <user-avatar
          class="align-self-start"
          :name="conversation.guest.full_name"
          :size="50"
          :color="stringToHslColor(conversation.guest.full_name)"
        />
        <div class="ml-4 p-relative flex-1">
          <p
            v-if="isAgent"
            class="text-caption blue--text mb-0 p-absolute guest-label"
          >
            Agent
          </p>
          <p
            v-else
            class="text-caption text-secondary mb-0 p-absolute guest-label"
          >
            Guest
          </p>
          <p class="text-body-1 black--text mb-0 font-weight-medium">
            {{ conversation.guest.full_name }}
          </p>
          <dir v-if="displayedPhone">
            <v-btn class="pa-0" :href="`tel:${displayedPhone}`" text x-small>
              <v-icon class="mr-1" x-small>mdi-phone</v-icon>
              {{ displayedPhone }}
            </v-btn>
            <copy-clipboard color="secondary" :text="displayedPhone" />
          </dir>
          <p v-if="displayedEmail" class="text-caption">
            <v-icon x-small class="pr-1">person</v-icon>
            <span class="black--text">User email: </span>
            {{ displayedEmail }}
          </p>
        </div>
        <div class="text-center p-relative">
          <v-tooltip
            v-if="reservation.risk_analysis"
            left
            max-width="350px"
            color="white"
            top
          >
            <template #activator="{ on }">
              <v-progress-circular
                :rotate="-90"
                :size="45"
                :width="8"
                :value="reservation.risk_analysis.risk_score * 100"
                :color="getRiskColor(reservation.risk_analysis.risk_score)"
                v-on="on"
              >
                {{ round(reservation.risk_analysis.risk_score * 100) }}
              </v-progress-circular>
            </template>
            <v-row class="grey--text">
              <v-col
                v-if="!isEmpty(reservation.risk_analysis.bad_reasons)"
                cols="12"
                class="red-warn headline-font center-text"
              >
                Bad indications:
              </v-col>
              <v-col
                v-for="badReason in reservation.risk_analysis.bad_reasons"
                :key="badReason"
                cols="12"
                class="center-text"
                mt-1
                mb-1
              >
                <v-icon class="red-warn">report_problem</v-icon>
                {{ badReason }}
              </v-col>
              <v-col
                v-if="!isEmpty(reservation.risk_analysis.good_reasons)"
                cols="12"
                class="green--text headline-font center-text"
              >
                Good indications:
              </v-col>
              <v-col
                v-for="goodReason in reservation.risk_analysis.good_reasons"
                :key="goodReason"
                cols="12"
                class="center-text"
                mt-1
                mb-1
              >
                <v-icon class="green--text">done_outline</v-icon>
                {{ goodReason }}
              </v-col>
            </v-row>
          </v-tooltip>
          <div class="text-caption">Guest Risk</div>
        </div>
      </div>
      <div
        v-if="reservation.multi_reservation_id"
        class="d-flex align-center info--text pointer"
        @click="
          goToReservation(reservation.parent_reservation.reservation_guesty_id)
        "
      >
        <div class="text-subtitle-2">Parent Reservation</div>
        <v-icon right x-small>mdi-open-in-new</v-icon>
      </div>
      <reservations-select
        :readonly="singleReservation"
        :pre-selected="reservation.reservation_guesty_id"
        :res-options="conversation.related_reservations"
        @changed="reservationChanged"
      />
      <div class="d-flex align-center mt-10 mb-6 p-relative">
        <v-img
          max-width="100px"
          class="rounded-img mr-3 align-self-start"
          :src="listing.picture"
        />
        <div>
          <div class="listing-risk">
            <span class="text-caption font-weight-medium mr-2"
              >Listing Risk:</span
            >
            <span>
              <v-chip
                class="px-2"
                x-small
                label
                :color="sensitivityColor(reservation.listing.sensitivity)"
                >{{ reservation.listing.sensitivity }}</v-chip
              >
            </span>
          </div>
          <div class="text-subtitle-2 font-weight-medium">
            <div>
              Category -
              {{ listingNicknameById(reservation.original_listing_id) }}
            </div>
            <span
              v-if="region"
              class="text-capitalize font-weight-medium secondary--text"
              >{{ region.name }} -
            </span>
            <span class="text-body-1 black--text mb-0 font-weight-medium"
              >{{ listing.nickname }} <CopyClipboard :text="listing.nickname"
            /></span>
          </div>
        </div>
      </div>
      <v-divider />
      <div
        class="d-flex align-center justify-center font-weight-semibold text-body-2 blue-grey--text text--darken-4 w-100 my-6"
      >
        <div v-if="reservation">
          {{ checkInDate }}
        </div>
        <div class="mx-2"><v-icon small>mdi-arrow-right</v-icon></div>
        <div v-if="reservation" cols="4">
          {{ checkOutDate }}
        </div>
      </div>
      <v-divider />
      <v-row v-if="reservation" class="my-1 text-center">
        <v-col cols="6" class="font-weight-medium mb-1">
          <div class="text-caption secondary-text text--darken-2">Price</div>
          <div class="black--text text-body-1 font-weight-medium">
            {{ dollarFormatter(calcReservationPrice(reservation)) }}
          </div>
        </v-col>
        <v-divider vertical />
        <v-col cols="6" class="font-weight-medium mb-1">
          <div class="text-caption secondary-text text--darken-2">Channel</div>
          <div class="black--text text-body-1 font-weight-medium">
            <span v-if="reservation.agent_guesty_conversation_id">
              <a
                target="_blank"
                :href="`dashboard/guest-experience/agents?conversation=${reservation.agent_guesty_conversation_id}`"
              >
                {{ reservation.source }}
              </a>
            </span>
            <span v-else>
              {{ reservation.source }}
            </span>
          </div>
        </v-col>
      </v-row>
      <v-divider />
      <v-row v-if="reservation" class="my-1 text-center">
        <v-col cols="6" class="font-weight-medium mb-1">
          <div class="text-caption secondary-text text--darken-2">Guests</div>
          <div class="black--text text-body-1 font-weight-medium">
            {{ reservation.guests_count }}
          </div>
        </v-col>
        <v-divider vertical />

        <v-col cols="6" class="font-weight-medium mb-1">
          <div class="text-caption secondary-text text--darken-2">Nights</div>
          <div class="black--text text-body-1 font-weight-medium">
            {{ reservation.nights_count }}
          </div>
        </v-col>
      </v-row>
      <v-divider class="mb-2" />
      <v-btn
        v-if="
          !reservation.pre_approved &&
          reservation.status === 'inquiry' &&
          reservation.source === 'Airbnb'
        "
        target="_blank"
        class="success white--text full-width pr-2 mt-4"
        text
        @click="markPreApproved"
        >Pre-approve
      </v-btn>
      <span v-if="reservation.pre_approved" class="success">Pre-approved</span>
      <v-flex
        v-if="
          isAdmin &&
          reservation.balance_due > 0 &&
          (reservation.is_merchant || isAgentReserved)
        "
      >
        <v-btn
          outlined
          color="success"
          class="w-100 mt-4"
          @click="markAspaid(reservation.id)"
          >Mark as paid
        </v-btn>
      </v-flex>
      <div v-if="isAgentReserved" class="my-4">
        <conversation-info-agent-confirm
          :reservation="reservation"
          :is-owner-vacation="false"
          :disabled="!!reservation.balance_due"
          @after-confirm="conversationRefresh"
        />
      </div>
      <v-row no-gutters class="py-2 justify-space-between">
        <v-col cols="auto">
          <listing-task-module
            :pre-filled-listing-id="[reservation.listing_id]"
            class="mt-2 mb-2"
            button-text="SC"
            :reservation="reservation"
            :after-save-func="afterTaskCreate"
          >
            <template #customBtn="{ on }">
              <v-btn
                depressed
                color="blue-grey"
                dark
                small
                class="mt-2"
                v-on="on"
              >
                <v-icon left class="mr-1">add</v-icon>
                {{ $t('New SC') }}
              </v-btn>
            </template>
          </listing-task-module>
        </v-col>
        <v-col
          v-if="reservation.status === 'confirmed' && isDesignedVr"
          cols="auto"
        >
          <v-btn
            small
            depressed
            color="blue-grey"
            dark
            class="mt-2"
            @click="sendInfo"
          >
            <v-icon small class="pr-1">lock</v-icon>
            Send Codes
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            depressed
            small
            outlined
            color="blue-grey"
            class="mt-2"
            dark
            @click="openInfo"
          >
            <v-icon small class="pr-1">info</v-icon>
            Info
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-dialog v-model="dialog" width="1200">
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                color="blue-grey"
                dark
                small
                outlined
                class="mt-2"
                append-icon="search"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left small class="pr-1">search</v-icon>
                Listings
              </v-btn>
            </template>
            <v-card class="pa-3">
              <listings-search v-if="dialog" :pre-config="preConfig" />
            </v-card>
          </v-dialog>
        </v-col>
        <v-col v-if="isDesignedVr" cols="auto">
          <v-btn
            class="text-capitalize mt-2"
            color="blue-grey"
            dark
            outlined
            small
            elevation="0"
            @click="showCatalogInfo"
          >
            Knowledge center
          </v-btn>
        </v-col>
      </v-row>
      <v-layout column>
        <span
          v-if="
            showModule('comm') &&
            reservation &&
            !reservation.pre_check_in &&
            reservation.status === 'confirmed'
          "
          class="text-center d-flex justify-center align-center mt-4"
          ><v-icon small class="red-icon pr-1">warning</v-icon> Pre-check-in:
          <span class="red--text ms-1 font-weight-medium">Not filled</span>
          <span>
            <CopyClipboard :text="preCheckInLink" />
          </span>
        </span>
        <span
          v-if="
            reservation &&
            reservation.pre_check_in &&
            reservation.status === 'confirmed'
          "
          class="text-center mt-4"
          :class="
            reservation.pre_check_in.status !== 'Done'
              ? 'red--text'
              : 'green-text'
          "
        >
          <v-icon
            v-if="reservation.pre_check_in.status !== 'Done'"
            class="red-icon me-1"
            small
            >mdi-alert-outline</v-icon
          >
          Pre-check-in:
          <span class="font-weight-bold">
            {{
              STATUS_LABELS[reservation.pre_check_in.status] ||
              reservation.pre_check_in.status
            }}
          </span></span
        >
        <v-tooltip
          v-if="
            reservation.pre_check_in &&
            reservation.pre_check_in.status !== 'Done'
          "
          top
        >
          <template #activator="{ on }">
            <v-icon small class="icon ml-1 alt help-icon" v-on="on"
              >fas fa-question-circle
            </v-icon>
          </template>
          <v-layout wrap style="min-width: 100px">
            <pre-check-in-card
              :pre-check-in="reservation.pre_check_in"
            ></pre-check-in-card>
          </v-layout>
        </v-tooltip>
        <v-layout v-if="!reservation.agent_id" wrap mt-2 mb-2>
          <v-flex v-if="reservation.status === 'reserved'" xs6 pr-1>
            <v-btn
              color="success"
              class="full-width white--text"
              :loading="approving"
              @click="approveRes"
              >approve
            </v-btn>
          </v-flex>
          <v-flex v-if="reservation.status === 'reserved'" xs6 pl-1>
            <v-btn
              color="error"
              :loading="declining"
              class="full-width white--text"
              @click="declineRes"
              >decline
            </v-btn>
          </v-flex>
          <span v-if="conversation.extra_data['approval_request']">
            Request has been
            {{ conversation.extra_data['approval_request'] }}, might take few
            min to update.
          </span>
        </v-layout>
      </v-layout>
      <v-row>
        <v-col class="pl-0">
          <check-in-out-form
            :reservation="reservation"
            @change="updateCheckInOutTimes"
          />
        </v-col>
      </v-row>

      <v-row wrap no-gutters class="my-2">
        <v-col
          v-if="!reservation.is_merchant && reservation.status === 'confirmed'"
          cols="12"
        >
          <div class="d-flex text-capitalize text-body-1">
            <span>
              <span class="bolder">Accommodation:</span>
              <span class="text-body-2 green--text"
                >{{
                  `Paid via channel (${dollarFormatter(reservation.price)})`
                }}
              </span>
            </span>
          </div>
        </v-col>
        <v-col
          v-for="(intent, idx) in paymentIntents"
          :key="idx"
          cols="12"
          class=""
        >
          <div class="d-flex justify-space-between text-capitalize text-body-1">
            <span>
              <span class="bolder"
                >{{
                  intent.metadata['type'] === 'accommodation'
                    ? 'Reservation'
                    : 'Deposit'
                }}:</span
              >
              <span :class="['body-2', textClass(intent)]"
                >{{ ` ${paymentStatus(intent)} (${intent.amount / 100})` }}
              </span>
            </span>
            <span
              v-show="
                intent.metadata['type'] === 'accommodation' ||
                (intent.metadata['type'] === 'deposit' &&
                  reservation.should_pay_deposit)
              "
            >
              <a
                v-show="isAdmin"
                class="text-decoration-none"
                target="_blank"
                :href="`https://dashboard.stripe.com/payments/${intent.payment_intent_id}`"
                ><v-icon small>fab fa-stripe-s</v-icon></a
              >
              <a
                class="text-decoration-none"
                target="_blank"
                :href="`${portalDomain}/checkout/${intent.payment_intent_id}`"
                ><v-icon small>fas fa-external-link-alt</v-icon></a
              >
              <CopyClipboard
                description="Copy payment link"
                :text="`${portalDomain}/checkout/${intent.payment_intent_id}`"
                >Copy Link</CopyClipboard
              ></span
            >
          </div>
        </v-col>
        <v-col v-if="hasRefunds" cols="12">
          <div class="notification--text font-weight-medium text-body-2">
            Has Refunds
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon small v-on="on">info</v-icon>
              </template>
              <div>More information can be found in payment tab</div>
            </v-tooltip>
          </div>
        </v-col>
        <v-col v-if="disputes.length" cols="12">
          <div class="font-weight-medium warning--text text-body-2">
            Has Disputes
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon small v-on="on">info</v-icon>
              </template>
              <v-sheet> More information can be found in payment tab</v-sheet>
            </v-tooltip>
          </div>
        </v-col>
        <v-col v-if="noticeMessage" cols="12">
          <div class="d-flex text-capitalize text-body-1">
            <span>
              <span class="bolder">Res Notification:</span>
              <span class="red--text text-body-2">
                {{ `${noticeMessage} notice` }}
              </span>
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row wrap justify-center class="mt--1">
        <v-col cols="12">
          <reservation-calendar
            v-if="currentListing && currentListing.days_rates"
            :add-on-res="reservation"
            :slim-version="true"
            listing-type="currentEditListing"
          />
        </v-col>
        <v-col
          v-if="listing.has_assigned_parking || listing.has_parking_pass"
          cols="12"
        >
          <v-expansion-panels mt-3>
            <v-expansion-panel key="final">
              <v-expansion-panel-header class="pa-2">
                <span class="small-text">
                  <v-icon class="green--text">local_parking</v-icon>
                  Parking info
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="column med-font">
                  <p v-if="listing.has_parking_pass">Has parking pass</p>
                  <p v-if="listing.has_assigned_parking">
                    Has assigned parking:
                    {{ listing.assigned_parking_desc }}
                  </p>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col v-if="showModule('store')" cols="12" class="pa-0">
          <v-expansion-panels mt-3 flat accordion>
            <v-expansion-panel key="final">
              <v-expansion-panel-header class="pa-2">
                <span class="small-text">
                  <v-icon class="dark-yellow">store</v-icon>
                  Store items options
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div>
                  <v-col
                    v-for="storeItem in reservationStoreItems"
                    :key="storeItem.id"
                    cols="12"
                    class="pa-2"
                  >
                    <div class="d-flex justify-space-between">
                      <div>
                        <div
                          v-if="storeItem.status === 'not_available'"
                          class="grey--text"
                        >
                          <v-icon small class="me-1">remove</v-icon>
                          <span class="bolder">{{ storeItem.item.name }}</span>
                          <br />
                          <span>not available.</span>
                        </div>

                        <div v-else-if="storeItem.status === 'available'">
                          <v-icon small class="cyan-icon me-1"
                            >done_outline</v-icon
                          >
                          <span class="bolder">{{ storeItem.item.name }}</span>
                          <br />
                          <span>can be purchased.</span>
                        </div>

                        <div
                          v-else-if="
                            storeItem.status === 'purchased' &&
                            storeItem.granted
                          "
                        >
                          <v-icon small class="green--text me-1"
                            >mdi-hand-heart-outline
                          </v-icon>
                          <span class="bolder">{{ storeItem.item.name }}</span>
                          <br />
                          <span>granted.</span>
                        </div>

                        <div v-else-if="storeItem.status === 'purchased'">
                          <v-icon small class="green--text me-1">money</v-icon>
                          <span class="bolder">{{ storeItem.item.name }}</span>
                          <br />
                          <span>purchased.</span>
                        </div>

                        <div v-else-if="storeItem.status === 'future'">
                          <v-icon small class="yellow-health me-1"
                            >mdi-lock</v-icon
                          >
                          <span class="bolder">{{ storeItem.item.name }}</span>
                          <br />
                          <span>
                            will be available to purchase from<br />
                            {{
                              parseDateAndHourInEst(
                                storeItem.item.available_from
                              )
                            }}
                            EST
                          </span>
                        </div>
                      </div>
                      <div>
                        <div class="d-flex flex-column align-end">
                          <span v-if="storeItem.price.total">
                            ({{ dollarFormatter(storeItem.price.total) }})
                          </span>
                          <span v-else-if="storeItem.price.start_from">
                            (from
                            {{ dollarFormatter(storeItem.price.start_from) }})
                          </span>
                        </div>
                        <store-item-dialog
                          v-if="showGrantItemButton(storeItem)"
                          :reservation-id="reservation.id"
                          :store-item="storeItem.item"
                          :price="storeItem.price"
                          :options="storeItem.options"
                          :status="storeItem.status"
                          @after-grant="conversationRefresh"
                        >
                          <template #activator="{ on }">
                            <v-btn color="primary" outlined x-small v-on="on">
                              Grant
                            </v-btn>
                          </template>
                        </store-item-dialog>
                      </div>
                    </div>
                    <v-divider />
                  </v-col>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider />
        </v-col>
        <v-col cols="12" class="pa-0">
          <v-expansion-panels mt-3 flat accordion>
            <v-expansion-panel key="final">
              <v-expansion-panel-header class="pa-2">
                <span class="small-text">
                  <v-icon class="purple-icon">manage_accounts</v-icon>
                  Personal
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-2">
                <Personal
                  :personal="listing.personal"
                  disabled
                  :hide-save="true"
                  :override-personal-types="[
                    'cleaning_supervisor',
                    'pool',
                    'pest',
                    'landscape',
                    'host',
                    'region_manager',
                  ]"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider />
        </v-col>
        <v-col cols="12" class="pa-0">
          <v-expansion-panels mt-3 accordion flat>
            <v-expansion-panel key="final">
              <v-expansion-panel-header class="pa-2">
                <span class="small-text">
                  <v-icon class="cyan-icon">house</v-icon>
                  House public info
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-2">
                <v-row>
                  <v-col v-if="listing.marketing_content.space" cols="12">
                    <h4>The space</h4>
                    <span
                      class="small-text"
                      v-html="listing.marketing_content.space"
                    ></span>
                  </v-col>
                  <v-col v-if="listing.marketing_content.notes" cols="12">
                    <h4>Notes</h4>
                    <span
                      class="small-text"
                      v-html="listing.marketing_content.notes"
                    ></span>
                  </v-col>
                  <v-col v-if="listing.marketing_content.access" cols="12">
                    <h4>Access</h4>
                    <span
                      class="small-text"
                      v-html="listing.marketing_content.access"
                    ></span>
                  </v-col>
                  <v-col v-if="listing.marketing_content.summary" cols="12">
                    <h4>Summary</h4>
                    <span
                      class="small-text"
                      v-html="listing.marketing_content.summary"
                    ></span>
                  </v-col>
                  <v-col v-if="listing.marketing_content.houseRules" cols="12">
                    <h4>House Rules</h4>
                    <span
                      class="small-text"
                      v-html="listing.marketing_content.houseRules"
                    ></span>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider />
        </v-col>
        <v-col v-if="listing.terms" cols="12" class="pa-0">
          <v-expansion-panels mt-3 flat>
            <v-expansion-panel
              v-if="
                listing.terms.cancellation || reservation.cancellation_policy
              "
              key="final"
            >
              <v-expansion-panel-header class="pa-2">
                <span class="small-text">
                  <v-icon class="red-warn">cancel_presentation</v-icon>
                  Cancellation info
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col v-if="listing.terms.cancellation" cols="12">
                    <h4 class="red-warn">House Cancellation</h4>
                    <span
                      class="small-text"
                      v-html="listing.terms.cancellation"
                    ></span>
                  </v-col>
                  <v-col v-if="reservation.cancellation_policy" cols="12">
                    <h4 class="red-warn">Reservation Cancellation</h4>
                    <span
                      class="small-text"
                      v-html="reservation.cancellation_policy"
                    ></span>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import ColorsMixin from 'components/mixins/colors-mixin'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ListingTaskModule from './listing-task-module'
import ReservationCalendar from 'components/calendar/reservation-calendar'
import PreCheckInCard from 'components/pre-check-in/pre-check-in-card'
import CopyClipboard from 'components/copy-clipboard'
import ReservationsSelect from 'components/chat/reservations-select'
import ListingsSearch from 'components/listings-search'
import get from 'lodash/fp/get'
import Personal from 'components/listing/personal'
import ConversationInfoTitle from 'components/chat/conversation-info-title'
import { orderBy } from 'lodash'
import StoreItemDialog from 'components/store/store-item-dialog'
import CheckInOutForm from 'components/common/check-in-out-form'
import ReservationEditModal from 'components/reservation/reservation-edit-modal'

import { mapMutations, mapState } from 'vuex'
import FormattersMixin from 'components/mixins/formatters-mixin'
import UserAvatar from 'components/common/user-avatar.vue'
import NavigationMixin from 'components/mixins/navigation-mixin'
import ConversationInfoAgentConfirm from 'components/conversation-info-agent-confirm.vue'

const STATUS_LABELS = {
  New: 'Not Filled',
  'In Progress': 'In Progress',
  Done: 'Filled',
}

export default {
  mixins: [
    CommonFunctions,
    PermissionsMixin,
    FormattersMixin,
    ColorsMixin,
    NavigationMixin,
  ],
  props: ['conversation', 'singleReservation'],
  watch: {
    'reservation.listing_id': {
      handler(newVal) {
        if (newVal) this.loadListing()
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('regions', ['regions']),
    reservationStoreItems() {
      return orderBy(
        this.reservation.store_items_options || [],
        'item.is_manual',
        'desc'
      )
    },
    displayedPhone() {
      return (
        get('guest.primary_phone', this.conversation) ||
        get('guest.phones[0]', this.conversation) ||
        get('active_reservation.primary_phone', this.conversation)
      )
    },
    displayedEmail() {
      return (
        this.conversation.registered_email ||
        this.conversation.active_reservation.primary_email
      )
    },
    preConfig() {
      return {
        guestAmount: get('beds', this.listing) ? this.listing.beds * 2 : 2,
        from: this.$moment(this.reservation.check_in)
          .toISOString()
          .substr(0, 10),
        to: this.$moment(this.reservation.check_out)
          .toISOString()
          .substr(0, 10),
      }
    },
    region() {
      return this.regions.find(r => r.id === this.listing.region_id)
    },
    portalDomain() {
      return 'https://guest.easyair.io'
    },
    listing() {
      return this.reservation.listing
    },
    reservation() {
      return this.$store.getters.chosenChatReservation
    },
    currentListing() {
      return this.$store.state.currentListing
    },
    noticeMessage() {
      const messages = this.conversation.guest.automated_messages || {}
      if (messages['24_h_payment_warn']) {
        return '3rd'
      }
      if (messages['6_h_payment_warn']) {
        return '2nd'
      }
      if (messages['3_h_payment_warn']) {
        return '1st'
      }
      return ''
    },
    preCheckInLink() {
      return `${this.portalDomain}/tourist/trips/${this.reservation.confirmation_code}`
    },
    hasRefunds() {
      return this.getReservationRefunds(this.reservation.payment_intents).length
    },
    paymentIntents() {
      return (this.reservation.payment_intents || []).filter(
        pi =>
          pi.metadata.type === 'accommodation' || pi.metadata.type === 'deposit'
      )
    },
    disputes() {
      return this.getReservationDisputes(this.reservation.payment_intents)
    },
    isAgentReserved() {
      return (
        this.reservation.agent_id &&
        this.reservation.status === 'agent-reserved'
      )
    },
    isAgent() {
      return (
        this.reservation.agent_id &&
        ['agent-reserved', 'agent-confirmed'].includes(this.reservation.status)
      )
    },
    pmsLink() {
      const integrationName = this.reservation.app_integration_name
      const reservationId = this.reservation.reservation_guesty_id

      switch (integrationName) {
        case 'hostaway':
          return `https://dashboard.hostaway.com/reservations/${reservationId}`
        default:
          return null
      }
    },
    checkInDate() {
      return this.parseDateTZ(
        this.reservation.check_in,
        'YYYY-MM-DD',
        this.reservation.listing.timezone
      )
    },
    checkOutDate() {
      return this.parseDateTZ(
        this.reservation.check_out,
        'YYYY-MM-DD',
        this.reservation.listing.timezone
      )
    },
  },
  methods: {
    ...mapMutations(['showModal']),
    sensitivityColor(sensitivity) {
      if (sensitivity === 'Low') {
        return 'success'
      }
      if (sensitivity === 'Med') {
        return 'notification'
      }
      if (sensitivity === 'High') {
        return 'warning'
      }
      return 'grey'
    },
    onReservationEdit() {
      if (this.$route.name === 'multi-calendar') {
        this.$store.commit('listingCalendar/calendarUpdate')
      }
    },
    showCatalogInfo() {
      this.showModal({
        name: 'CatalogItemInfoModal',
        props: {
          pickerMode: true,
        },
      })
    },
    showGrantItemButton(storeItem) {
      return (
        (this.isAdmin || this.isCommunicationManager) &&
        !['purchased', 'not_available'].includes(storeItem.status) &&
        this.reservation.status === 'confirmed' &&
        !storeItem.item.is_manual &&
        ['PoolHeater', 'EarlyCheckin', 'LateCheckout'].includes(
          storeItem.item.type
        )
      )
    },
    getRiskColor(risk) {
      if (risk < 0.2) {
        return 'green lighten-2'
      }
      if (risk < 0.5) {
        return 'yellow accent-4'
      }
      if (risk < 0.8) {
        return 'red lighten-2'
      }
      return 'red darken-4'
    },
    calcReservationPrice(res) {
      return res.price !== 0
        ? res.price
        : res.fare_accommodation +
            res.cleaning_fee +
            res.tourist_tax +
            res.sales_tax
    },
    reservationChanged(val) {
      const reservation = this.conversation.related_reservations.find(
        r => r.reservation_guesty_id === val
      )
      this.$store.commit('updateChatReservation', reservation)
    },
    textClass({ status, metadata, amount }) {
      if (
        ['succeeded', 'requires_capture', 'canceled'].includes(status) ||
        this.paidOnGuesty(amount / 100)
      ) {
        return 'green--text'
      }
      if (
        !this.reservation.should_pay_deposit &&
        metadata['type'] === 'deposit' &&
        (status === 'requires_source' || status === 'requires_payment_method')
      ) {
        return 'secondary--text'
      } else {
        return 'red--text'
      }
    },
    markAspaid(reservationID) {
      const userConfirm = confirm('Are you sure?')
      if (userConfirm) {
        this.$store.dispatch('markAsPaid', reservationID).then(({ data }) => {
          if (data && data.success) {
            this.conversationRefresh()
          }
        })
      }
    },
    paidOnGuesty(amount) {
      return this.reservation.payment_items.find(
        p => p.confirmation_code && p.amount === amount
      )
    },
    paymentStatus({ status, metadata, amount }) {
      if (metadata['type'] === 'deposit') {
        if (
          !this.reservation.should_pay_deposit &&
          (status === 'requires_source' || status === 'requires_payment_method')
        ) {
          return "Shouldn't be paid yet"
        }
        if (status === 'succeeded') {
          return 'Paid and captured'
        }
        if (status === 'canceled') {
          return 'Paid and expired'
        }
      }
      if (status === 'succeeded' || status === 'requires_capture') {
        return 'Paid'
      }

      if (this.paidOnGuesty(amount / 100)) {
        return 'Paid on PMS'
      }
      if (
        status === 'requires_source' ||
        status === 'requires_payment_method'
      ) {
        return 'not paid'
      }

      return status
    },
    issuesAreDone(issues) {
      let res = true
      issues.forEach(issue => {
        if (issue.status !== 'Done') {
          res = false
        }
      })
      return res
    },
    sendInfo() {
      this.$store.commit(
        'updatePushedMessage',
        this.reservation.generated_code_msg
      )
    },
    afterTaskCreate() {
      this.$store.dispatch('conversationUpdate', { id: this.conversation.id })
    },
    hourDesc(issue) {
      let hour = this.$moment.utc(issue.scheduled_at).hours()
      if (hour !== 0) {
        return this.parseDateJustTime(issue.scheduled_at)
      } else {
        return 'No hour scheduled'
      }
    },
    async conversationRefresh() {
      await this.$store.dispatch(
        'getChatConversation',
        this.conversation.guesty_id
      )
    },
    async approveRes() {
      this.approving = true
      await this.$store.dispatch('conversationUpdate', {
        id: this.conversation.id,
        reservation_id: this.reservation.id,
        approve_reservation: true,
      })
      this.approving = false
      if (this.$route.query.reservation) {
        this.$store.commit('listingCalendar/calendarUpdate')
      }
    },
    async declineRes() {
      this.declining = true
      await this.$store.dispatch('conversationUpdate', {
        id: this.conversation.id,
        reservation_id: this.reservation.id,
        decline_reservation: true,
      })
      this.declining = true
      if (this.$route.query.reservation) {
        this.$store.commit('listingCalendar/calendarUpdate')
      }
    },
    openInfo() {
      this.$store.commit('updateShowListingInfoPop', true)
    },
    markPreApproved() {
      this.$store.dispatch('preApproveReservation', this.reservation.id)
      this.reservation.pre_approved = true
    },
    sourceColor(channel) {
      if (channel === 'Airbnb') {
        return 'airbnb-color'
      } else if (channel === 'Booking.com') {
        return 'booking-color'
      } else if (channel === 'HomeAway') {
        return 'homeaway-color'
      } else if (channel === 'Designedvr.com') {
        return 'designedvr-color'
      } else if (channel === 'Website') {
        return 'designedvr-color'
      } else if (channel === 'Expedia') {
        return 'expedia-color'
      }
    },
    loadListing() {
      this.$store.dispatch('changeListing', {
        listingId: this.reservation.listing_id,
        updateLoading: false,
      })
    },
    async updateCheckInOutTimes({
      arrival,
      departure,
      luggage_drop,
      dvr_notes,
    }) {
      await this.$store.dispatch('reservation/alterReservation', {
        id: this.reservation.id,
        planned_arrival: arrival,
        planned_departure: departure,
        luggage_drop,
        dvr_notes,
      })
      await this.conversationRefresh()
    },
  },
  data() {
    return {
      dialog: false,
      declining: false,
      approving: false,
      selectedRes: null,
      STATUS_LABELS,
    }
  },
  components: {
    UserAvatar,
    ReservationEditModal,
    CheckInOutForm,
    StoreItemDialog,
    ConversationInfoTitle,
    ReservationCalendar,
    Personal,
    ListingsSearch,
    ReservationsSelect,
    ListingTaskModule,
    CopyClipboard,
    PreCheckInCard,
    ConversationInfoAgentConfirm,
  },
}
</script>
<style scoped>
.guest-label {
  top: -20px;
}
.rounded-img {
  border-radius: 5%;
}
.listing-risk {
  position: absolute;
  top: -20px;
  right: 0;
}
</style>
