/* Register component with one of 2 methods */
import '@babel/polyfill'
// or import all icons if you don't care about bundle size
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import Vuex from 'vuex'
import { sync } from 'vuex-router-sync'

import App from '@/App'
import { router } from '@/route'
import VueHotkey from 'v-hotkey'
import VueI18n from 'vue-i18n'
import { store } from '@/store'
import { dom } from '@fortawesome/fontawesome-svg-core'
import { Datetime } from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'
import VueClipboard from 'vue-clipboard2'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import icons from '@/assets/icons'
import theme from '@/assets/theme'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import VueQuillEditor from 'vue-quill-editor'
// import './registerServiceWorker'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import axios from 'axios'
import {
  DynamicScroller,
  DynamicScrollerItem,
  RecycleScroller,
} from 'vue-virtual-scroller'
import Sortable from 'sortablejs'
import Toaster from '@/utils/toaster'
import GlobalMixin from 'components/mixins/global-mixin'
import Vuelidate from 'vuelidate'
import Websocket from '@/utils/websocket'
import VueCarousel from 'vue-carousel'
import CountryFlag from 'vue-country-flag'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import '@seamapi/react/elements.js'

Vue.use(VueCarousel)
Vue.use(VueI18n)
Vue.use(Vuelidate)
Vue.use(VueTelInput)

Vue.use(VueQuillEditor)
Vue.use(VueHotkey)
Vue.use(PerfectScrollbar)
Vue.use(Datetime)
Vue.mixin(GlobalMixin)
dom.watch()
// if (
//   process.env.NODE_ENV === 'production' ||
//   process.env.NODE_ENV === 'staging'
// ) {
//   axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
// }
axios.defaults.withCredentials = true

Vue.use(VueClipboard)
Vue.component('RecycleScroller', RecycleScroller)
Vue.component('DynamicScroller', DynamicScroller)
Vue.component('DynamicScrollerItem', DynamicScrollerItem)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('Datetime', Datetime)
Vue.component('CountryFlag', CountryFlag)
// globally (in your main .js file)
Vue.use(Vuex)
Vue.use(require('vue-moment'))

sync(store, router)
const opts = {
  theme,
  icons,
}
Vue.use(Vuetify)
if (process.env.NODE_ENV != 'development') {
  // Vue.config.debug = false;
  // Vue.config.silent = true;
}

Vue.directive('sortableDataTable', {
  bind(el, binding, vnode) {
    const options = {
      animation: 150,
      onUpdate: function (event) {
        vnode.child.$emit('dragged', event)
      },
      disabled: binding.value === false,
    }
    Sortable.create(el.getElementsByTagName('tbody')[0], options)
  },
})

function loadLocaleMessages() {
  const locales = require.context(
    '../src/locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'en', // set locale
  messages: loadLocaleMessages(), // set locale messages
})
Websocket.initStore(store)
Toaster.initStore(store)

document.addEventListener('DOMContentLoaded', function () {
  new Vue({
    el: '#app',
    i18n,
    store,
    vuetify: new Vuetify(opts),
    components: {
      App,
    },
    router,
    created: function () {
      window.$store = this.$store
    },
    render: h => h(App),
  }).$mount()
})
