<template>
  <v-container>
    <seam-device-table
      v-if="publishableKey"
      :publishable-key="publishableKey"
      :device-ids="listingIotDevicesIds"
    ></seam-device-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: ['listing'],
  data() {
    return {
      dialog: false,
      headers: [
        { text: 'Image', value: 'image', sortable: false },
        { text: 'Device Name', value: 'display_name' },
        { text: 'Device Type', value: 'device_type' },
        { text: 'Device ID', value: 'device_id' },
        { text: 'Locked', value: 'properties.locked' },
        { text: 'Online', value: 'properties.online' },
        { text: 'Battery Level', value: 'properties.battery_level' },
        { text: 'Manufacturer', value: 'properties.manufacturer' },
        { text: 'Model', value: 'properties.model.display_name' },
      ],
    }
  },
  computed: {
    ...mapState('seamIot', [
      'publishableKey',
      'listingIotDevices',
      'publishableKey',
    ]),
    listingIotDevicesIds() {
      return this.listingIotDevices.map(device => device.device_id)
    },
  },
  methods: {
    ...mapActions('seamIot', ['getListingDevices', 'getPublishableKey']),
  },
  mounted() {
    this.getPublishableKey()
    this.getListingDevices({ listing_id: this.listing.id })
  },
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
