import axios from 'axios'
import moment from 'moment-timezone'
import {
  cloneDeep,
  find,
  isEmpty,
  isNumber,
  isString,
  remove,
  round,
  size,
  truncate,
} from 'lodash'
import { compose, filter, flatMap } from 'lodash/fp'
import {
  INTERNAL_RESERVATION_SOURCES,
  TASK_STATUSES,
  TASK_SUB_STATUSES,
} from '@/consts'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const monthsShortcuts = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export default {
  data() {
    return {
      incomeTypes: [
        'Booking/reservation income',
        'Cancellation Fee Revenue',
        'Claim profit',
        'Cleaning reimbursement',
        'Discount',
        'laundry machine',
        'Long term rental profit',
        'Other',
        'Owner deposit',
        'Parking deposit',
        'Resolution Payout Revenue',
        'Revenue transfer',
      ],
      compensationTags: [
        'AC issue',
        'Appliance Issue',
        'Cleanliness',
        'Electricity issue',
        'Internet Issue',
        'Hot water related issues',
        'Major multiple issues',
        'Noise Issue',
        'Privacy issues',
        'Furniture issue (broken chair, bed, table)',
        'Smell issue',
        'Gas leak',
        'Inaccurate listing',
        'Parking issue',
        'Lock/Keypad',
        'Pool heater',
        'Pest control',
        'Pool not clean',
        'Parking Pass',
        'Plumbing',
        'Toilet',
      ],
      reviewTags: [
        "CS - Refund wasn't sufficient",
        'CS - Check in process',
        'CS - Check-in Process: Wrong codes provided',
        'CS - Guest Experience issue',
        'CS - Guest misinformed',
        'CS - Long Response Time',
        'CS - Not Applicable Solution',
        'CS - Parking Pass',
        'CS - Parking issue',
        'CS - Pre-Heating Pool',
        'CS - Refund Process',
        'CS - Return Process',
        'Channel - Inaccurate listing',
        'Cleaning - Bed Linens',
        'Cleaning - Dirty items',
        'Cleaning - Missing Items',
        'Cleaning - Stained Furnitures',
        'Cleaning - Cleanliness',
        'Cleaning - Delayed Check-in',
        'Cleaning - Dirty Appliances',
        'Cleaning - Dirty Floors',
        'Cleaning - Dirty Towels',
        'Cleaning - Garbage Issue',
        'Cleaning - Item for replacement / Item issue',
        'Cleaning - Mold Issue',
        'Cleaning - Privacy Issue',
        'Cleaning - Smell issue',
        'Design - Furniture Feedback',
        'Design - Insufficient Lighting',
        'Design - Low Quality Kitchen Items',
        'Design - Low Quality Towels',
        'Design - Old Furniture',
        'General - ADR Price',
        'General - Hostile Neighbors',
        'General - Issues raised not relevant',
        'General - No Issues Mentioned',
        'IOT - IOT Devices',
        'None - False review',
        'PM - AC issue',
        'PM - Appliance Issue',
        'PM - Broken Shower',
        'PM - Bulb light issue',
        'PM - Cable/Wire related issues',
        'PM - Ceiling Damage',
        'PM - Check-in Process: Lock/Keypad',
        'PM - Damaged Item',
        'PM - Delayed Check-in',
        'PM - Door/ Window Issue',
        'PM - Electricity issue',
        'PM - Floor Needs fixing',
        'PM - Furniture issue (broken chair, bed, table)',
        'PM - Garbage Issue',
        'PM - Gas leak',
        'PM - Gas tanks empty',
        'PM - Health Hazard',
        'PM - Hot water related issues',
        'PM - Internet Issue',
        'PM - Landscaping',
        'PM - Landscaping',
        'PM - Lock/Keypad',
        'PM - Not enough Water Pressure',
        'PM - PM Bath/ Hot Tub/ jaccuzzi Issue',
        'PM - Pest control',
        'PM - Plumbing - Ceiling leak',
        'PM - Plumbing - Drainage Issue',
        'PM - Plumbing - Sink related issues',
        'PM - Plumbing shower leak',
        'PM - Plumbing',
        'PM - Pool Drain',
        'PM - Pool Fence/ Bar tool Issue',
        'PM - Pool Filter',
        'PM - Pool Floor',
        'PM - Pool heater',
        'PM - Pool not clean',
        'PM - Privacy Issue',
        'PM - Property maintenance issues',
        'PM - Router issue',
        'PM - Safety Hazard',
        'PM - Scheduled Services Canceled',
        'PM - Shattered Glass door/ window',
        'PM - Smoke detector Issue',
        'PM - Thermostat Issue',
        'PM - Toilet',
        'PM - Ventilation Issue',
        'PM - Water Damage',
        'Security - Camera Issues',
        'Security - Security/Rover Staff',
      ],
      budgetDivisions: [
        'Construction',
        'Design',
        'Finance',
        'Landscape',
        'Exterior',
        'Other',
      ],
      operationalDivisions: [
        'Maintenance',
        'Repairs',
        'Supplies',
        'Utilities',
        'Exceptionals',
        'Other',
      ],
      // zones: ['Global', 'Resorts', 'No Resorts'],
      dvrDivisions: [
        'Accounting',
        'Administration',
        'Channel Management',
        'Cleaning',
        'Construction',
        'Customer Support',
        'Customer Success',
        'Design',
        'Development',
        'Experience',
        'GO',
        'Marketing',
        'Maintenance',
        'Purchasing',
        'Sales',
        'Project Management',
        'Setup',
        'Realestate',
        'Warehouse',
      ],
      ltStatuses: Object.values(TASK_STATUSES),
      ltSubStatuses: Object.values(TASK_SUB_STATUSES),
      dvrDepartments: [
        'accounting',
        'administration',
        'communication',
        'design',
        'development',
        'property-management',
        'marketing',
        'setup',
        'storage',
        'realestate',
      ],
      myLoader: 0,
      personalTypes: [
        'accounting',
        'cleaning_supervisor',
        'customer_success',
        'designer',
        'office_manager',
        'landscape',
        'pool',
        'pest',
        'project_manager',
        'realator',
      ],
      companyTasksDepartments: [
        'Development',
        'Property management',
        'Communication',
        'Office management',
        'Accounting',
        'Warehouse',
        'Design',
        'Marketing',
      ],
      simpleListingTaskTypes: [
        'repair',
        'cleaning',
        'purchase-replacement',
        'finance',
      ],
      system_keys: [
        'cleaning_full',
        'cleaning_refresh',
        'cleaning_delivery',
        'cleaning_after_repair',
        'redo_missed_items',
        'extra_cleaning_purchase',
        'repair_plumbing',
        'repair_electricity',
        'repair_ac',
        'repair_general',
        'repair_appliances',
        'repair_roof_ceiling',
        'repair_gutters',
        'repair_paint',
        'repair_property_inspection',
        'repair_iot_devices',
        'repair_purchase_item',
        'repair_specialist_diagnostics',
        'maintenance_general',
        'maintenance_landscaping',
        'maintenance_pool',
        'maintenance_waste_management',
        'maintenance_field_activity',
        'maintenance_pest_control',
        'maintenance_design',
        'check_in_inspection',
        'pool_heater_check',
        'check_out_inspection',
        'quick_field_task',
        'guest_review',
        'guest_damages',
        'maintenance_item_purchase_request',
        'general_issue',
      ],
      defaultTemplate: {
        tasks: [],
        assigned_contractor_id: null,
        description: '',
        task_type: null,
        sub_topic: null,
        listing_task_catalog_item_id: null,
        listing_task_catalog_item_name: null,
        planned_duration: 1,
        priority: 'Med',
      },
      publicDivisions: [
        'accounting',
        'administration',
        'construction',
        'design',
        'guest experience',
        'projects',
        'property care',
      ],
      investorAbilities: ['investor_see_tasks', 'investor_operator'],
      abilities: [
        'submit_sc',
        'reviewer',
        'inventory',
        'ai-config',
        'disable-listing',
        'clocking-management',
        'update-listing-budget',
        'catalog-manager',
        'house-info-manager',
        'auto-rules-manager',
        'create-reservation',
        'view-shopping-list',
        'task-approval',
        'housekeeping-report',
        'pm-summary',
        'ge-summary',
        'channel-management',
        'accounting',
        'ach-transactions',
        'integrations-manager',
        'ach-transactions-actions',
        'bank-transactions-due-to-do',
        'accounting-dash',
        'bi-accounting',
        'bi-comm',
        'bi-expenses',
        'bi-daily-pulse',
        'bi-inventory',
        'bi-invest',
        'bi-store',
        'bi-pm',
        'bi-ai',
        'bi-revenue',
        'bi-reviews',
        'charge',
        'company-config',
        'company-manage',
        'create-store-purchase',
        'crm-management',
        'edit-bank-accounts',
        'edit-personal',
        'edit-staff',
        'estimates',
        'estimates-config',
        'generate-payments-nacha',
        'hide-expense',
        'invoice-update',
        'listings-owners-payments',
        'login_as_tour',
        'management-payments',
        'modify-bank-accounts',
        'pricing-manager',
        'projects',
        'real-estate-finance',
        'refund',
        'regions-management',
        'retire-listing',
        'salaries',
        'store-manager',
        'super-accountant',
        'super-pm',
        'super-storage',
        'taskim',
        'users-management',
        'view-logs',
      ],
      adminAbilities: [
        'accounting',
        'ach-transactions',
        'ach-transactions-actions',
        'ai-config',
        'integrations-manager',
        'bank-transactions-due-to-do',
        'accounting-dash',
        'bi-accounting',
        'bi-comm',
        'bi-expenses',
        'bi-inventory',
        'bi-invest',
        'bi-pm',
        'bi-revenue',
        'bi-reviews',
        'charge',
        'company-config',
        'company-manage',
        'create-store-purchase',
        'edit-bank-accounts',
        'edit-personal',
        'edit-staff',
        'estimates',
        'estimates-config',
        'generate-payments-nacha',
        'hide-expense',
        'invoice-update',
        'listings-owners-payments',
        'login_as_tour',
        'management-payments',
        'modify-bank-accounts',
        'pricing-manager',
        'projects',
        'real-estate-finance',
        'refund',
        'regions-management',
        'retire-listing',
        'salaries',
        'store-manager',
        'super-accountant',
        'super-pm',
        'super-storage',
        'taskim',
        'users-management',
        'view-logs',
      ],
      subRoles: [
        'manager',
        'field-manager',
        'handyman',
        'runner',
        'shift-manager',
        'agent',
        'backoffice',
      ],
    }
  },
  computed: {
    RTLmode() {
      return this.$i18n.locale === 'he'
    },
    isOwnerVacationDisabled() {
      return this.$store.state.app.configuration.custom[
        'owner_vacation_disable'
      ]
    },
    BoomUser() {
      if (this.$store.state.user) {
        return this.$store.state.user.email.includes('boomnow.com')
      }
      return null
    },
    divisions() {
      if (this.isDesignedVr) {
        return this.dvrDivisions
      }
      return this.publicDivisions
    },
    apiDomain() {
      return process.env.VUE_APP_API_ENDPOINT
    },
    defaultRouteByRole() {
      if (this.$store.state.user) {
        const role = this.$store.state.user.role
        if (['investor', 'admin'].includes(role)) {
          return '/dashboard/listings'
        } else if (
          [
            'cleaning-manager',
            'cleaning-supervisor',
            'property-manager',
            'coordinator',
            'field-pm',
          ].includes(role)
        ) {
          return '/dashboard/service-calls'
        } else if (
          [
            'communication-manager',
            'communication-agent',
            'sales-manager',
            'sales-agent',
          ].includes(role)
        ) {
          return '/dashboard/guest-experience/sales'
        } else if (['hotel-reception'].includes(role)) {
          return '/dashboard/multi-calendar'
        } else if (['tourist'].includes(role)) {
          return '/tourist'
        } else {
          return '/dashboard/service-calls'
        }
      } else {
        return '/dashboard/listings'
      }
    },
    editTemplateObj() {
      return this.$store.state.listingTask.currentEditTemplate
    },
    origin() {
      return location.origin
    },
    hasTeam() {
      const user = this.$store.state.user
      return user && user.team_id
    },
    currentRoute() {
      return this.$route.fullPath
    },
    listingManageContext() {
      return (
        this.$route.fullPath.includes('dashboard/edit') ||
        this.$route.fullPath.includes('dashboard/property')
      )
    },
    isReferrer: function () {
      return this.$store.getters.isReferrer
    },
    listingLoading() {
      return this.$store.state.listingLoading
    },
    isHotel() {
      return this.$store.state.currentListing.listing_type === 'hotel'
    },
    isUnit() {
      return this.$store.state.currentListing.listing_type === 'unit'
    },
    getMyUserId() {
      if (this.$store.state.user) {
        return this.$store.state.user.id
      }
    },
    loading() {
      return this.$store.state.loading
    },
  },
  methods: {
    isBoomRes(res) {
      return (
        !res.listing.app_integration_id ||
        res.listing.app_integration_name === 'boom'
      )
    },
    listingImgUploadValiation(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = e => {
          const img = new Image()
          img.onload = () => {
            resolve(img.width >= 1024 && img.height >= 768)
          }
          img.onerror = reject
          img.src = e.target.result
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    },
    isInHouseReservation(source) {
      return INTERNAL_RESERVATION_SOURCES.includes(source)
    },
    parseISODateWithOffset(date) {
      return new Date(
        date - new Date().getTimezoneOffset() * 60000
      ).toISOString()
    },
    getDaysInMonth(month, year) {
      const date = new Date(year, month, 1)
      let days = []
      while (date.getMonth() === month) {
        days.push(date.getDate())
        date.setDate(date.getDate() + 1)
      }
      return days
    },
    reactionEmoji(funLevel) {
      switch (funLevel) {
        case 1:
          return 'hate'
        case 2:
          return 'disappointed'
        case 3:
          return 'natural'
        case 4:
          return 'good'
        case 5:
          return 'excellent'
        default:
          return null
      }
    },
    showModule(moduleName) {
      if (!moduleName) {
        return true
      } else if (this.$store.state.app.configuration) {
        const modules = this.$store.state.app.configuration.approved_modules
        if (modules && modules.includes(moduleName)) {
          return true
        } else {
          return false
        }
      }
    },
    isOwnerDashboardSettingEnabled(settingKey) {
      return (
        this.$store.state.app.configuration.dashboard_settings[settingKey] ||
        false
      )
    },

    formatStatus(status) {
      if (
        status === 'requires_source' ||
        status === 'requires_payment_method'
      ) {
        return 'unpaid'
      }
      if (status === 'requires_capture') {
        return 'pending capture'
      }
      if (status === 'succeeded') {
        return 'paid'
      }
      return status
    },
    durationFormat(durationInSeconds = 0) {
      const duration = Number(durationInSeconds)
      const days = Math.floor((duration % 31536000) / 86400)
      const hours = Math.floor(((duration % 31536000) % 86400) / 3600)
      const minutes = Math.floor((((duration % 31536000) % 86400) % 3600) / 60)
      const seconds = (((duration % 31536000) % 86400) % 3600) % 60
      const str = ` ${days}d ${hours}h ${minutes}m ${seconds}s`
      return str.replace(/ 0\w/g, '').trim() || '0'
    },
    isActiveScope(scope) {
      const start = this.$moment.utc(scope.start_date, 'YYYY-MM-DD  HH:mm:ss')
      const end = this.$moment.utc(scope.end_date, 'YYYY-MM-DD  HH:mm:ss')
      const now = this.$moment.utc()
      return end.isAfter(now) && start.isBefore(now) && !scope.is_done
    },
    progressColor(val) {
      if (val <= 1) {
        return 'light-red'
      } else if (val <= 25) {
        return 'orange'
      } else if (val <= 50) {
        return 'yellow'
      } else if (val <= 75) {
        return 'green lighten-2'
      } else {
        return 'green'
      }
    },
    availColor(text) {
      if (text === 'Check-in and Checkout today') {
        return 'orange--text font-light'
      } else if (text === 'Check-in today') {
        return 'purple--text font-light'
      } else if (text === 'Checkout today') {
        return 'dark-yellow font-light'
      } else if (text === 'Free today') {
        return 'green--text font-light'
      }
    },
    getReservationRefunds: compose(
      flatMap('refunds.data'),
      flatMap('charges.data'),
      filter(p => p.metadata.type === 'accommodation')
    ),
    getReservationDisputes: flatMap('disputes'),
    eventDateColor(val, listingTask = null) {
      listingTask =
        listingTask || this.listingTask || this.listingTaskCreationInfo
      const parsedDate = this.$moment(val).utc().add(1, 'days')
      const timeNow = this.$moment().utc()

      const house_vacant_dates =
        listingTask.house_vacant_dates ||
        (listingTask.extra_data && listingTask.extra_data.house_vacant_dates) ||
        {}
      const house_check_in_dates =
        listingTask.house_check_in_dates ||
        (listingTask.extra_data &&
          listingTask.extra_data.house_check_in_dates) ||
        {}
      const house_check_out_dates =
        listingTask.house_check_out_dates ||
        (listingTask.extra_data &&
          listingTask.extra_data.house_check_out_dates) ||
        {}
      const future_blocks =
        listingTask.future_blocks ||
        (listingTask.extra_data && listingTask.extra_data.future_blocks) ||
        {}

      if (timeNow <= parsedDate && house_check_out_dates[val]) {
        return 'yellow lighten-1'
      } else if (timeNow <= parsedDate && house_check_in_dates[val]) {
        return 'purple lighten-1'
      } else if (timeNow <= parsedDate && future_blocks[val]) {
        return 'grey lighten-1'
      } else if (timeNow <= parsedDate && !house_vacant_dates[val]) {
        return 'green lighten-1'
      } else {
        return ''
      }
    },
    autoDownloadBlobData(data, fileName, blobOptions = {}) {
      const blob = new Blob([data], blobOptions)
      const fileURL = window.URL.createObjectURL(blob)
      this.autoDownloadFileUrl(fileURL, fileName)
    },
    autoDownloadLocalCsv(rows, fileName) {
      let csvString =
        'data:text/csv;charset=utf-8,' +
        rows.map(e => e.map(JSON.stringify).join(',')).join('\r\n')
      const encodedUri = encodeURI(csvString).replace(/#/g, '%23')
      this.autoDownloadFileUrl(encodedUri, fileName + '.csv')
    },
    autoDownloadFileUrl(fileURL, fileName) {
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', fileName)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    hasAbility(ability) {
      const user = this.$store.state.user
      if (isEmpty(ability)) {
        return true
      }
      if (user && user.abilities) {
        if (user.abilities.includes('super-admin')) {
          return true
        }
        if (Array.isArray(ability)) {
          return ability.some(ab => user.abilities.includes(ab))
        }
        return user.abilities.includes(ability)
      } else {
        return false
      }
    },
    isAllowed(roles) {
      return (
        !roles ||
        roles.some(
          r => this.$store.state.user && r === this.$store.state.user.role
        )
      )
    },
    isNotAllowed(roles) {
      return (
        !roles ||
        roles.every(
          r => this.$store.state.user && r !== this.$store.state.user.role
        )
      )
    },
    openChat(conversation) {
      this.$store.commit('updateChosenConversation', conversation)
    },
    subTopics(taskType) {
      if (this.$store.state.listingTaskSubTopics && taskType) {
        let res = this.$store.state.listingTaskSubTopics[taskType] || []
        if (res.length == 0) {
          res.push('other')
        }
        return res
      }
    },
    tasksCatagories(dep) {
      if (
        this.$route.fullPath.includes('tasks-list') ||
        this.$route.fullPath.includes('service-calls')
      ) {
        if (dep) {
          if (dep == 'Development') {
            return [
              'Feature request',
              'Bug report',
              'Finance',
              'Service Calls',
              'Property Management',
              'Pricing',
              'Communication',
              'Storage',
              'Investor Portal',
              'Design',
              'Tech debt',
              'Other',
            ]
          } else if (dep == 'Property management') {
            return ['Inspection', 'Reminder', 'General']
          } else if (dep == 'Marketing') {
            return [
              'Advertisement',
              'Media-creation',
              'Onsite',
              'Content/pr',
              'Research',
              'Other',
            ]
          } else if (dep == 'Communication') {
            return [
              'Payments issues',
              'Listing details change',
              'Marketing',
              'General',
              'Claim',
            ]
          } else if (dep == 'Office management') {
            return [
              'Inspection',
              'Licensing',
              'Utilities',
              'Payments',
              'General',
            ]
          } else if (dep == 'Accounting') {
            return ['General']
          } else if (dep == 'Design') {
            return ['Purchase', 'Walk through', 'General']
          } else if (dep == 'Warehouse') {
            return ['Consumables', 'Purchases', 'General']
          }
        } else {
          return []
        }
      } else {
        return [
          'Design',
          'Construction',
          'Marketing',
          'Setup',
          'Utilities and licence',
        ]
      }
    },
    listingTaskStatusColor(status) {
      if (status === TASK_STATUSES.NEW) {
        return 'secondary'
      }
      if (status === TASK_STATUSES.IN_PROGRESS) {
        return 'info'
      }
      if (status === TASK_STATUSES.REJECTED) {
        return 'error'
      }
      if (status === TASK_STATUSES.PENDING_APPROVAL) {
        return 'yellow'
      }
      if (status === TASK_STATUSES.INVESTIGATION) {
        return 'warning'
      }
      if (status === TASK_STATUSES.OWNER_APPROVAL) {
        return 'yellow'
      }
      if (status === TASK_STATUSES.DONE) {
        return 'success'
      }
      if (status === TASK_STATUSES.CANT_DO) {
        return 'deep-orange'
      }
      if (status === TASK_STATUSES.CLOSED) {
        return 'warning'
      }
      if (status === TASK_STATUSES.PAUSED) {
        return 'blue-grey'
      }
      if (status === TASK_STATUSES.IN_REVIEW) {
        return 'indigo'
      }
      return 'purple'
    },
    listingTaskStatusFormat(status) {
      if (status === 'Pending Approval') {
        return 'pending'
      } else if (status === 'In Progress') {
        return 'in-prog'
      }
      return status
    },
    resStatusColor(status) {
      if (status == 'confirmed') {
        return 'greenish white--text'
      } else if (status == 'reserved') {
        return 'yellowish purple-icon'
      } else if (status == 'canceled') {
        return 'red white--text'
      } else if (status == 'inquiry') {
        return 'white--text grey'
      } else if (status == 'awaiting_payment') {
        return 'yellow grey--text'
      } else {
        return ''
      }
    },
    cssVar(variables) {
      return getComputedStyle(document.body).getPropertyValue(variables)
    },
    createCompsText(optionalListings, channel) {
      let msg = ''
      channel.charAt(0).toUpperCase()
      if (channel) {
        optionalListings.forEach(function (listing) {
          if (listing.links[channel]) {
            msg =
              msg +
              `${listing.links[channel]} - ${listing.title} / bedrooms: ${listing.beds} baths: ${listing.baths}\n`
          }
        })
        return msg
      }
    },
    sourceColor(channel) {
      if (channel == 'Airbnb') {
        return 'airbnb-color'
      } else if (channel == 'Booking.com') {
        return 'booking-color'
      } else if (channel == 'HomeAway') {
        return 'homeaway-color'
      } else if (channel == 'Designedvr.com') {
        return 'designedvr-color'
      } else if (channel == 'Website') {
        return 'designedvr-color'
      } else if (channel == 'Expedia') {
        return 'expedia-color'
      }
    },
    storageRequestStatusColor(status) {
      const color = { bg: '', text: '' }
      switch (status) {
        case 'New':
          color.bg = 'green lighten-5'
          color.text = 'success--text'
          break
        case 'In Progress':
          color.bg = 'orange lighten-5'
          color.text = 'orange--text'
          break
        case 'Done':
          color.bg = 'info lighten-5'
          color.text = 'info--text'
      }
      return color
    },
    randomId() {
      return Math.floor(Math.random() * 100000) + 1
    },
    transformHourToString(hourHash) {
      if (hourHash['hh'] && hourHash['mm'] && hourHash['a']) {
        return hourHash['hh'] + ':' + hourHash['mm'] + ' ' + hourHash['a']
      }
    },
    onCopy: function (e) {
      $(e.trigger).addClass('cyan-icon')
    },
    addToLoadList(addon) {
      this.myLoader += addon
    },

    subTopicsSelect(categories) {
      let res = []
      if (categories.includes('pool')) {
        res = res.concat([
          'pool - regular',
          'pool - extra cleaning',
          'pool - heater',
          'pool - other',
        ])
      }
      if (categories.includes('cleaning')) {
        res = res.concat([
          'cleaning - refresh',
          'cleaning - after fix',
          'cleaning - after setup',
          'cleaning - AC Filter Change',
          'cleaning - other',
        ])
      }
      if (categories.includes('landscape')) {
        res = res.concat(['landscape - regular', 'landscape - other'])
      }
      if (categories.includes('pest-control')) {
        res = res.concat([
          'pest-control - regular',
          'pest-control - additional',
        ])
      }
      if (categories.includes('repair')) {
        res = res.concat([
          'repair - a/c',
          'repair - electricity',
          'repair - appliances',
          'repair - setup',
          'repair - plumbing',
          'repair - general-repair',
          'repair - runner',
          'repair - property-inspection',
        ])
      }
      res = res.concat(['other - other'])
      return res.flat()
    },
    isPdf(src) {
      if (src && src.indexOf('.pdf') != -1) {
        return true
      } else {
        return false
      }
    },
    isImage(src) {
      if (
        src &&
        (src.indexOf('.jpg') != -1 ||
          src.indexOf('.gif') != -1 ||
          src.indexOf('.bmp') != -1 ||
          src.indexOf('.png') != -1)
      ) {
        return true
      } else {
        return false
      }
    },
    isVideo(src) {
      return (
        src &&
        (src.indexOf('.m4v') != -1 ||
          src.indexOf('.avi') != -1 ||
          src.indexOf('.mpg') != -1 ||
          src.indexOf('.mp4') != -1 ||
          src.indexOf('.mkv') != -1 ||
          src.indexOf('.mov') != -1)
      )
    },
    fExtention(src) {
      src = src.split('.')
      return src[src.length - 1]
    },
    downloadItem(url, filename = 'download') {
      let fileType = null
      if (this.isVideo(url)) {
        fileType = { type: 'Content-type: application/octet-stream' }
      } else if (this.isImage(url)) {
        fileType = { type: 'image/png' }
      } else if (this.isPdf(url)) {
        fileType = { type: 'application/pdf' }
      }
      axios
        .get(url, { responseType: 'blob', withCredentials: false })
        .then(response => {
          const blob = new Blob(
            [response.data],
            fileType || { type: 'image/png' }
          )
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)

          const isIOS =
            /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

          if (isIOS) {
            window.open(link.href, '_blank')
          } else {
            link.download = `${filename}.${
              fileType ? this.fExtention(url) : 'jpg'
            }`
            link.click()
            URL.revokeObjectURL(link.href)
          }
        })
        .catch(console.error)
    },
    getFilterLessCurrentCompanyTasks() {
      let startDate = this.dateInSafari(
        window.$store.state.company_tasks_calendar.view.title
      )
      let userId = this.$store.state.user ? this.$store.state.user.id : null
      let date = this.$moment.utc(startDate)
      this.$store.dispatch('getCompanyTasks', {
        from: date.toISOString().substr(0, 10),
        to: this.getEndDate(
          date,
          window.$store.state.company_tasks_calendar.view.type
        ),
        assigned_user_id: userId,
        bg: true,
      })
    },
    getTaskCalMonth(listingId) {
      const today = this.$moment().utc()
      const from_date = today.startOf('month')
      this.$store.dispatch('getListingTasks', {
        filters: {
          from: from_date.toISOString().substr(0, 10),
          to: from_date.add(40, 'd').toISOString().substr(0, 10),
          status: 'All',
          type: 'All',
          listing_id: listingId,
          saveToCal: true,
          getPropertyTasks: true,
        },
      })
    },
    howFarBack(scheduled_at) {
      return this.$moment().utc().diff(this.$moment.utc(scheduled_at), 'days')
    },
    daysAhead(scheduled_at) {
      return this.$moment.utc(scheduled_at).utc().diff(this.$moment(), 'days')
    },
    isSC(item) {
      return !item.department
    },
    itemPic(item) {
      if (!item.department) {
        if (item.assigned_contractor) {
          return item.assigned_contractor.picture
        }
      } else {
        if (item.assigned_user) {
          return item.assigned_user.picture
        }
      }
    },
    itemUser(item) {
      if (!item.department) {
        return item.assigned_contractor
      } else {
        return item.assigned_user
      }
    },

    itemAssignName(item) {
      if (!item.department && item.extra_data) {
        return item.extra_data.assigned_to_name
      } else {
        return item.assigned_to_name
      }
    },
    itemListingNickname(item) {
      if (!item.department && item.extra_data) {
        return item.extra_data.listing_name || ''
      } else {
        return item.listing_nickname || ''
      }
    },
    ltdContains(item) {
      return this.$store.state.ltdSelectedIDS.has(item.id)
    },
    addAutoRuleSelected(item) {
      let id = item.id
      let selected = cloneDeep(this.$store.state.autoRulesSelectedIDS)
      if (selected.includes(id)) {
        remove(selected, n => n == id)
        item.selected = false
      } else {
        selected.push(id)
        item.selected = true
      }
      this.$store.commit('updateAutoRulesSelectedIDS', selected)
    },
    multiAddToAutoRuleSelected(items) {
      items.forEach(item => {
        this.addAutoRuleSelected(item)
      })
    },
    addToLtdSelected(item) {
      let id = item.id
      let selected = new Set(this.$store.state.ltdSelectedIDS)
      if (selected.has(id)) {
        selected.delete(id)
        item.selected = false
      } else {
        selected.add(id)
        item.selected = true
      }
      this.$store.commit('updateLtdSelectedIDS', selected)
    },
    multiAddToLtdSelected(items) {
      items.forEach(item => {
        this.addToLtdSelected(item)
      })
    },
    updateChosenListingSetupTask(task) {
      this.$store.commit('updateChosenListingSetupTask', task)
    },
    initials(user) {
      if (user) {
        let first = user.first_name
          ? user.first_name.charAt(0).toUpperCase()
          : ''
        let last = user.last_name ? user.last_name.charAt(0).toUpperCase() : ''
        return first + last
      }
    },
    initialsFromFullName(name) {
      if (name) {
        let first = name.split(' ')[0]
          ? name.split(' ')[0].charAt(0).toUpperCase()
          : ''
        let last = name.split(' ')[1]
          ? name.split(' ')[1].charAt(0).toUpperCase()
          : ''
        return first + last
      } else {
        return ''
      }
    },
    normelizeStatus(status) {
      if (
        status == 'Initial Inspection' ||
        status == 'Cleaning' ||
        status == 'Final Inspection'
      ) {
        return 'In Progress'
      } else {
        return status
      }
    },
    showVideo(videoSrc) {
      this.$store.commit('updateChosenVideo', videoSrc)
    },
    statusToNum(status) {
      if (status == 'Cant') {
        return 0
      } else if (status == 'New') {
        return 1
      } else if (status == 'Pending Approval') {
        return 3
      } else if (status == 'In Progress') {
        return 2
      } else if (status == 'Done') {
        return 4
      } else if (status == 'Owner Approval') {
        return 5
      } else {
        return 0
      }
    },
    monthNumToName(monthnum) {
      return months[monthnum - 1] || ''
    },
    monthNameToNum(monthname) {
      var month = months.indexOf(monthname)
      if (month == -1) {
        month = monthsShortcuts.indexOf(monthname)
      }
      return month !== undefined ? month + 1 : 0
    },
    dateInSafari(date) {
      let view = 'month'
      let day = '01'
      let monthName = ''
      let year = new Date().getFullYear()
      if (date.includes(' – ')) {
        view = 'week'
      } else if (date.includes(',')) {
        view = 'day'
      }
      if (view == 'month') {
        monthName = date.split(' ')[0]
        year = date.split(' ')[1]
      } else if (view == 'week') {
        monthName = date.split(' ')[0]
        day = date.split(' ')[1]
        year = date.split(' ')[date.split(' ').length - 1]
      } else if (view == 'day') {
        monthName = date.split(' ')[0]
        day = date.split(' ')[1].split(',')[0]
        year = date.split(' ')[2]
      }
      let monthIndex = this.monthNameToNum(monthName)
      return this.$moment.utc(
        new Date(this.$moment.utc(monthIndex + '/' + day + '/' + year))
      )
    },
    getEndDate(startDate, view) {
      if (view == 'dayGridMonth' || view == 'month') {
        return this.$moment(
          new Date(startDate._d.setMonth(new Date(startDate._d).getMonth() + 1))
        )
          .utc()
          .toISOString()
          .substr(0, 10)
      } else if (view.includes('Week')) {
        return this.$moment(new Date(this.$moment.utc(startDate)))
          .add(6, 'd')
          .utc()
          .toISOString()
          .substr(0, 10)
      } else {
        return this.$moment
          .utc(new Date(this.$moment.utc(startDate)))
          .toISOString()
          .substr(0, 10)
      }
    },
    priorityOrder(priority) {
      priority = priority && priority.toLowerCase()
      if (priority === 'critical') return 1
      if (priority === 'high') return 2
      if (priority === 'med') return 3
      if (priority === 'low') return 4
      return 5
    },
    priorityClass(priority) {
      if (priority === 'High') {
        return 'pri-high'
      } else if (priority === 'Critical') {
        return 'pri-critical'
      } else if (priority === 'Med') {
        return 'pri-med'
      } else {
        return 'pri-low'
      }
    },
    changeToHttps(imageSrc) {
      if (!imageSrc.includes('https')) {
        return imageSrc.replace('http', 'https')
      } else {
        return imageSrc
      }
    },
    rotateImg(imageSrc) {
      let newLink = null
      if (imageSrc.includes('a_360')) {
        newLink = imageSrc.replace('a_360', 'a_90')
      } else if (imageSrc.includes('a_90')) {
        newLink = imageSrc.replace('a_90', 'a_180')
      } else if (imageSrc.includes('a_180')) {
        newLink = imageSrc.replace('a_180', 'a_270')
      } else if (imageSrc.includes('a_270')) {
        newLink = imageSrc.replace('a_270', 'a_360')
      } else {
        let index = imageSrc.indexOf('upload/') + 7
        newLink =
          imageSrc.slice(0, index) +
          'a_360/' +
          imageSrc.slice(index, imageSrc.length)
      }
      return newLink
    },
    roleColor(role) {
      if (role === 'property-manager') {
        return 'green'
      } else if (role === 'communication-manager' || role === 'sales-manager') {
        return 'red'
      } else if (role === 'communication-agent' || role === 'sales-agent') {
        return 'yellow'
      } else if (role === 'admin') {
        return 'blue'
      } else {
        return 'cyan'
      }
    },
    storageBy(key, value) {
      const { storages } = this.$store.state.storages
      return find(storages, [key, value])
    },
    storageIdByNickname(nickname) {
      const storage = this.storageBy('nickname', nickname)
      return storage && storage.id
    },
    storageNicknameById(id) {
      const storage = this.storageBy('id', id)
      return storage && storage.nickname
    },
    getListingById(id) {
      return find(this.$store.getters.listingsPicker, ['id', id]) || {}
    },
    listingNicknameById(id) {
      return (find(this.$store.state.listingsIndex, ['id', id]) || {}).nickname
    },
    nameById(id) {
      const usersMap = this.$store.getters['users/usersMap']
      return (usersMap[id] || {}).name
    },
    userById(id) {
      const usersMap = this.$store.getters['users/usersMap']
      return usersMap[id] || {}
    },
    attachementCreatedBy(attachement) {
      let type = this.attachementType(attachement)
      if (type == 'Reservation') {
        return 'System'
      } else if (type == 'Expense') {
        return attachement.created_by_name
      } else {
        return attachement.created_by_name
      }
    },
    attachementPrice(attachement) {
      if (attachement) {
        let type = this.attachementType(attachement)
        if (type == 'Reservation') {
          return attachement.reported_price
        } else if (type == 'Expense') {
          return attachement.amount
        } else {
          return attachement.amount
        }
      }
    },
    listingName(attachement) {
      return attachement.listing_name || 'Company expense'
    },
    monthName(index) {
      var months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      return months[index - 1]
    },
    attachementType(attachement) {
      if (attachement) {
        if (attachement.check_in) {
          return 'Reservation'
        } else if (attachement.short_reason) {
          return 'Expense'
        } else if (attachement.category) {
          return 'Company Income: ' + attachement.category
        } else if (attachement.title) {
          return 'Payment'
        } else if (attachement.income_type) {
          return 'Income'
        }
      }
    },
    attachementDate(attachement) {
      if (attachement) {
        let type = this.attachementType(attachement)
        if (type == 'Reservation') {
          if (
            attachement.source == 'Airbnb' ||
            attachement.source == 'Booking.com'
          ) {
            return attachement.check_in
          } else {
            return attachement.confirmed_at
          }
        } else if (type == 'Income') {
          return attachement.transferred_at
        } else {
          return attachement.paid_at
        }
      }
    },
    hashSize(hash) {
      return size(hash)
    },
    attachementDesc(attachement) {
      let type = this.attachementType(attachement)
      if (type == 'Reservation') {
        if (attachement.source == 'Airbnb') {
          return `https://airbnb.com/hosting/reservations/details/${attachement.confirmation_code}`
        }
        return attachement.confirmation_code
      } else if (type == 'Expense') {
        return attachement.short_reason + ': ' + attachement.description
      } else if (type == 'Payment') {
        return 'To' + attachement.recipient_name
      } else if (type == 'Income') {
        return attachement.comments
      } else {
        return attachement.description
      }
    },
    round(val, decimals = 0) {
      return round(val, decimals)
    },
    roundWith(num, accuracy) {
      return round(num, accuracy)
    },
    yearNow() {
      return this.$moment().year()
    },
    monthNow() {
      return this.$moment().month()
    },
    isNumber,
    isString,
    isEmpty(obj) {
      return isEmpty(obj)
    },
    truncateAddress(listing) {
      return truncate(listing.address, {
        length: 30,
        omission: '...',
      })
    },
    truncateBy(text, length = 30) {
      return truncate(text, {
        length: length,
        omission: '...',
      })
    },
    doNothing: function () {},
    parseDate: function (date, format = 'YYYY-MM-DD', config = {}) {
      if (date) {
        const parsedDate = config.local
          ? this.$moment(date)
          : this.$moment.utc(date)
        if (parsedDate) {
          return parsedDate.format(format)
        }
      } else {
        return ''
      }
    },
    parseDateTZ: function (date, format = 'YYYY-MM-DD', timezone = 'UTC') {
      return moment(date).tz(timezone).format(format)
    },
    parseDateWithTime: function (date) {
      if (date) {
        const parsedDate = this.$moment.utc(
          this.$moment(date, 'YYYY-MM-DD HH:mm')
        )
        if (parsedDate) {
          return parsedDate.format('YYYY-MM-DD HH:mm:ss')
        }
      } else {
        return ''
      }
    },
    parseDateWithTimeIfPresent: function (date) {
      if (date) {
        const parsedDate = this.$moment.utc(date, 'YYYY-MM-DD HH:mm')
        if (parsedDate && parsedDate.hours() != 0) {
          return parsedDate.format('YYYY-MM-DD HH:mm')
        } else {
          return parsedDate.format('YYYY-MM-DD')
        }
      } else {
        return ''
      }
    },
    timeNotSet: function (date) {
      if (date) {
        const parsedDate = this.$moment.utc(date, 'YY-MM-DD HH:mm')
        return parsedDate && parsedDate.hours() == 0
      } else {
        return true
      }
    },
    parseDateLocalTime: function (date) {
      if (date) {
        const parsedDate = this.$moment(new Date(date)).format(
          'YYYY-MM-DD HH:mm'
        )
        if (parsedDate) {
          return parsedDate
        }
      } else {
        return ''
      }
    },
    parseDateJustTime: function (date) {
      if (date) {
        const parsedDate = this.$moment.utc(
          this.$moment(date, 'YYYY-MM-DD HH:mm')
        )
        if (parsedDate) {
          return parsedDate.format('HH:mm:ss')
        }
      } else {
        return ''
      }
    },
    parseDateUSA: function (date) {
      if (date) {
        const parsedDate = this.$moment(date).utc()
        if (parsedDate) {
          return parsedDate.format('YYYY-MM-DD')
        }
      } else {
        return ''
      }
    },
    isAssingedToTask: function (task) {
      if (this.$store.state.user) {
        return task.assigned_contractor_id == this.$store.state.user.id
      } else {
        return false
      }
    },
    isToday: function (date) {
      if (date) {
        const parsedDate = new Date(date).toISOString().substr(0, 10)
        const timeNow = new Date().toISOString().substr(0, 10)
        return timeNow == parsedDate
      }
    },
    isSameDay(day1, day2) {
      if (day1 && day2) {
        const one = new Date(day1).toISOString().substr(0, 10)
        const two = new Date(day2).toISOString().substr(0, 10)
        return one === two
      }
      return false
    },
    pastDate: function (date) {
      const parsedDate = this.$moment(date).utc()
      const timeNow = this.$moment().utc()
      return timeNow > parsedDate
    },
    parseDateAndHourInEst: function (date) {
      if (date) {
        const parsedDate = this.$moment(date).utc()
        let d = new Date(date)
        if (parsedDate) {
          return (
            parsedDate.format('YYYY-MM-DD') + ` ${d.getUTCHours() - 4}` + ':00'
          )
        }
      } else {
        return ''
      }
    },
    generatePassword() {
      const length = 8,
        charset =
          'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      let retVal = ''
      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n))
      }
      return retVal
    },
    timeFromNow(time) {
      return this.$moment(time).fromNow()
    },
    timeAgo(time) {
      return this.$moment().diff(this.$moment(time), 'hours') < 24
        ? this.$moment(time).fromNow()
        : this.$moment(time).format('MM-DD-YYYY HH:mm')
    },
    addMonth(date) {
      return this.$moment(date).add(1, 'month')
    },
    subtractMonth(date) {
      return this.$moment(date).subtract(1, 'month')
    },
    generateDateRangeArray(from, to) {
      const dates = []
      const fromDate = moment(from)
      const toDate = moment(to)
      for (let m = fromDate; m.isBefore(toDate); m.add(1, 'days')) {
        dates.push(m.format('YYYY-MM-DD'))
      }
      return dates
    },
  },
}
