const menuItems = [
  {
    text: 'Listings',
    value: '/dashboard/listings',
    allowed_roles: [
      'admin',
      'investor',
      'property-manager',
      'coordinator',
      'accountant',
      'field-pm',
    ],
    icon: '$home',
    demo_only: true,
  },
  {
    text: 'Portfolio',
    icon: '$portfolio',
    value: '/dashboard/portfolio',
    module: 'ops',
    allowed_roles: ['admin', 'investor', 'property-manager', 'field-pm'],
  },
  {
    text: 'Operations',
    allowed_roles: [
      'admin',
      'communication-manager',
      'communication-agent',
      'sales-manager',
      'sales-agent',
      'cleaning-supervisor',
      'cleaning-manager',
      'property-manager',
      'coordinator',
      'hotel-reception',
      'accountant',
      'contractor',
      'cleaner',
      'field-pm',
      'pools-manager',
      'repairs-manager',
      'landscaping-manager',
    ],
    module: 'ops',
    icon: '$maintenance',
    value: '/dashboard/service-calls',
    demo_only: true,
  },
  {
    text: 'Tasks',
    icon: '$office_ops',
    value: '/dashboard/taskim',
    allowed_roles: [
      'admin',
      'communication-manager',
      'communication-agent',
      'sales-agent',
      'sales-manager',
      'property-manager',
      'coordinator',
      'accountant',
    ],
    module: 'ops',
  },
  {
    text: 'Feeds',
    icon: 'mdi-pound',
    value: '/dashboard/feeds',
    allowed_roles: ['admin'],
    module: 'ops',
  },
  {
    text: 'Accounting',
    allowed_roles: ['admin', 'accountant', 'property-manager'],
    icon: '$calculator',
    module: 'accounting',
    value: '/dashboard/my-finance',
  },
  {
    text: 'Channels',
    allowed_roles: ['admin'],
    icon: 'mdi-home-assistant',
    value: '/dashboard/cm',
    module: 'channel-manager',
  },
  {
    text: 'CRM',
    allowed_roles: [
      'admin',
      'communication-manager',
      'communication-agent',
      'sales-manager',
      'sales-agent',
      'property-manager',
      'coordinator',
    ],
    module: 'comm',
    abilities: ['crm-management'],
    abilityOrRole: true,
    icon: '$guest_crm',
    value: '/dashboard/guest-experience',
  },
  {
    text: 'Experience',
    allowed_roles: [
      'admin',
      'communication-manager',
      'communication-agent',
      'sales-manager',
      'sales-agent',
      'property-manager',
      'coordinator',
      'field-pm',
    ],
    module: 'guest-exp',
    icon: '$guest_experience',
    value: '/dashboard/guest-mgmt',
  },
  {
    text: 'Reviews',
    allowed_roles: [
      'admin',
      'communication-manager',
      'communication-agent',
      'sales-manager',
      'sales-agent',
      'property-manager',
      'coordinator',
    ],
    module: 'comm',
    icon: '$promotion',
    value: '/dashboard/reviews-dashboard',
  },
  {
    text: 'Pricing',
    icon: '$accounting',
    value: '/dashboard/pricing-panel',
    allowed_roles: ['admin'],
    abilities: ['pricing-manager'],

    module: 'pricing',
  },
  {
    text: 'Multi calendar',
    allowed_roles: [
      'admin',
      'communication-manager',
      'communication-agent',
      'sales-manager',
      'sales-agent',
      'cleaning-supervisor',
      'cleaning-manager',
      'cleaner',
      'property-manager',
      'coordinator',
      'field-pm',
      'investor',
    ],
    icon: '$calendar',
    value: '/dashboard/multi-calendar',
  },
  {
    text: 'Storage',
    allowed_roles: [
      'admin',
      'cleaning-manager',
      'cleaning-supervisor',
      'property-manager',
      'coordinator',
      'accountant',
      'field-pm',
    ],
    isPrivate: true,
    icon: '$inventory',
    module: 'projects',
    value: '/dashboard/inventory',
  },
  {
    text: 'Projects',
    allowed_roles: ['admin', 'property-manager', 'coordinator', 'field-pm'],
    isPrivate: true,
    icon: '$setups',
    abilities: ['projects'],
    module: 'projects',
    value: '/dashboard/listings-setup',
  },
  {
    text: 'Hotel Reception',
    allowed_roles: ['hotel-reception', 'admin'],
    abilities: ['housekeeping-report'],
    icon: '$hotel_reception',
    module: 'hotels',
    value: '/dashboard/hotel-dashboard',
  },
  {
    text: 'Analytics',
    isPrivate: true,
    allowed_roles: [
      'admin',
      'field-pm',
      'property-manager',
      'coordinator',
      'hotel-reception',
      'communication-manager',
      'sales-manager',
    ],
    demo_only: true,
    icon: '$analytics',
    abilities: [
      'bi-reviews',
      'bi-cleaning',
      'bi-revenue',
      'bi-accounting',
      'bi-comm',
      'bi-pm',
      'bi-invest',
      'bi-inventory',
    ],
    module: 'bi',
    value: '/dashboard/performance',
  },
  {
    text: 'IoT',
    allowed_roles: ['admin'],
    module: 'iot',
    icon: '$iot',
    value: '/dashboard/iot',
  },
  {
    text: 'AI',
    allowed_roles: ['admin'],
    abilities: ['ai-config'],
    module: 'ai',
    icon: '$guest_crm',
    value: '/dashboard/ai-config',
  },
  {
    text: 'Settings',
    allowed_roles: [
      'admin',
      'property-manager',
      'coordinator',
      'communication-manager',
      'communication-agent',
      'sales-agent',
      'sales-manager',
    ],
    demo_only: true,
    abilities: [
      'estimates-config',
      'users-management',
      'real-estate-finance',
      'pricing-manager',
      'channel-management',
    ],
    icon: '$settings',
    value: '/dashboard/management',
  },
  {
    text: 'Dev Tools',
    allowed_roles: ['admin'],
    abilities: ['super-admin', 'view-logs'],
    icon: '$dev_tools',
    value: '/dashboard/dev-tools',
  },
]

export default menuItems
