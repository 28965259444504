var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',[_c('div',{staticClass:"d-flex justify-space-between align-center my-3 px-3"},[_c('div',{staticClass:"text-h6"},[_vm._v("Calculation Formulas")]),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"small":"","depressed":"","color":"success","disabled":_vm.disabledSave}},on),[_vm._v("Save")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-8"},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"font-weight-medium"},[_vm._v(" This will affect all reservations starting from ")]),_c('div',{staticClass:"d-inline-flex ma-6"},[_c('v-text-field',{attrs:{"max":_vm.$moment().utc().format('YYYY-MM-DD'),"outlined":"","dense":"","hide-details":"","type":"date"},on:{"focus":e => e.target.showPicker()},model:{value:(_vm.commissionRecalcFrom),callback:function ($$v) {_vm.commissionRecalcFrom=$$v},expression:"commissionRecalcFrom"}})],1)]),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"success","text":""},on:{"click":_vm.updateListing}},[_vm._v("Save")])],1)])],1)],1),_c('v-sheet',{staticClass:"overflow-y-auto px-3 pb-3",attrs:{"max-height":"700"}},[(_vm.settings)?_c('v-row',{attrs:{"no-gutters":""}},[(_vm.isAdmin)?_c('v-col',{staticClass:"pa-2 mb-8",attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-4"},[_vm._v("Commission")]),_c('v-text-field',{attrs:{"label":_vm.$t('Management Commission'),"outlined":"","type":"number","dense":"","clearable":_vm.isHotel && !_vm.isDisabledManagementCommissionAbove,"hint":_vm.$t(_vm.managementCommissionAboveHint),"persistent-hint":"","min":0,"max":1,"step":"0.01","rules":[
            v => (v >= 0 && v <= 1) || _vm.$t('Value must be between 0 and 1'),
          ],"disabled":_vm.isDisabledManagementCommissionAbove},on:{"input":function($event){_vm.disabledSave = false}},model:{value:(_vm.managementCommissionAbove),callback:function ($$v) {_vm.managementCommissionAbove=_vm._n($$v)},expression:"managementCommissionAbove"}}),(_vm.isOptima)?_c('v-text-field',{staticClass:"mt-3",attrs:{"label":_vm.$t('Cleaning Fee'),"dense":"","outlined":"","hide-details":""},on:{"input":function($event){_vm.disabledSave = false}},model:{value:(_vm.cleaningFee),callback:function ($$v) {_vm.cleaningFee=$$v},expression:"cleaningFee"}}):_vm._e(),_c('v-switch',{attrs:{"label":_vm.$t('Owner cleaning'),"hide-details":""},on:{"change":function($event){_vm.disabledSave = false}},model:{value:(_vm.ownerCleaning),callback:function ($$v) {_vm.ownerCleaning=$$v},expression:"ownerCleaning"}})],1):_vm._e(),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","prepend-inner-icon":"mdi-calculator","label":"Net Income Formula","dense":"","clearable":"","hint":_vm.zone.net_income_formula || _vm.settings.net_income_formula
              ? `Using system default (${
                  _vm.zone.net_income_formula || _vm.settings.net_income_formula
                })`
              : null,"persistent-hint":!_vm.netIncomeFormula,"items":_vm.NET_INCOME_FORMULAS},on:{"change":function($event){_vm.disabledSave = false}},model:{value:(_vm.netIncomeFormula),callback:function ($$v) {_vm.netIncomeFormula=$$v},expression:"netIncomeFormula"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","prepend-inner-icon":"mdi-calculator","label":"Management Commission Formula","dense":"","clearable":"","hint":_vm.zone.management_commission_formula ||
            _vm.settings.management_commission_formula
              ? `Using system default (${
                  _vm.zone.management_commission_formula ||
                  _vm.settings.management_commission_formula
                })`
              : null,"persistent-hint":!_vm.managementCommissionFormula,"items":_vm.MANAGEMENT_COMMISSION_FORMULAS},on:{"change":function($event){_vm.disabledSave = false}},model:{value:(_vm.managementCommissionFormula),callback:function ($$v) {_vm.managementCommissionFormula=$$v},expression:"managementCommissionFormula"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","label":"Owner Revenue Formula","dense":"","prepend-inner-icon":"mdi-calculator","clearable":"","hint":_vm.zone.owner_revenue_formula || _vm.settings.owner_revenue_formula
              ? `Using system default (${
                  _vm.zone.owner_revenue_formula || _vm.settings.owner_revenue_formula
                })`
              : null,"persistent-hint":!_vm.ownerRevenueFormula,"items":_vm.OWNER_REVENUE_FORMULAS},on:{"change":function($event){_vm.disabledSave = false}},model:{value:(_vm.ownerRevenueFormula),callback:function ($$v) {_vm.ownerRevenueFormula=$$v},expression:"ownerRevenueFormula"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","label":"Owner Income Formula","dense":"","prepend-inner-icon":"mdi-calculator","clearable":"","hint":_vm.settings.owners_income_formula
              ? `Using system default (${_vm.settings.owners_income_formula})`
              : null,"persistent-hint":!_vm.ownerIncomeFormula,"items":_vm.OWNERS_INCOME_FORMULAS},on:{"change":function($event){_vm.disabledSave = false}},model:{value:(_vm.ownerIncomeFormula),callback:function ($$v) {_vm.ownerIncomeFormula=$$v},expression:"ownerIncomeFormula"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('date-picker',{attrs:{"hide-details":false,"label":"Cutoff date","clearable":true},on:{"change":function($event){_vm.disabledSave = false}},model:{value:(_vm.cutoffDate),callback:function ($$v) {_vm.cutoffDate=$$v},expression:"cutoffDate"}})],1),_c('v-expansion-panels',{staticClass:"ml-2 mr-2 mt-2"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',{staticClass:"mt-1"},[_vm._v("Channel Settings")])]),_c('v-expansion-panel-content',[_c('v-card',[_c('v-card-text',_vm._l((_vm.channels),function(val,key){return _c('v-sheet',{key:key,staticClass:"pa-2 rounded my-2",attrs:{"outlined":""}},[_c('h4',{staticClass:"text-h6 primary--text"},[_vm._v(" "+_vm._s(val)+" ")]),_c('v-switch',{attrs:{"label":"Override pms settings"},model:{value:(_vm.channel_settings[val].override),callback:function ($$v) {_vm.$set(_vm.channel_settings[val], "override", $$v)},expression:"channel_settings[val].override"}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[(
                          _vm.channel_settings[val] &&
                          _vm.channel_settings[val].override
                        )?_c('v-text-field',{attrs:{"label":"Commission","dense":"","outlined":"","hide-details":""},model:{value:(_vm.channel_settings[val].default_commission),callback:function ($$v) {_vm.$set(_vm.channel_settings[val], "default_commission", $$v)},expression:"channel_settings[val].default_commission"}}):_vm._e()],1),_c('v-col',{staticClass:"ml-3",attrs:{"cols":"auto"}},[(
                          _vm.channel_settings[val] &&
                          _vm.channel_settings[val].override
                        )?_c('v-select',{attrs:{"label":"Out of","dense":"","outlined":"","hide-details":"","items":[
                          { text: 'Total', value: 'total' },
                          {
                            text: 'Fare Accommodation',
                            value: 'fare_accommodation',
                          },
                        ]},model:{value:(_vm.channel_settings[val].out_of),callback:function ($$v) {_vm.$set(_vm.channel_settings[val], "out_of", $$v)},expression:"channel_settings[val].out_of"}}):_vm._e()],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[(
                        _vm.channel_settings[val] &&
                        _vm.channel_settings[val].override
                      )?_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Owner Processing %"},model:{value:(
                            _vm.channel_settings[val].processing_fee_override
                          ),callback:function ($$v) {_vm.$set(_vm.channel_settings[val], "processing_fee_override", _vm._n($$v))},expression:"\n                            channel_settings[val].processing_fee_override\n                          "}})],1)],1):_vm._e()],1),(
                      _vm.channel_settings[val] && _vm.channel_settings[val].override
                    )?_c('h4',{staticClass:"v-label theme--light"},[_vm._v(" Merchant of record: ")]):_vm._e(),(
                      _vm.channel_settings[val] && _vm.channel_settings[val].override
                    )?_c('v-radio-group',{staticClass:"pb-2 ma-0",attrs:{"hide-details":"","row":""},model:{value:(_vm.channel_settings[val].processing_fee),callback:function ($$v) {_vm.$set(_vm.channel_settings[val], "processing_fee", $$v)},expression:"channel_settings[val].processing_fee"}},[_c('v-radio',{attrs:{"label":"Channel","value":false}}),_c('v-radio',{attrs:{"label":"PMC","value":true}})],1):_vm._e()],1)}),1)],1)],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }