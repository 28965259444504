<template>
  <div v-if="dryRunMessage">
    <div
      class="float-right ai-component align-baseline max-w-85 ma-2 pa-3 text-body-2 text-sm-body-1"
      style="margin-right: 18px !important"
    >
      <div style="width: 100%; visibility: hidden">
        <v-btn outlined x-small color="blue-grey" class="float-right">
          <v-icon class="me-1" x-small>mdi-creation</v-icon>
          Dry Run
        </v-btn>
      </div>
      <div class="center-text pb-3 bb-1 full-width ai-component-header">
        <span><strong>Dry Run</strong></span>
      </div>
      <div class="ai-component-content">
        <div
          v-if="
            !aiSuggestionMessage &&
            (!aiSuggestionActions || aiSuggestionActions.length === 0)
          "
          class="ai-component-message mt-3"
        >
          <div
            class="center-text pb-3 bb-1 full-width ai-component-no-suggestion"
          >
            <img
              class="mr-2"
              style="width: 60px; height: 60px"
              src="no messages.svg"
            />
            <span style="font-size: 12px; margin-left: 10px">
              <strong>No AI Suggestion Found</strong>
            </span>
          </div>
        </div>
        <div v-if="aiSuggestionMessage" class="ai-component-message mt-3">
          <span>
            <span class="cyan--text">
              <v-icon class="mr-2 cyan--text">$chat</v-icon>
            </span>
            <strong>Message</strong>
            <span
              v-if="aiSuggestionMessageTranslation"
              class="cyan--text tooltip"
            >
              <v-icon class="mr-2 cyan--text tooltipicon">mdi-translate</v-icon>
              <span class="tooltiptext">{{
                aiSuggestionMessageTranslation
              }}</span>
            </span>
            <span v-if="aiSuggestionMessageReason" class="cyan--text tooltip">
              <v-icon class="mr-2 cyan--text tooltipicon">$info</v-icon>
              <span class="tooltiptext">{{ aiSuggestionMessageReason }}</span>
            </span>
          </span>
          <p v-html="aiSuggestionMessage"></p>
        </div>
        <div
          v-for="co_pilot_action in aiSuggestionActions"
          :key="co_pilot_action.action_name"
          class="ai-component-action"
        >
          <div>
            <span class="cyan--text">
              <v-icon class="mr-2">{{ iconForAction(co_pilot_action) }}</v-icon>
            </span>
            <strong>
              {{ labelForAction(co_pilot_action) }}
              <span
                v-if="
                  co_pilot_action.payload && co_pilot_action.payload.human_time
                "
                class="ml-1"
              >
                ({{ co_pilot_action.payload.human_time }})
              </span>
            </strong>
          </div>
          <div
            v-if="
              co_pilot_action.payload && co_pilot_action.payload.description
            "
          >
            <span class="will-be">
              {{ co_pilot_action.payload.description }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['dryRunMessage'],

  computed: {
    aiSuggestionMessage() {
      return JSON.parse(this.dryRunMessage.body).message
    },
    aiSuggestionMessageTranslation() {
      return JSON.parse(this.dryRunMessage.body).message_translation
    },
    aiSuggestionMessageReason() {
      return JSON.parse(this.dryRunMessage.body).message_reason
    },
    aiSuggestionActions() {
      const actions = JSON.parse(this.dryRunMessage.body)?.actions?.filter(
        e => e.requires_action && this.labelForAction(e)
      )
      return actions || []
    },
  },
  methods: {
    labelForAction(co_pilot_action) {
      switch (co_pilot_action.action_name) {
        case 'create_service_call':
          return 'Create Service Call'
        case 'create_follow_up_reminder':
          return 'Create Follow-Up'
        case 'update_conversation_quality_level_score':
          return 'Fun Level Changed'
        case 'escalate':
          return 'Escalation'
        case 'update_service_call':
          return 'Edit Service Call'
        case 'update_reservation':
          return 'Edit Reservation'
        case 'process_guest_checkout':
          return 'Guest Checkout'
        default:
          return ''
      }
    },
    iconForAction(co_pilot_action) {
      switch (co_pilot_action.action_name) {
        case 'create_service_call':
        case 'update_service_call':
          return '$maintenance'
        case 'create_follow_up_reminder':
          return '$alarm'
        case 'update_conversation_quality_level_score':
          return 'mdi-star'
        case 'escalate':
          return 'mdi-bell-outline'
        case 'update_reservation':
        case 'process_guest_checkout':
          return '$people'
        default:
          return ''
      }
    },
  },
}
</script>

<style scoped>
.ai-component {
  /* width: 500px; */
  margin: auto;
  /* margin-right: 24px; */
  /* border: 1px solid lightgrey; */
  border-radius: 11px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ai-component-message {
  flex-direction: column;
  align-items: center;
}

.ai-component-action {
  flex-direction: column;
  margin-top: 10px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: gray;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 15px;
  left: -140px;
  top: 37px;
  position: absolute;
  z-index: 1;
  word-break: break-word;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
