<template>
  <v-dialog
    :value="value"
    width="90%"
    @click:outside="$emit('update:value', false)"
  >
    <v-card :loading="guestLoading">
      <div>
        <v-card-title class="h1 py-2 px-4">Guest Details</v-card-title>
        <v-divider></v-divider>
        <v-row class="ma-0">
          <v-col cols="5" class="pa-4">
            <div class="d-flex align-center mx-0">
              <div class="me-2">
                <v-avatar size="45" color="light400">
                  <span class="black--text font-weight-medium">{{
                    initialsFromFullName(guest.full_name)
                  }}</span>
                </v-avatar>
              </div>
              <div class="">
                <div class="text-h6 font-weight-medium d-flex align-center">
                  {{ guest.full_name }}
                  <div
                    v-for="tag in guest.extra_data?.user_tags"
                    :key="tag"
                    class="text-capitalize font-weight-medium py-0 px-2 rounded-pill text-caption black950--text ms-2 d-flex align-center"
                    :style="`background-color: ${randomColors(tag)}1A;`"
                  >
                    <div
                      class="dot me-1"
                      :style="`background-color: ${randomColors(tag)};`"
                    />
                    {{ tag }}
                  </div>
                </div>
                <div class="dark500--text text-caption font-weight-medium">
                  {{ guest.primary_email }}
                </div>
              </div>
            </div>
            <div class="mt-6">
              <v-card
                outlined
                class="d-flex justify-space-around pa-3 rounded-lg"
              >
                <template v-for="(kpi, i) in kpis">
                  <div :key="kpi.value">
                    <div class="text-body-2 dark500--text text-uppercase">
                      {{ kpi.title }}
                    </div>
                    <div class="text-h6 black950--text font-weight-semibold">
                      <span v-if="kpi.currency">{{
                        toMoney(get(kpi.value, stats))
                      }}</span>
                      <span v-else>{{ get(kpi.value, stats) || '-' }}</span>
                    </div>
                  </div>
                  <v-divider
                    v-if="i < kpis.length - 1"
                    :key="kpi.title"
                    vertical
                  ></v-divider>
                </template>
              </v-card>
            </div>
            <div>
              <div class="my-6 text-body-2 font-weight-medium">Details</div>
              <div v-for="detail in details" :key="detail.value">
                <div class="d-flex justify-space-between text-body-2 my-2">
                  <div class="text--secondary">{{ detail.title }}</div>
                  <div v-if="detail.tags">
                    <v-chip
                      v-for="tag in get(detail.value, guest)"
                      :key="tag"
                      label
                      small
                      outlined
                      class="ms-2"
                    >
                      {{ tag }}</v-chip
                    >
                  </div>
                  <div v-else class="font-weight-medium">
                    {{ get(detail.value, guest) || '-' }}
                  </div>
                </div>
                <v-divider></v-divider>
              </div>
            </div>
          </v-col>
          <v-col class="pa-0 ma-0 d-flex">
            <v-divider vertical class="min-margin"></v-divider>
            <div class="w-100">
              <v-tabs
                v-model="guestTab"
                background-color="white"
                slider-color="primary500"
              >
                <v-tab
                  href="#reservations"
                  class="text-capitalize font-weight-medium tab-blue"
                >
                  Reservations</v-tab
                >
                <v-tab
                  href="#reviews"
                  disabled
                  class="text-capitalize font-weight-medium tab-blue"
                  >Reviews</v-tab
                >
                <v-tab
                  href="#behaviors"
                  disabled
                  class="text-capitalize font-weight-medium tab-blue"
                >
                  Behavior Patterns</v-tab
                >
                <v-tab
                  href="#notes"
                  disabled
                  class="text-capitalize font-weight-medium tab-blue"
                >
                  Notes</v-tab
                >
              </v-tabs>
              <v-divider />
              <v-tabs-items v-model="guestTab">
                <v-tab-item value="reservations">
                  <div class="pa-4">
                    <div class="text-body-2 font-weight-medium">
                      Last Reservation
                    </div>
                    <v-card
                      v-if="lastReservation"
                      outlined
                      class="rounded-lg my-4"
                    >
                      <div
                        class="d-flex justify-space-between align-center pa-3"
                      >
                        <div>
                          <div
                            class="rounded-pill light300 text-caption d-inline-block px-2 my-2"
                          >
                            #{{ lastReservation.confirmation_code }}
                          </div>
                          <div class="text-body-2 font-weight-medium">
                            {{ lastReservation.listing.nickname }}
                            <span class="font-weight-light ms-1">{{
                              lastReservation.listing.address
                            }}</span>
                          </div>
                        </div>
                        <div
                          class="text-capitalize font-weight-semibold py-1 px-4 rounded-pill text-caption"
                          :style="`background-color: ${
                            STATUS_COLORS[lastReservation.status]?.background
                          }; color: ${
                            STATUS_COLORS[lastReservation.status]?.text
                          };`"
                        >
                          {{ lastReservation.status }}
                        </div>
                      </div>
                      <v-divider />
                      <div class="px-3 d-flex justify-space-between">
                        <div class="py-3 pe-3 text-no-wrap">
                          <div class="dark600--text text-caption">
                            Reservation Dates
                          </div>
                          <div class="black950--text font-weight-medium">
                            {{ formattedReservationDates }}
                          </div>
                        </div>
                        <v-divider vertical />
                        <div class="pa-3">
                          <div class="dark600--text text-caption">Value</div>
                          <div class="black950--text font-weight-medium">
                            {{ toMoney(lastReservation.fare_accommodation) }}
                          </div>
                        </div>
                        <v-divider vertical />
                        <div class="pa-3">
                          <div class="dark600--text text-caption">Requests</div>
                          <div class="black950--text font-weight-medium">
                            <v-chip
                              v-for="tag in Object.keys(
                                lastReservation.special_requests
                              )"
                              :key="tag"
                              label
                              small
                              outlined
                              class="me-2 mb-1"
                            >
                              {{ tag }}</v-chip
                            >
                            <span
                              v-if="
                                !Object.keys(lastReservation.special_requests)
                                  .length
                              "
                              >-</span
                            >
                          </div>
                        </div>
                        <v-divider vertical />
                        <div v-if="lastReservation.reviews.length" class="pa-3">
                          <div class="dark600--text text-caption">Review</div>
                          <div
                            class="black950--text font-weight-medium d-flex justify-center"
                          >
                            <v-icon
                              v-for="s in fullStarsCount(
                                lastReservation.reviews[0]
                              )"
                              :key="s"
                              class="mr-2"
                              small
                              color="yellow"
                              >fas fa-star</v-icon
                            >
                            <v-icon
                              v-if="hasHalfStar(lastReservation.reviews[0])"
                              small
                              color="yellow darken-2"
                              >fas fa-star-half</v-icon
                            >
                            {{ roundedStars(lastReservation.reviews[0]) }}
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>
                </v-tab-item>
                <v-tab-item value="reviews"> reviews </v-tab-item>
              </v-tabs-items>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import FormattersMixin from 'components/mixins/formatters-mixin'
import { get } from 'lodash/fp'
import maxBy from 'lodash/maxBy'
import { STATUS_COLORS } from '@/consts'

const details = [
  {
    title: 'Full Name',
    value: 'full_name',
  },
  {
    title: 'Phone Number',
    value: 'primary_phone',
  },
  {
    title: 'Email',
    value: 'primary_email',
  },
  {
    title: 'Location',
    value: 'reservations[0].pre_check_in.guests_details[0].address',
  },
  {
    title: 'Birthday',
    value: 'reservations[0].pre_check_in.client_info.birthday',
  },
  {
    title: 'Booking Frequency',
    value: '',
  },
  {
    title: 'Tags',
    value: 'extra_data.profile_tags',
    tags: true,
  },
]

export default {
  name: 'GuestModal',
  mixins: [CommonFunctions, FormattersMixin],
  props: ['guestId', 'value'],
  data() {
    return {
      STATUS_COLORS,
      guest: {},
      stats: {
        avgReviewScore: 0,
        totalRevenue: 0,
        upcomingVisits: 0,
      },
      guestLoading: false,
      guestTab: 'reservations',
      details,
      kpis: [
        {
          title: 'Satisf. Score',
          value: 'avgReviewScore',
        },
        {
          title: 'Total revenue',
          value: 'totalRevenue',
          currency: true,
        },
        {
          title: 'Upcoming visits',
          value: 'upcomingVisits',
        },
      ],
    }
  },
  mounted() {
    if (this.guestId) this.fetchGuest()
  },
  computed: {
    guestColor() {
      return this.randomColors(this.guest.full_name)
    },
    lastReservation() {
      return maxBy(this.guest.reservations, 'check_out')
    },
    formattedReservationDates() {
      const { check_in, check_out } = this.lastReservation
      const checkIn = this.$moment(check_in).format('MMM DD, YYYY')
      const checkOut = this.$moment(check_out).format('MMM DD, YYYY')
      return `${checkIn} - ${checkOut}`
    },
  },
  methods: {
    get,
    async fetchGuest() {
      this.guestLoading = true
      this.guest = await this.$store.dispatch('guests/fetchGuest', this.guestId)
      this.calculateStats()
      this.guestLoading = false
    },
    fullStarsCount(review) {
      return Math.floor(review.normilized_scrore_overall / 2)
    },
    hasHalfStar(review) {
      return (review.normilized_scrore_overall / 2) % 1 !== 0
    },
    roundedStars(review) {
      return Math.round(((review.normilized_scrore_overall / 2) * 10) / 10)
    },
    calculateStats() {
      this.guest.reservations.forEach(r => {
        this.stats.upcomingVisits +=
          r.check_in > this.$moment().format('YYYY-MM-DD')
        this.stats.totalRevenue += r.fare_accommodation
        const rawScore = r.reviews.reduce((acc, review) => {
          return acc + review.normilized_scrore_overall
        }, 0)
        this.stats.avgReviewScore =
          Math.round((rawScore / r.reviews.length / 2) * 10) / 10
      })
    },
  },
}
</script>

<style scoped>
.min-margin {
  margin: 0.1px;
}
:deep() .v-tabs-bar {
  height: 40px;
}
</style>
