<template>
  <v-dialog
    v-model="dialog"
    :persistent="true"
    max-width="900px"
    :fullscreen="isMobile"
  >
    <template #activator="props">
      <slot name="customBtn" v-bind="props" />
      <v-btn v-if="!$scopedSlots.customBtn" small color="info" v-on="props.on">
        <v-icon left small>mdi-plus</v-icon> {{ $t('Reservation') }}
      </v-btn>
    </template>

    <v-sheet class="mx-auto" height="100%">
      <v-stepper v-model="steps" elevation="0" vertical class="pa-4">
        <div class="text-h5 font-weight-black text-center">
          Create Reservation
        </div>
        <v-stepper-step
          complete-icon="check"
          :complete="steps > 1"
          step="1"
          class="ps-0"
        >
          Reservation Details
        </v-stepper-step>
        <v-stepper-content step="1" class="pa-0 ma-0">
          <v-form ref="form" @submit.prevent="create">
            <v-sheet class="pa-2 my-3 secondary lighten-3">
              <div class="text-h6 ps-2 py-6 font-weight-black">Reservation</div>
              <v-row no-gutters>
                <v-col class="px-2" cols="12">
                  <v-select
                    v-if="!isHotelReception && !isOwnerVacation"
                    v-model="reservation.status"
                    outlined
                    dense
                    :items="[
                      { text: 'Confirmed', value: 'confirmed' },
                      { text: 'Inquiry', value: 'inquiry' },
                      { text: 'Reserved', value: 'reserved' },
                      { text: 'Agent Reservation', value: 'agent-reserved' },
                    ]"
                    @change="onStatusChange"
                  />
                  <v-select
                    v-if="reservation.status === 'reserved'"
                    v-model="reservation.reserved_expiration"
                    label="Expire after"
                    outlined
                    dense
                    :items="[
                      { text: '1 day', value: 24 },
                      { text: '2 days', value: 48 },
                      { text: '3 days', value: 72 },
                    ]"
                  />
                  <v-select
                    v-if="
                      !isHotelReception &&
                      !isOwnerVacation &&
                      !isAgentReservation
                    "
                    v-model="reservation.reservation_type"
                    outlined
                    dense
                    :items="[
                      { text: 'STR', value: null },
                      { text: 'Long Term', value: 'long_term' },
                    ]"
                  />
                  <v-autocomplete
                    v-if="isAgentReservation"
                    v-model="reservation.agent"
                    :items="agents"
                    :rules="[required]"
                    item-value="id"
                    item-text="name"
                    return-object
                    no-filter
                    outlined
                    dense
                    clearable
                    label="Select Agent"
                    @change="onAgentChange"
                  >
                  </v-autocomplete>
                  <v-autocomplete
                    v-if="!isOwnerVacation"
                    v-model="reservation.listing_id"
                    label="Listing"
                    :items="listingsPicker"
                    dense
                    :rules="[required]"
                    item-text="nickname"
                    item-value="id"
                    outlined
                    @change="onListingChange"
                  />
                </v-col>
                <v-col v-if="isOwnerVacation" cols="6" class="px-2">
                  <date-picker
                    v-model="reservation.check_in"
                    :hide-details="false"
                    label="Check-In"
                    :rules="[required]"
                    :allowed-dates="allowedDatesOwner"
                    @change="updateCheckOut"
                  />
                </v-col>
                <v-col v-if="isOwnerVacation" cols="6" class="px-2">
                  <date-picker
                    v-model="reservation.check_out"
                    label="Check-Out"
                    :disabled="!reservation.check_in"
                    :hide-details="false"
                    :rules="[required]"
                    :allowed-dates="allowedDatesCheckoutOwner"
                  />
                </v-col>
                <v-col v-else class="px-2" cols="6" md="10">
                  <date-picker
                    v-model="reservation.range"
                    :range="true"
                    :hide-details="false"
                    :rules="[required, dateRange]"
                    :allowed-dates="isStr ? allowedDates : undefined"
                    :event-color="eventColors"
                    label="Dates"
                  />
                </v-col>
                <v-col v-if="!isOwnerVacation" class="px-2" cols="6" md="2">
                  <v-text-field
                    v-model.number="reservation.guests_count"
                    label="No. Guests"
                    :rules="[required, isPositive]"
                    :hint="
                      listing && parseInt(listing.accommodates) > 0
                        ? `Up to ${listing.accommodates} guests`
                        : null
                    "
                    outlined
                    dense
                    :max="20"
                    type="number"
                  />
                </v-col>
                <v-col class="px-2" cols="12">
                  <v-textarea
                    v-model="reservation.dvr_notes"
                    label="Notes"
                    outlined
                    dense
                    rows="3"
                  />
                </v-col>
              </v-row>
            </v-sheet>
            <v-sheet
              v-if="!isAgentReservation"
              class="pa-2 secondary my-3 lighten-3"
            >
              <div class="text-h6 ps-2 py-6 font-weight-black">Guest</div>
              <reservation-guest-search
                :reservation.sync="reservation"
                :is-owner-vacation="!!isOwnerVacation"
              />
            </v-sheet>
            <v-sheet
              v-if="!isOwnerVacation"
              class="pa-2 secondary my-3 lighten-3"
            >
              <div class="text-h6 ps-2 py-6 font-weight-black">Financial</div>
              <v-row no-gutters>
                <v-col class="px-2" cols="6">
                  <v-text-field
                    v-model.number="reservation.origin_fare_accommodation"
                    label="Accommodation fare"
                    :rules="[required, nonNegative]"
                    outlined
                    type="number"
                    dense
                    @input="invoiceChange"
                  />
                </v-col>
                <v-col class="px-2" cols="6">
                  <v-text-field
                    v-model.number="reservation.fare_cleaning"
                    label="Cleaning fare"
                    outlined
                    type="number"
                    dense
                    @input="invoiceChange"
                  />
                </v-col>
                <v-col
                  v-if="
                    reservation.fees_items?.length ||
                    reservation.tax_lines?.length
                  "
                  cols="12"
                >
                  <v-checkbox
                    v-model="reservation.fees_tax_overridden"
                    label="Override fees/taxes"
                    dense
                    @change="overrideFeeTax"
                  />
                </v-col>
                <v-col
                  v-if="reservation.fees_items?.length"
                  class="px-2"
                  cols="6"
                >
                  <v-text-field
                    v-for="fee in reservation.fees_items"
                    :key="fee.title"
                    v-model.number="fee.amount"
                    :label="fee.title"
                    outlined
                    :disabled="!reservation.fees_tax_overridden"
                    type="number"
                    dense
                    @change="feeChange"
                  />
                </v-col>
                <v-col
                  v-if="reservation.tax_lines?.length"
                  class="px-2"
                  cols="6"
                >
                  <v-text-field
                    v-for="tax in reservation.tax_lines"
                    :key="tax.title"
                    v-model.number="tax.amount"
                    :disabled="!reservation.fees_tax_overridden"
                    :label="tax.title"
                    outlined
                    type="number"
                    dense
                  />
                </v-col>
              </v-row>
              <v-sheet v-if="!isStr" class="secondary lighten-3">
                <div class="text-h6 ps-2 py-6 font-weight-black">
                  Commissions
                </div>
                <v-row no-gutters>
                  <v-col class="px-2" cols="6">
                    <v-text-field
                      v-model.number="reservation.management_commission"
                      label="Management Commission"
                      outlined
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col class="px-2" cols="6">
                    <v-text-field
                      v-model.number="reservation.client_profit"
                      label="Owner Profit"
                      outlined
                      type="number"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-sheet>
              <div v-if="total" class="px-2 text-end">
                <span class="font-weight-medium text-subtitle-2 mx-1"
                  >Total:</span
                >{{ dollarFormatter(total) }}
              </div>
            </v-sheet>
            <div class="text-right py-2">
              <v-btn
                class="ml-auto mr-2"
                color="primary"
                outlined
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                :loading="loading"
                class="ml-auto"
                :disabled="pricingLoading"
                type="submit"
              >
                {{ isStr && !isOwnerVacation ? 'Continue' : 'Create' }}
              </v-btn>
            </div>
          </v-form>
        </v-stepper-content>
        <v-stepper-step
          v-if="isStr && !isOwnerVacation && !isAgentReservation"
          complete-icon="check"
          :complete="steps > 2"
          step="2"
          class="ps-0"
        >
          Payment Details
        </v-stepper-step>
        <v-stepper-content v-if="isStr" step="2" class="ma-0 pa-0">
          <stripe-element
            v-if="intentId"
            button-text="PAY NOW"
            :email="reservation.email"
            :public-key="publicKey"
            :intent-id="intentId"
            :client-secret="clientSecret"
            @payment-success="paymentSuccess"
            @payment-failed="paymentFailed"
            @payment-processing="paymentProcess"
          >
            <template #disclaimer>
              <div v-if="amount" class="text-right py-2">
                <tag size="sm" :inverted="true" color="warning">
                  <span class="text-subtitle-2"
                    >* You are about to charge third of the reservation price.
                    <strong class="mx-2">{{ dollarFormatter(amount) }}</strong>
                  </span>
                </tag>
              </div>
            </template>
          </stripe-element>
          <div class="text-right py-2">
            <v-btn
              color="success"
              :loading="loading"
              class="ml-auto"
              @click="skipPayment"
            >
              Skip without pay
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper>
    </v-sheet>
  </v-dialog>
</template>

<script>
import FormRules from 'components/mixins/form-rules-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import DatePicker from 'components/form-fields/date-picker'
import { mapGetters, mapState } from 'vuex'
import Toaster from '@/utils/toaster'
import StripeElement from 'components/stripe/stripe-element'
import FormattersMixin from 'components/mixins/formatters-mixin'
import Tag from 'components/common/tag'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import ReservationGuestSearch from 'components/reservation/reservation-guest-search.vue'
import { MARBELLA_DEMO_LISTING_ID } from '@/consts'
import axios from 'axios'
import debounce from 'lodash/debounce'

export default {
  name: 'ReservationCreateModal',
  components: {
    Tag,
    StripeElement,
    DatePicker,
    ReservationGuestSearch,
  },
  props: ['preFilled', 'isOwnerVacation'],
  mixins: [
    FormRules,
    CommonFunctions,
    FormattersMixin,
    PermissionsMixin,
    DeviceMixin,
  ],
  data() {
    return {
      reservation: this.initialReservation(),
      listing: {},
      pricingLoading: false,
      currentRates: {},
      dialog: false,
      chosenGuest: null,
      guests: [],
      publicKey: null,
      clientSecret: null,
      createdReservation: {},
      intentId: null,
      steps: 1,
      search: '',
      amount: null,
      reservationLoading: false,
      phoneKey: 0,
      debouncer: debounce(this.priceChange, 500),
    }
  },
  mounted() {
    if (this.$store.state.currentListing) {
      this.listing = this.$store.state.currentListing
    }
  },
  computed: {
    ...mapGetters(['listingsPicker']),
    ...mapState('users', ['allUsers']),
    agents() {
      return this.allUsers.filter(r => r.role === 'agent')
    },
    reservationDataString() {
      const r = this.reservation
      return `${r.listing_id}-${r.check_in}-${r.check_out}-${
        r.guests_count
      }-${r.range?.toString()}`
    },
    isStr() {
      return this.reservation.reservation_type !== 'long_term'
    },
    marbellaId() {
      return MARBELLA_DEMO_LISTING_ID
    },
    total() {
      return (
        (this.reservation.origin_fare_accommodation || 0) +
        (this.reservation.fare_cleaning || 0) +
        this.totalTax +
        this.totalFees
      )
    },
    totalTax() {
      return this.reservation.tax_lines.reduce(
        (acc, tax) => acc + (tax.amount || 0),
        0
      )
    },
    totalFees() {
      return this.reservation.fees_items.reduce(
        (acc, fee) => acc + fee.amount || 0,
        0
      )
    },
    isAgentReservation() {
      return this.reservation.status === 'agent-reserved'
    },
    isReserved() {
      return this.reservation.agent_id || this.reservation.status == 'reserved'
    },
  },
  watch: {
    search(val) {
      if (!val) {
        return
      }
      this.fetchEntriesDebounced(val)
    },
    async reservationDataString(_val) {
      this.debouncer.call(this, false)
    },
    async dialog(isOpen) {
      if (isOpen && this.preFilled && this.preFilled.listingId) {
        this.reservation.range = this.preFilled.range
          ? this.preFilled.range
          : []
        this.reservation.listing_id = this.preFilled
          ? +this.preFilled.listingId
          : null
        this.reservation.guests_count = this.preFilled
          ? +this.preFilled.guests_count
          : null
        this.currentRates = await this.$store.dispatch(
          'listings/getListingRates',
          this.reservation.listing_id
        )
        await this.refetchPricing()
      }
    },
  },
  methods: {
    ...mapGetters(['currentUser']),
    invoiceChange(val) {
      if (isNaN(val)) {
        return
      }
      this.debouncer.call(this, true)
    },
    overrideFeeTax(isOverridden) {
      if (!isOverridden) {
        this.priceChange()
      }
    },
    async refetchPricing(ovverideFA = true) {
      if (
        this.reservation.listing_id &&
        ((this.reservation.check_in && this.reservation.check_out) ||
          this.reservation.range?.length === 2) &&
        this.reservation.guests_count
      ) {
        this.pricingLoading = true
        const start_date =
          this.reservation.check_in || this.reservation.range[0]
        const end_date = this.reservation.check_out || this.reservation.range[1]
        try {
          const {
            data: { info },
          } = await axios.get(
            `/api/reservations/listings/${this.reservation.listing_id}/pricing`,
            {
              params: {
                start_date,
                end_date,
                guests_count: this.reservation.guests_count,
                fare_accommodation: ovverideFA
                  ? this.reservation.origin_fare_accommodation
                  : null,
                fare_cleaning: ovverideFA
                  ? this.reservation.fare_cleaning
                  : null,
              },
            }
          )
          this.reservation.fare_accommodation = info.adjusted_fare_accommodation
          this.reservation.fare_cleaning = info.cleaning_fee
          this.reservation.total_taxes = info.taxes
          this.reservation.fees_items = info.fees_breakdown
          this.reservation.tax_lines = info.taxes_breakdown
          this.reservation.origin_fare_accommodation = info.fare_accommodation
        } catch (e) {
          console.error('Failed to fetch pricing')
        }
        this.pricingLoading = false
      }
    },
    async paymentSuccess() {
      Toaster.show([{ type: 'success', text: 'Payment done successfully' }])
      this.$store.commit('updateLoading', false)
      this.$emit('charge-success')
      this.skipPayment()
    },
    paymentProcess() {
      this.$store.commit('updateLoading', true)
    },
    async paymentFailed() {
      Toaster.show([{ type: 'error', text: 'Payment failed to process' }])
      this.$store.commit('updateLoading', false)
    },
    fetchEntriesDebounced(newValue) {
      // cancel pending call
      clearTimeout(this._timerId)

      // delay new call 500ms
      this._timerId = setTimeout(async () => {
        this.guests = await this.$store.dispatch('guests/fetchGuests', {
          term: newValue,
        })
      }, 1000)
    },
    async onListingChange() {
      this.listing = this.listingsPicker.find(
        l => l.id === this.reservation.listing_id
      )
      this.currentRates = await this.$store.dispatch(
        'listings/getListingRates',
        this.reservation.listing_id
      )
      if (this.reservation.listing_id === this.marbellaId) {
        this.reservation = { ...this.reservation, ...this.prefilledDemo() }
      }
    },
    guestSelect(guest) {
      this.chosenGuest = guest
      const [first_name, last_name] = guest.full_name.split(' ')
      this.reservation.first_name = first_name
      this.reservation.last_name = last_name
      this.reservation.phone = guest.primary_phone || guest.phones[0]
      this.reservation.email = guest.primary_email || guest.emails[0]
      this.reservation.guest_id = guest.guesty_id
    },
    feeChange() {
      this.reservation.fare_accommodation =
        this.reservation.origin_fare_accommodation + this.totalFees
    },
    async priceChange(overrideFA = true) {
      await this.refetchPricing(overrideFA)
      if (!this.isOwnerVacation) {
        this.listing = this.listingsPicker.find(
          l => l.id === this.reservation.listing_id
        )
      }

      this.reservation.management_commission = this.round(
        this.listing.commission * (this.reservation.fare_accommodation || 0),
        2
      )
      this.reservation.client_profit =
        this.reservation.fare_accommodation -
        this.reservation.management_commission
    },
    allowedDates(date) {
      const dayBefore = this.$moment(date)
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      return (
        (this.currentRates[date] &&
          this.currentRates[date].status === 'available') ||
        (this.currentRates[dayBefore] &&
          this.currentRates[date] &&
          this.currentRates[date].status !== 'available' &&
          this.currentRates[dayBefore].status === 'available')
      )
    },
    eventColors(date) {
      if (
        this.currentRates[date] &&
        this.currentRates[date].status === 'available'
      ) {
        return 'green lighten-1'
      }
      return ''
    },
    close() {
      this.$refs.form.reset()
      this.$nextTick(() => {
        this.reservation = this.initialReservation()
        this.createdReservation = {}
        this.amount = null
        this.publicKey = null
        this.clientSecret = null
        this.intentId = null
        this.dialog = false
      })
    },
    skipPayment() {
      this.$emit('oncreate', this.createdReservation.reservation_guesty_id)
      this.close()
    },
    allowedDatesOwner(val) {
      return !this.listing.allowed_vacation_dates[val]
    },
    allowedDatesCheckoutOwner(val) {
      if (
        this.listing.allowed_vacation_dates[val] &&
        !this.listing.allowed_check_out_vacation_dates[val]
      ) {
        return false
      } else {
        if (this.reservation.check_in) {
          let m = this.$moment(this.reservation.check_in)
          if (m.isBefore(val)) {
            let noGap = true
            while (m.isBefore(val) && noGap) {
              if (
                this.listing.allowed_vacation_dates[m.format('YYYY-MM-DD')] &&
                !this.listing.allowed_check_out_vacation_dates[
                  m.format('YYYY-MM-DD')
                ]
              ) {
                noGap = false
              }
              m = this.$moment(m.add(1, 'days').toString())
            }
            return noGap
          } else if (this.reservation.check_in === val) {
            return false
          } else {
            return false
          }
        } else {
          return true
        }
      }
    },
    async createReservation(payload) {
      const { res, stripe_public_key, client_secret, intent_id, amount } =
        await this.$store.dispatch('reservation/createReservation', payload)
      if (this.isOwnerVacation) {
        await this.$store.dispatch('changeListing', {
          listingId: this.listing.id,
        })
      }
      if (!res) {
        return
      }
      this.createdReservation = res
      if (this.createdReservation && !intent_id) {
        this.skipPayment()
        return
      }
      this.amount = amount
      this.clientSecret = client_secret
      this.publicKey = stripe_public_key
      this.intentId = intent_id
      this.steps = 2
    },
    async createInternalReservation(payload) {
      const { res } = await this.$store.dispatch(
        'reservation/createInternalReservation',
        payload
      )
      this.createdReservation = res
      this.skipPayment()
    },
    async create() {
      if (this.$refs.form.validate()) {
        this.$store.commit('updateLoading', true)
        if (
          this.chosenGuest &&
          this.chosenGuest.emails[0] !== this.reservation.email
        ) {
          this.reservation.guest_id = null
        }
        this.reservation.fees_items = this.reservation.fees_items.filter(
          fee => fee.amount
        )
        this.reservation.tax_lines = this.reservation.tax_lines.filter(
          tax => tax.amount
        )
        this.reservation.total_taxes = this.totalTax
        if (this.isReserved) {
          return await this.createInternalReservation(this.reservation)
        }
        if (this.isOwnerVacation) {
          const data = {
            ...this.reservation,
            listing_id: this.listing.id,
            fare_accommodation: 0,
            fare_cleaning: 0,
            source: 'Owner',
            ignore_terms: true,
            status: 'confirmed',
            guests_count: 1,
            range: [this.reservation.check_in, this.reservation.check_out],
          }
          await this.createReservation(data)
        } else if (this.isStr && !this.isOptima) {
          await this.createReservation(this.reservation)
        } else {
          await this.createInternalReservation(this.reservation)
        }
      }
    },
    initialReservation() {
      this.phoneKey++
      return {
        listing_id: null,
        reservation_type: null,
        fare_accommodation: null,
        origin_fare_accommodation: null,
        fare_cleaning: null,
        management_commission: 0,
        client_profit: 0,
        processing_fee: 0,
        fees_tax_overridden: false,
        guests_count: 1,
        status: 'confirmed',
        check_in: null,
        check_out: null,
        total_taxes: 0,
        fees_items: [],
        tax_lines: [],
        first_name: this.isOwnerVacation ? this.currentUser().first_name : null,
        last_name: this.isOwnerVacation ? this.currentUser().last_name : null,
        email: this.isOwnerVacation ? this.currentUser().email : null,
        phone: this.isOwnerVacation ? this.currentUser().phone : undefined,
      }
    },
    prefilledDemo() {
      return {
        range: [
          this.$moment().utc().format('YYYY-MM-DD'),
          this.$moment().utc().add(2, 'days').format('YYYY-MM-DD'),
        ],
        guests_count: 2,
        note: 'Demo booking',
        phone: '',
        email: 'demo+' + Math.random() * 1000000 + '@boomnow.com',
      }
    },
    onStatusChange() {
      this.reservation.reservation_type = null
    },
    onAgentChange(agent) {
      this.reservation.phone = agent.phone
      this.reservation.first_name = agent.first_name
      this.reservation.last_name = agent.last_name
      this.reservation.agent_id = agent.id
      this.reservation.agent_name = agent.name
      this.reservation.email = agent.email
      this.reservation.source = 'Agent'
    },
    updateCheckOut() {
      this.reservation.check_out = null
    },
  },
}
</script>

<style scoped></style>
